import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Modal from '@mui/material/Modal';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import MUIDataTable from "mui-datatables";
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Autocomplete from '@mui/material/Autocomplete';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid'; // Import DataGrid from MUI
import axios from 'axios';

function SalaryHead() {
  const [data, setdata] = useState([]);
  const [openModal, setOpenModal] = useState(false); // State to control the modal visibility
  const [deleteIndex, setDeleteIndex] = useState(null); // State to store the index of item to be deleted
  const [salaryHeadName, setSalaryHeadName] = useState('');
  const [headType, setHeadType] = useState('');
  const [maxLimit, setMaxLimit] = useState('');
  const [calculationBasis, setCalculationBasis] = useState('');
  const [value, setValue] = useState('');
  const [percentageOfField, setPercentageOfField] = useState('');
  const [roundOfZero, setRoundOfZero] = useState('');
  const [order, setOrder] = useState('');
  const [error, setError] = useState('');
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [actionMessage, setActionMessage] = useState('');
  const [searchText, setSearchText] = useState(''); // State for search text
  const [rowsPerPage, setRowsPerPage] = useState(10); // State for rows per page, default 10
  const [currentPage, setCurrentPage] = useState(); // State for current page, default 1
  const [editID, setEditID] = useState('');

  const [dropdownValues, setDropdownValues] = useState([{ Limit: "", MaxLimit: "", Value: "" }]);
  const [SalryStucValues, setSalryStucValues] = useState([]);
  const [SalryStucType, setSalryStucType] = useState([]);

  const handleLanguageChange = (index, field, value) => {
    const newDropdownValues = [...dropdownValues];
    newDropdownValues[index][field] = value;
    setDropdownValues(newDropdownValues);
  };

  // Function to add one more Language
  const handleLanguageAddMore = () => {
    setDropdownValues([...dropdownValues, { Limit: "", MaxLimit: "", Value: "" }]);
  };

  // Function to delete a Language item
  const handleLanguageDelete = (index) => {
    const newDropdownValues = [...dropdownValues];
    newDropdownValues.splice(index, 1);
    setDropdownValues(newDropdownValues);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // if (salaryHeadName.trim() === '') {
    //   setError('Salary Head Name cannot be empty');
    //   return;
    // }
    // if (headType.trim() === '') {
    //   setError('Head Type cannot be empty');
    //   return;
    // }
    // if (maxLimit.trim() === '') {
    //   setError('Max Limit cannot be empty');
    //   return;
    // }
    // if (calculationBasis.trim() === '') {
    //   setError('Calculation Basis cannot be empty');
    //   return;
    // }
    // if (value.trim() === '') {
    //   setError('Value cannot be empty');
    //   return;
    // }
    // if (percentageOfField.trim() === '') {
    //   setError('% of Field cannot be empty');
    //   return;
    // }
    // if (roundOfZero.trim() === '') {
    //   setError('Round of Zero cannot be empty');
    //   return;
    // }
    // if (order.trim() === '') {
    //   setError('Order cannot be empty');
    //   return;
    // }
    setError('');
    const userSchemaString = localStorage.getItem('userSchema');
    const userSchema = JSON.parse(userSchemaString);
    const companyIdString = localStorage.getItem('companyId');
    const divIdString = localStorage.getItem('divId');
    const dealer = SalryStucType?.length > 0 ? SalryStucType?.map(item => item?._id) : [];
    const dataToSend = {
      _id: editID ? editID : null,
      LimitArr: dropdownValues,
      salaryhead_name: salaryHeadName,
      head_type: headType,
      max_limit: maxLimit,
      calculation_basis: calculationBasis,
      value: value,
      per_of_field: percentageOfField,
      FieldArr: dealer,
      round_of_zero: roundOfZero,
      order: order,
      user: userSchema?.usrnm,
      compid: JSON.parse(companyIdString),
      divid: JSON.parse(divIdString),
      masterid: userSchema?.masterid
    };
    const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
    axios.post(`${process.env.REACT_APP_API_URL}/salary_master/salarymasterAdd`, dataToSend, {
      headers: {
        authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        console.log(response)
        fetchData()
        if (response.data.status) {
          setActionMessage(response.data.message)
          setSuccessModalOpen(true);
          handleCancel();

        } else {
          setActionMessage(response.data.message)
          handleCancel();
          setErrorModalOpen(true)
        }
      })
      .catch(error => {
        console.log(error)
        setActionMessage("Ooops an error occured.")
        setErrorModalOpen(true)
      });
  };

  const handleUpdateEmpStruc = () => {
  
    const userSchemaString = localStorage.getItem('userSchema');
    const userSchema = JSON.parse(userSchemaString);
    const companyIdString = localStorage.getItem('companyId');
    const divIdString = localStorage.getItem('divId');
    const dataToSend = {
      user: userSchema?.usrnm,
      compid: JSON.parse(companyIdString),
      divid: JSON.parse(divIdString),
      masterid: userSchema?.masterid
    };
    const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
    axios.post(`${process.env.REACT_APP_API_URL}/salart_handleRouter/EmpSalaryStructureUpdate`, dataToSend, {
      headers: {
        authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        console.log(response)
        if (response.data.status) {
          setActionMessage(response.data.message)
          setSuccessModalOpen(true);
        } else {
          setActionMessage(response.data.message)
          setErrorModalOpen(true)
        }
      })
      .catch(error => {
        console.log(error)
        setActionMessage("Ooops an error occured.")
        setErrorModalOpen(true)
      });
  };

  const handleCancel = () => {
    setEditID('');
    setSalaryHeadName('');
    setHeadType('');
    setMaxLimit('');
    setCalculationBasis('');
    setValue('');
    setPercentageOfField('');
    setRoundOfZero('');
    setOrder('');
    setDropdownValues([{ Limit: "", MaxLimit: "", Value: "" }]);
    setSalryStucType([]);
    setError('');
  };

  const handleSuccessModalClose = () => {
    setSuccessModalOpen(false);
  };

  const handleErrorModalClose = () => {
    setErrorModalOpen(false);
  };

  const handleTableChange = (action, tableState) => {
    if (action === 'search') {
      setSearchText(tableState.searchText);
    }
    if (action === 'changeRowsPerPage') {
      setRowsPerPage(tableState.rowsPerPage);
    }
    if (action === 'changePage') {
      console.log("-", tableState.page)
      setCurrentPage(tableState.page);
    }
  };

  const fetchData = async () => {
    try {
      const userSchemaString = localStorage.getItem('userSchema');
      const userSchema = JSON.parse(userSchemaString);
      const masterid = userSchema?.masterid;
      const companyIdString = localStorage.getItem('companyId');
      const divIdString = localStorage.getItem('divId');
      const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/salary_master/salary_headListGET`,
        {
          params: {
            searchText,
            rowsPerPage,
            currentPage,
            masterid,
            compid: JSON.parse(companyIdString),
            divid: JSON.parse(divIdString)
          },
          headers: {
            authorization: `Bearer ${token}`
          }
        }
      );

      console.log('API Response:', response.data.lastEntryNo);
      setdata(response.data.lastEntryNo)

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const SalryStrucGET = async () => {
    const userSchemaString = localStorage.getItem('userSchema');
    const userSchema = JSON.parse(userSchemaString);
    const companyIdString = localStorage.getItem('companyId');
    const divIdString = localStorage.getItem('divId');


    const data = {
      user: userSchema?.usrnm,
      compid: JSON.parse(companyIdString),
      divid: JSON.parse(divIdString),
      masterid: userSchema?.masterid
    };
    try {
      const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/salary_master/salaryHeadOptionsGet`, data,
        {
          headers: {
            authorization: `Bearer ${token}`
          }
        }
      );
      console.log(response?.data?.lastEntryNo)
      setSalryStucValues(response?.data?.lastEntryNo);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // useEffect(()=>{
  //   SalryStrucGET();
  // },[]);

  useEffect(() => {
    SalryStrucGET();
    fetchData(); // Call fetchData when component mounts or when searchText, rowsPerPage, or currentPage changes
    // }, [searchText, rowsPerPage, currentPage]);
  }, []);

  const dataWithSerialNumbers = data.map((item, index) => ({
    id: index + 1,
    ...item,
    serialNumber: index + 1, // Serial number starts from 1
  }));

  const handleDelete = (INFO) => {
    setDeleteIndex(INFO._id);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleEdit = (editdata) => {
    setEditID(editdata._id)
    window.scrollTo(0, 0); // Scroll to the top of the page
    setDropdownValues(editdata?.LimitArr || [{ Limit: "", MaxLimit: "", Value: "" }]);
    setSalaryHeadName(editdata.salaryhead_name);
    setHeadType(editdata.head_type);
    setCalculationBasis(editdata.calculation_basis);
    setMaxLimit(editdata.max_limit);
    // setPercentageOfField(editdata.per_of_field);
    setSalryStucType(SalryStucValues.filter(item => editdata?.FieldArr?.includes(item._id)) || []);

    setRoundOfZero(editdata.round_of_zero);
    setValue(editdata.value);
    setOrder(editdata.order);
  };

  const handleModalDelete = async () => {
    try {
      const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/salary_master/salary_headDELETE/${deleteIndex}`,
        {},
        {
          headers: {
            authorization: `Bearer ${token}`
          }
        }
      );

      console.log('Delete API Response:', response.data);
      handleCloseModal();
      fetchData();
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  const columns = [
    { field: "serialNumber", headerName: "S. No.", flex: 1, }, // Serial number column
    { field: "salaryhead_name", headerName: "Salary Head Name", flex: 1 },
    { field: "head_type", headerName: "Head Type", flex: 1 },
    { field: "max_limit", headerName: "Max Limit", flex: 1 },
    { field: "calculation_basis", headerName: "Calculation Basis", flex: 1 },
    { field: "value", headerName: "Value", flex: 1 },
    { field: "per_of_field", headerName: "% of Field", flex: 1 },
    { field: "round_of_zero", headerName: "Round of Zero", flex: 1 },
    { field: "order", headerName: "Order", flex: 1 },
    {
      field: "edit",
      headerName: "Edit",
      flex: 1,
      renderCell: (params) => (
        <Button variant="text" color="primary" size='small'
          onClick={() => handleEdit(params.row)}
        ><EditIcon fontSize="small" /></Button>
      )
    },
    {
      field: "delete",
      headerName: "Delete",
      flex: 1,
      renderCell: (params) => (
        <Button variant="text" color="error" size='small'
          onClick={() => handleDelete(params.row)}
        ><DeleteIcon fontSize="small" /></Button>
      )
    }
  ];

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    border: '2px solid',
    boxShadow: 24,
    padding: 16,
    minWidth: 300,
    maxWidth: 500,
    textAlign: 'center'
  };
  return (
    <>
      <Container maxWidth="xl" style={{ marginTop: '25px' }}>
        <Box sx={{ border: '1px solid black', padding: '20px', margin: '-1px', display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ backgroundColor: '#007bff', padding: '10px', marginBottom: '20px', display:'flex', justifyContent:'space-between' }}>
            <Typography  variant="h6" sx={{ color: 'white', margin: '0' }}>
              Salary Head Master
            </Typography>

            <Button variant="contained" color="success" onClick={()=>{handleUpdateEmpStruc();}}>
              Update Employees Salary Structure
            </Button>
          </Box>
          <div style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
            <Typography variant="body1" sx={{ fontWeight: 'bold', marginRight: '10px', marginBottom: '10px', width: '150px' }}>
              Salary Head Name:
            </Typography>
            <input
              type="text"
              placeholder='Salary Head Name'
              value={salaryHeadName}
              onChange={(e) => setSalaryHeadName(e.target.value)}
              style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', flex: '1' }}
            />
          </div>
          <div style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
            <Typography variant="body1" sx={{ fontWeight: 'bold', marginRight: '10px', marginBottom: '10px', width: '150px' }}>
              Head Type (+/-):
            </Typography>
            <FormControl style={{ flex: '0.53' }}>
              {/* <Select
                value={headType}
                onChange={(e) => setHeadType(e.target.value)}
                displayEmpty
                inputProps={{ 'aria-label': 'Select Head Type' }}
                style={{ height: '38px', fontSize: '14px' }} // Adjust height and font size

              >
                <MenuItem value="" disabled >
                  Select Head Type
                </MenuItem>
                <MenuItem value="+">+</MenuItem>
                <MenuItem value="-">-</MenuItem>
                <MenuItem value="E">E</MenuItem>
              </Select> */}
              <Autocomplete
                value={headType}
                onChange={(e, newValue) => setHeadType(newValue)}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={['+', '-', 'E']}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // label="Select Head Type"
                    // variant="outlined"
                    inputProps={{ ...params.inputProps, 'aria-label': 'Select Head Type', style: { paddingTop: '0px' } }}
                    sx={{
                      "& .MuiInputBase-root": { height: "38px" },
                    }}
                  />
                )}
              />
            </FormControl>
            <Typography variant="body1" sx={{ fontWeight: 'bold', marginRight: '10px', marginBottom: '10px', width: '120px', marginLeft: '80px' }}>
              Value:
            </Typography>
            <input
              type="number"
              placeholder='Value'
              value={value}
              onChange={(e) => setValue(e.target.value)}
              style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', flex: '0.5' }} // Adjusted flex to take half of the container width
            />
          
          </div>
          <div style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
            <Typography variant="body1" sx={{ fontWeight: 'bold', marginRight: '10px', marginBottom: '10px', width: '150px' }}>
              Calculation Basis:
            </Typography>
            <FormControl style={{ flex: '0.53' }}>
              {/* <Select
                value={calculationBasis}
                onChange={(e) => setCalculationBasis(e.target.value)}
                displayEmpty
                inputProps={{ 'aria-label': 'Select Head Type' }}
                style={{ height: '38px', fontSize: '14px' }} // Adjust height and font size

              >
                <MenuItem value="" disabled >
                  % of Basic
                </MenuItem>
                <MenuItem value="% of Gross">% of Gross</MenuItem>
                <MenuItem value="% of Basic">% of Basic</MenuItem>
                <MenuItem value="Fix Amount">Fix Amount</MenuItem>
                <MenuItem value="Manual">Manual</MenuItem>
                <MenuItem value="Remaining">Remaining</MenuItem>
                <MenuItem value="Production">Production</MenuItem>

              </Select> */}
              <Autocomplete
                value={calculationBasis}
                onChange={(event, newValue) => {
                  setCalculationBasis(newValue.value);
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={[
                  { label: '% of Basic', value: '% of Basic' },
                  { label: '% of Gross', value: '% of Gross' },
                  { label: 'Fix Amount', value: 'Fix Amount' },
                  { label: 'Manual', value: 'Manual' },
                  { label: 'Remaining', value: 'Remaining' },
                  { label: 'Production', value: 'Production' },
                  { label: 'Slab', value: 'Slab' },
                  { label: 'Formula', value: 'Formula' },

                ]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // label="Calculation Basis"
                    // variant="outlined"
                    inputProps={{ ...params.inputProps, 'aria-label': 'Calculation Basis', style: { paddingTop: '0px' } }}
                    sx={{
                      "& .MuiInputBase-root": { height: "38px", },
                    }}
                  />
                )}
              />
            </FormControl>
            <Typography variant="body1" sx={{ fontWeight: 'bold', marginRight: '10px', marginBottom: '10px', width: '120px', marginLeft: '80px' }}>
              Max Limit:
            </Typography>
            <input
              type="number"
              placeholder='Max Limit'
              value={maxLimit}
              onChange={(e) => setMaxLimit(e.target.value)}
              style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', flex: '0.5' }} // Adjusted flex to take half of the container width
            />
          </div>
          <div style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
            {/* <Typography variant="body1" sx={{ fontWeight: 'bold', marginRight: '10px', marginBottom: '10px', width: '150px' }}>
              Value:
            </Typography>
            <input
              type="number"
              placeholder='Value'
              value={value}
              onChange={(e) => setValue(e.target.value)}
              style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', flex: '0.5' }} // Adjusted flex to take half of the container width
            /> */}
            <Typography variant="body1" sx={{ fontWeight: 'bold', marginRight: '10px', marginBottom: '10px', width: '150px' }}>
              % of Field:
            </Typography>
            {/* <input
              type="text"
              placeholder='% of Field'
              value={percentageOfField}
              onChange={(e) => setPercentageOfField(e.target.value)}
              style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', flex: '0.5' }} // Adjusted flex to take half of the container width
            /> */}
            <div style={{flex:'1'}}>
            <Autocomplete
                value={SalryStucType}
                multiple
                onChange={(event, newValue) => {
                  setSalryStucType(newValue);
                }}
                options={SalryStucValues}
                getOptionLabel={(option) => option?.salaryhead_name}
                getOptionSelected={(option, value) => option._id === value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    // label="Sal"
                    size="small"
                    inputProps={{
                      ...params.inputProps,
                      'aria-label': 'Select Dealer',
                      style: { paddingTop: '0px',}
                    }}
                    InputProps={{
                      ...params.InputProps,
                      sx: {
                        height: '40px', // Adjust input height
                        // width: '180px',
                        overflow: 'hidden', // Prevent overflow
                      },
                    }}
                  />
                )}
                fullWidth
                PaperProps={{
                  style: {
                    maxHeight: '300px', // Limit dropdown menu height
                    overflowY: 'auto', // Enable vertical scrolling if needed
                    // flex: '0.5',
                  },
                }}
              />
            </div>
              
          </div>
          <div style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
            <Typography variant="body1" sx={{ fontWeight: 'bold', marginRight: '10px', marginBottom: '10px', width: '150px' }}>
              Round of Zero:
            </Typography>
            <FormControl style={{ flex: '0.54' }}>
              {/* <Select
                value={roundOfZero}
                onChange={(e) => setRoundOfZero(e.target.value)}
                displayEmpty
                inputProps={{ 'aria-label': 'Select Head Type' }}
                style={{ height: '38px', fontSize: '14px' }} // Adjust height and font size

              >
                <MenuItem value="" disabled >
                  Round of Zero
                </MenuItem>
                <MenuItem value="Y">Y</MenuItem>
                <MenuItem value="N">N</MenuItem>
              </Select> */}
              <Autocomplete
                value={roundOfZero}
                onChange={(event, newValue) => {
                  setRoundOfZero(newValue);
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={['Y', 'N']}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // label="Round of Zero"
                    // variant="outlined"
                    inputProps={{ ...params.inputProps, 'aria-label': 'Round of Zero', style: { paddingTop: '0px' } }}
                    sx={{
                      "& .MuiInputBase-root": { height: "38px", },
                    }}
                  />
                )}
              />
            </FormControl>
            <Typography variant="body1" sx={{ fontWeight: 'bold', marginRight: '10px', marginBottom: '10px', width: '120px', marginLeft: '80px' }}>
              Order:
            </Typography>
            <input
              type="number"
              placeholder='Order'
              value={order}
              onChange={(e) => setOrder(e.target.value)}
              style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', flex: '0.5' }} // Adjusted flex to take half of the container width
            />
          </div>

          <Grid item xs={12}>
            <Grid item xs={12}>
              {dropdownValues.map((item, index) => (
                <div key={index}>
                  <Paper elevation={0} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '0px', marginTop: '10px' }}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={4}>
                        <TextField
                          label="Limit"
                          variant="outlined"
                          value={item.Limit || ''}
                          fullWidth
                          size='small'
                          type='number'
                          onChange={(event) => handleLanguageChange(index, 'Limit', event.target.value)}
                        // margin="normal"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Max Limit"
                          variant="outlined"
                          value={item.MaxLimit || ''}
                          fullWidth
                          size='small'
                          type='number'
                          onChange={(event) => handleLanguageChange(index, 'MaxLimit', event.target.value)}
                        // margin="normal"
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="Value"
                          variant="outlined"
                          value={item.Value || ''}
                          fullWidth
                          type='number'
                          size='small'
                          onChange={(event) => handleLanguageChange(index, 'Value', event.target.value)}
                        // margin="normal"
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <Button onClick={() => handleLanguageDelete(index)}><DeleteIcon /></Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </div>
              ))}
            </Grid>
            <Grid item xs={12}>
              <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
                <Button onClick={handleLanguageAddMore}>Add More</Button>
              </div>
            </Grid>
          </Grid>

          {error && (
            <Typography variant="body2" sx={{ color: 'red', marginBottom: '10px', width: '100%', textAlign: 'center' }}>
              {error}
            </Typography>
          )}
          <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '12px' }}>
            <Button type="submit" variant="contained" color="success" onClick={handleSubmit} sx={{ marginRight: '10px' }}>
              Submit
            </Button>
            <Button variant="contained" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </Box>
      </Container>
      <Container maxWidth="xl" style={{ marginTop: '25px', minHeight: '400px' }}>
        <Box sx={{ border: '1px solid black', padding: '20px', marginBottom: '20px' }}>
          <Typography variant="h6" sx={{ color: 'white', margin: '0', backgroundColor: '#007bff', padding: '10px' }}>
            Employee Grade List
          </Typography>
          {/* <MUIDataTable title={""} data={dataWithSerialNumbers} columns={columns} options={options} /> */}
          <DataGrid
            rows={dataWithSerialNumbers}
            columns={columns}
            initialState={{
              ...data.initialState,
              pagination: { paginationModel: { pageSize: 10 } },

            }}
            pageSizeOptions={[10, 25, 100]}
            pagination
            autoHeight
            checkboxSelection={false}
            disableSelectionOnClick
            density="compact"
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
          />
        </Box>
      </Container>
      <Modal
        open={successModalOpen}
        onClose={handleSuccessModalClose}
        aria-labelledby="success-modal-title"
        aria-describedby="success-modal-description"
      >
        <div style={{ ...modalStyle, borderColor: '#007bff' }}>
          <h2 id="success-modal-title" style={{ color: '#007bff' }}>Success!</h2>
          <p id="success-modal-description">{actionMessage}</p>
          <Button variant="contained" onClick={handleSuccessModalClose} style={{ backgroundColor: '#007bff', color: 'white' }}>Close</Button>
        </div>
      </Modal>

      <Modal
        open={errorModalOpen}
        onClose={handleErrorModalClose}
        aria-labelledby="error-modal-title"
        aria-describedby="error-modal-description"
      >
        <div style={{ ...modalStyle, borderColor: '#ff0000' }}>
          <h2 id="error-modal-title" style={{ color: '#ff0000' }}>Error!</h2>
          <p id="error-modal-description">{actionMessage}.</p>
          <Button variant="contained" onClick={handleErrorModalClose} style={{ backgroundColor: '#ff0000', color: 'white' }}>Close</Button>
        </div>
      </Modal>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4, width: 400 }}>
          <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
            Confirm Deletion
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
            Are you sure you want to delete this item?
          </Typography>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={handleCloseModal} color="primary" sx={{ mr: 2 }}>
              Cancel
            </Button>
            <Button onClick={handleModalDelete} variant="contained" color="error">
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default SalaryHead;


import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Container, Paper } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Divider from '@mui/material/Divider';
import { useRef, useLayoutEffect } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    Modal, Grid, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Typography, TextField, Select, MenuItem
} from '@mui/material';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import dayjs from 'dayjs';
import { useLocation, useNavigate } from 'react-router-dom';

export default function SalaryAdd() {
    const location = useLocation();
    const navigate = useNavigate();

    const [month, setMonth] = useState('');
    const [availableMonths, setAvailableMonths] = useState([
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ]);


    const [employeeName, setemployeeName] = useState(null);
    const [EmpList, setEmpList] = useState([]);
    const [positiveRows, setPositiveRows] = useState([]);
    const [negativeRows, setNegativeRows] = useState([]);
    const [leave_or_group, setleave_or_group] = useState([]);
    const [LeaveType, setLeaveType] = useState([]);

    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [actionMessage, setActionMessage] = useState('');
    const [EditID, setEditID] = useState();
    const [data, setdata] = useState();
    const [positiveTotal, setPositiveTotal] = useState(0);
    const [negativeTotal, setNegativeTotal] = useState(0);
    const [GrossSal, setGrossSal] = useState(null);
    const [Gsal, setGsal] = useState(null);
    const [total_attendance, settotal_attendance] = useState(null);
    const [shifthour, setshifthour] = useState(0);
    const [ot_hrs, setot_hrs] = useState(null);
    const [amt, setamt] = useState(null);
    const [less_advance, setless_advance] = useState(null);
    const [less_loan, setless_loan] = useState(null);
    const [net_salary, setnet_salary] = useState(null);
    const [less_pf, setless_pf] = useState(null);
    const [less_esic, setless_esic] = useState(null);
    const [less_pt, setless_pt] = useState(null);
    const [SalCount, setSalCount] = useState(0);
    const [PFCalBy, setPFCalBy] = useState(0);
    const [ESICCalBy, setESICCalBy] = useState(0);

    const [salDays, setsalDays] = useState(null);
    const [salperhour, setsalperhour] = useState(null);
    const [salperday, setsalperday] = useState(null);
    const [calculationGross, setcalculationGross] = useState(null);
    const [dueAmount, setdueAmount] = useState(null);
    const [BasicSal, setBasicSal] = useState(0);
    const lastContentRef = useRef(null);
    useLayoutEffect(() => {
        if (lastContentRef.current) {
            lastContentRef.current.style.marginBottom = '95px';
        }
    }, []);

    useEffect(() => {
        const currentDate = new Date();
        const currentMonth = currentDate.toLocaleString('default', { month: 'long' });
        setMonth(currentMonth);
    }, []);

    useEffect(() => {
        setemployeeName('');
        setGrossSal('');
    }, [month]);

    const handleMonthChange = (event) => {
        setMonth(event.target.value);
    };

    const fetchSalaryData = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const masterid = userSchema?.masterid;
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/salart_handleRouter/AddEmployeeSalaryGET`, {
                _id: employeeName?._id,
                month: month,
                masterid,
                compid: JSON.parse(companyIdString),
                divid: JSON.parse(divIdString)
            },
                {
                    params: {
                        _id: employeeName?._id,
                        // month: "June",
                        masterid,
                        compid: JSON.parse(companyIdString),
                        divid: JSON.parse(divIdString)
                    },
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );
            return response?.data?.lastEntryNo[0];
        } catch (error) {
            console.error('Error fetching data:', error);
            return null
        }
    };

    const handleSave = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');

            const salryhd_or_groups = [...positiveRows, ...negativeRows];
            const transformedData = salryhd_or_groups?.map(item => {
                const { _id, ...rest } = item; // Destructure _id out of item
                return {
                    ...rest,
                    salaryhead_name: item.salaryhead_name._id
                };
            });

            const transformedLeave = leave_or_group?.map(item => {
                const { _id, ...rest } = item; // Destructure _id out of item
                return {
                    ...rest,
                    leave_name: item.leave_name._id
                };
            });

            const data = {
                user: userSchema?.usrnm,
                compid: JSON.parse(companyIdString),
                divid: JSON.parse(divIdString),
                masterid: userSchema?.masterid,
                salryhd_or_group: transformedData,
                leave_or_group: transformedLeave,
                month,
                employee: employeeName?._id,
                gross_salary: GrossSal,
                total_attendance,
                ot_hrs,
                amt,
                less_advance,
                less_loan,
                net_salary: dueAmount,
                less_pf,
                less_esic,
                less_pt,
                calculated_gross: calculationGross,
                total_add: positiveTotal,
                total_less: negativeTotal,
                PFCalBy,
                ESICCalBy,
            };

            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/salart_handleRouter/Salary_Add`, data, {
                headers: {
                    authorization: `Bearer ${token}`
                }
            })
                .then(response => {
                    if (response.data.status) {
                        setActionMessage(response.data.message)
                        setSuccessModalOpen(true);
                        setTimeout(() => {
                            navigate('/transaction/salaryList');
                        }, 1000);
                    } else {
                        setActionMessage(response.data.message)
                        setErrorModalOpen(true)
                    }
                })
                .catch(error => {
                    console.log(error)
                    setActionMessage("Ooops an error occured.")
                    setErrorModalOpen(true)
                });
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };

    const EmpListGET = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');

            const data = {
                user: userSchema?.usrnm,
                compid: JSON.parse(companyIdString),
                divid: JSON.parse(divIdString),
                masterid: userSchema?.masterid,
                month
            }
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/salart_handleRouter/EmployeeSalaryNamesGET`, data,
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );

            const employeeIdSet = new Set(response?.data?.employeeIds);

            const filteredEntries = await response.data?.lastEntryNo.filter(entry => !employeeIdSet.has(entry._id));
            setEmpList(filteredEntries);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchLeaveType = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');


            const data = {
                user: userSchema?.usrnm,
                compid: JSON.parse(companyIdString),
                divid: JSON.parse(divIdString),
                masterid: userSchema?.masterid
            };
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/attendance/LeaveTypeGET`, data,
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );

            setLeaveType(response.data.lastEntryNo);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchLeaveType();
    }, [])

    useEffect(() => {
        EmpListGET();
    }, [month])


    useEffect(() => {
        const fetchData = async () => {
            try {
                const Saldata = await fetchSalaryData();
                // setEditID(Saldata?._id);
                setsalperhour((Number(Saldata?.gross_sal || 0) / 30) / Number(Saldata?.shift || 0));
                setshifthour(Saldata?.shift || 0);
                setsalperday(Number(Saldata?.gross_sal) / 30);
                setsalDays(Saldata?.days || 26);
                setGrossSal(Saldata?.gross_sal || 0);
                settotal_attendance(Saldata?.total_attendance || 0);
                setot_hrs(Saldata?.totalOvertime.toFixed(2) || 0);
                setamt(Saldata?.amt || 0);
                setless_advance(Saldata?.advance || 0);
                setless_loan(Saldata?.loan || 0);
                setnet_salary(Saldata?.net_salary || 0)
                setSalCount(Saldata?.SalaryCount || 0)
                setleave_or_group(Saldata?.leave_or_group || []);
                if (Saldata?.leave_or_group?.length === 0 && employeeName) {
                    setActionMessage("No Attendance Found. Add Attendance to Proceed.")
                    setErrorModalOpen(true)
                }
                setdata(Saldata)
                const positiveData = await Saldata?.salryhd_or_group?.filter(item => item?.salryhd_sign === '(+)');
                const negativeData = await Saldata?.salryhd_or_group?.filter(item => item?.salryhd_sign === '(-)');

                setPositiveRows(positiveData || []);
                setNegativeRows(negativeData || []);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData(); // Call the asynchronous function
    }, [employeeName]);

    const handlePositiveChange = (event, index, field) => {
        const updatedRows = [...positiveRows];
        updatedRows[index][field] = event;
        setPositiveRows(updatedRows);
    };

    const handlePositiveAmountCal = (event, index, type,) => {
        if (type === "Fix Amount") {
            handlePositiveChange((event.target.value / parseFloat(salDays) * parseFloat(total_attendance)).toFixed(2), index, 'amount');
        }
        else if (type === "% of Basic") {
            handlePositiveChange((parseFloat(BasicSal) * event.target.value / 100).toFixed(2), index, 'amount');
        }
        else if (type === "% of Gross") {
            handlePositiveChange((parseFloat(GrossSal) / parseFloat(salDays) * parseFloat(total_attendance)).toFixed(2), index, 'amount');
        }
    }

    const handleNegativeChange = (event, index, field) => {
        const updatedRows = [...negativeRows];
        updatedRows[index][field] = event;
        setNegativeRows(updatedRows);
    };

    const handleNegativeAmountCal = (event, index, type, name) => {
        // if (name === "ESIC") {
        //     const x = parseFloat(GrossSal) / parseFloat(salDays);
        //     const y = x * parseFloat(total_attendance);
        //     const z = y + parseFloat(amt);
        //     handleNegativeChange((parseFloat(GrossSal) + parseFloat(amt)) * event.target.value / 100, index, 'amount');
        //     handleNegativeChange(
        //         (z * event.target.value / 100).toFixed(2),
        //         index,
        //         'amount'
        //     );
        // }

        // else if (name === "Professional Tax") {
        //     handleNegativeChange((event.target.value).toFixed(2), index, 'amount');
        // }
        // else 
        if (type === "Fix Amount") {
            handleNegativeChange((event.target.value / parseFloat(salDays) * parseFloat(total_attendance)).toFixed(2), index, 'amount');
        }
        else if (type === "% of Basic") {
            handleNegativeChange((parseFloat(BasicSal) * event.target.value / 100).toFixed(2), index, 'amount');
        }
        else if (type === "% of Gross") {
            handleNegativeChange((parseFloat(GrossSal) / parseFloat(salDays) * parseFloat(total_attendance)).toFixed(2), index, 'amount');
        }
    }


    const handleLeaveChange = (event, index, field) => {
        const updatedRows = [...leave_or_group];
        updatedRows[index][field] = event;
        setleave_or_group(updatedRows);
    };

    const handleAddMore = () => {
        if (leave_or_group.length > 0) {
            setleave_or_group([...leave_or_group, { nos: "", hrs: "", leave_name: {} }]);
        }
        else {
            setleave_or_group([{ nos: "", hrs: "", leave_name: {} }]);
        }
    };

    const handleDelete = (index) => {
        const newleave_or_group = [...leave_or_group];
        newleave_or_group.splice(index, 1);
        setleave_or_group(newleave_or_group);
    };

    useEffect(() => {
        const pf = negativeRows?.find(item => item.salaryhead_name.salaryhead_name === 'PF');
        const esic = negativeRows?.find(item => item.salaryhead_name.salaryhead_name === 'ESIC');
        const pt = negativeRows?.find(item => item.salaryhead_name.salaryhead_name === 'Professional Tax');

        setless_pf(pf?.amount || 0);
        setless_esic(esic?.amount || 0);
        setless_pt(pt?.amount || 0);
    }, [negativeRows]);

    useEffect(() => {
        // Calculate total for earnings
        let positiveSum = 0;
        positiveRows?.forEach(row => {
            console.log(row)
            positiveSum += parseFloat(row.amount || 0);
            if (row?.salaryhead_name?.salaryhead_name == "BASIC") {
                setBasicSal(parseFloat(row.amount || 0));
            }
        });
        setPositiveTotal(positiveSum);

        // Calculate total for deductions
        let negativeSum = 0;
        negativeRows?.forEach(row => {
            negativeSum += parseFloat(row.amount || 0);
        });
        setNegativeTotal(negativeSum);

        // // Calculate due amount
        // const grossSalary = parseFloat(GrossSal || 0);
        // const earningsTotal = positiveSum;
        // const deductionsTotal = negativeSum;
        // const due = grossSalary + earningsTotal - deductionsTotal;
        // setDueAmount(due);
    }, [positiveRows, negativeRows]);

    function addTotalCount(data) {
        let totalCount = 0;
        let abscount = 0;
        let presentCount = 0;

        data?.forEach(item => {
            const day_count = item?.leave_name?.day_count ? Number(item?.leave_name?.day_count) : 1;
            const benifit_leave = item?.leave_name?.benifit_leave;
            //abscount = item?.leave_name?.benifit_leave;
            if (benifit_leave === 'N') abscount += Number(item.nos) * day_count;

            if (benifit_leave === 'Y' || benifit_leave === 'Holiday') {
                // totalCount += Number(item.nos) * day_count;
                presentCount += Number(item.nos) * day_count;
            }
        });
        if (parseFloat(salDays) == 30) totalCount = 30 - abscount
        if (presentCount > 0) {
            return totalCount;
        }
        else {
            return 0;
        }
    }

    useEffect(() => {
        settotal_attendance(addTotalCount(leave_or_group));
    }, [leave_or_group]);

    function calculateValue(data, salt, count) {
        const sal = salt * 12;
        const range = data?.find(item => sal >= parseInt(item.Limit) && sal <= parseInt(item.MaxLimit));

        if (!range) {
            return 0;
        }
        const valuePerMonth = parseInt(range.Value) / 12;
        const integerPart = Math.floor(valuePerMonth);
        const fractionalPart = valuePerMonth - integerPart;
        // console.log(fractionalPart.toFixed(2) )
        if (count < 12) {
            return integerPart;
        } else {
            return Math.ceil(fractionalPart?.toFixed(2) * 12) + integerPart;
        }
    }

    const calculateAmount = (value) => {
        if (value?.salryhd_calcb === "Fix Amount") {
            return (value.salryhd_vlu / parseFloat(salDays) * parseFloat(total_attendance)).toFixed(2);
        }
        else if (value?.salryhd_calcb === "% of Basic") {
            return (parseFloat(BasicSal) * value.salryhd_vlu / 100).toFixed(2);
        }
        else if (value?.salryhd_calcb === "% of Gross") {
            return ((parseFloat(GrossSal) / parseFloat(salDays) * parseFloat(total_attendance)) * value.salryhd_vlu / 100).toFixed(2);
        }
        else if (value?.salryhd_calcb === "Formula") {
            const PercField = value?.salaryhead_name?.FieldArr || [];

            const matchingData = positiveRows.filter(item => PercField.includes(item?.salaryhead_name?._id));
            const totalPercentage = matchingData.reduce((acc, item) => acc + (parseFloat(item?.amount || 0) * parseFloat(value?.salryhd_vlu) / 100), 0);

            return totalPercentage?.toFixed(2);
        }
        else if (value?.salryhd_calcb === "Slab") {
            const PercField = value?.salaryhead_name?.LimitArr || [];
            const result = calculateValue(PercField, GrossSal, SalCount);
            // console.log("**********",result);
            return result;
        }
    };


    // Update positiveRows when total_attendance changes
    useEffect(() => {
        setPositiveRows((prevRows) =>
            prevRows?.map((row) => ({
                ...row,
                amount: calculateAmount(row)
            }))
        );
    }, [total_attendance, BasicSal, GrossSal]);

    const calculateNegativeAmount = (value) => {
        if (value?.salryhd_calcb === "Fix Amount") {
            return (value.salryhd_vlu / parseFloat(salDays) * parseFloat(total_attendance)).toFixed(2);
        }
        else if (value?.salryhd_calcb === "% of Basic") {
            if (value?.salaryhead_name?.salaryhead_name === 'PF') {
                if (data?.pf_application === 'Y') {
                    setPFCalBy(parseFloat(BasicSal));
                    return Math.round(parseFloat(BasicSal) * value.salryhd_vlu / 100);
                }
                else {
                    return 0.00;
                }
            }
            else {
                return (parseFloat(BasicSal) * value.salryhd_vlu / 100).toFixed(2);

            }
        }
        else if (value?.salryhd_calcb === "% of Gross") {

            if (value?.salaryhead_name?.salaryhead_name === 'ESIC') {
                if (data?.esi_applicable === 'Y') {
                    setESICCalBy(parseFloat((parseFloat(GrossSal) / parseFloat(salDays) * parseFloat(total_attendance)) + Number(amt)));
                    return Math.round(((parseFloat(GrossSal) / parseFloat(salDays) * parseFloat(total_attendance)) + Number(amt)) * value.salryhd_vlu / 100);
                }
                else {
                    return 0.00;
                }
            }
            else {
                return ((parseFloat(GrossSal) / parseFloat(salDays) * parseFloat(total_attendance)) * value.salryhd_vlu / 100).toFixed(2);
            }
        }
        else if (value?.salryhd_calcb === "Formula") {
            const PercField = value?.salaryhead_name?.FieldArr || [];

            const matchingData = positiveRows.filter(item => PercField.includes(item?.salaryhead_name?._id));
            const totalPercentage = matchingData.reduce((acc, item) => acc + (parseFloat(item?.amount || 0) * parseFloat(value?.salryhd_vlu) / 100), 0);
            const FieldArrVal = matchingData.reduce((acc, item) => acc + parseFloat(item?.amount || 0), 0);

            if (value?.salaryhead_name?.salaryhead_name === 'ESIC') {
                if (data?.esi_applicable === 'Y') {
                    setESICCalBy(parseFloat(FieldArrVal));
                    return Math.round(totalPercentage);
                }
                else {
                    return 0.00;
                }
            }
            else if (value?.salaryhead_name?.salaryhead_name === 'PF') {
                if (data?.pf_application === 'Y') {
                    setPFCalBy(parseFloat(FieldArrVal));
                    return Math.round(totalPercentage);
                }
                else {
                    return 0.00;
                }
            }
            else {
                return totalPercentage?.toFixed(2);
            }
        }
        else if (value?.salryhd_calcb === "Slab") {
            const PercField = value?.salaryhead_name?.LimitArr || [];
            const result = calculateValue(PercField, GrossSal, SalCount);
            // console.log("**********",result);
            if (value?.salaryhead_name?.salaryhead_name === 'Professional Tax') {
                if (data?.pt_application === 'Y') {
                    return result;
                }
                else {
                    return 0.00;
                }
            }
            // return result;
        }
    };

    useEffect(() => {
        setNegativeRows((prevRows) =>
            prevRows?.map((row) => ({
                ...row,
                amount: calculateNegativeAmount(row)
            }))
        );
    }, [total_attendance, BasicSal, GrossSal, amt, positiveTotal]);

    useEffect(() => {
        console.log(data?.ol_appl);
        if ((data?.ol_appl && data?.ol_appl === 'Y') || (data?.ol_appl && data?.ol_appl === '1.5')) {
            var otamtcalc = (Number(ot_hrs) * salperhour)
            if (data?.ol_appl && data?.ol_appl === '1.5') otamtcalc = parseFloat(otamtcalc) * 1.5
            setamt(otamtcalc.toFixed(0));
        }

        // else if (data?.ol_appl && data?.ol_appl === 'N') {
        //     const validOtHrs = Math.max(Number(ot_hrs), 0);
        //     const calculatedAmt = (validOtHrs * salperhour).toFixed(0);
        //     setamt(calculatedAmt);
        // }
    }, [ot_hrs, salperhour]);

    useEffect(() => {
        const total = Number(positiveTotal) - Number(negativeTotal);
        setcalculationGross(total.toFixed(2));
    }, [positiveTotal, negativeTotal]);

    useEffect(() => {
        const total = (Number(calculationGross) + Number(amt) - Number(less_loan) - Number(less_advance));
        setdueAmount(total.toFixed(2));
    }, [calculationGross, amt, less_loan, less_advance]);

    useEffect(() => {
        setsalperhour((Number(GrossSal) / 30) / Number(shifthour));
    }, [GrossSal])
    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        border: '2px solid',
        boxShadow: 24,
        padding: 16,
        minWidth: 300,
        maxWidth: 500,
        textAlign: 'center'
    };
    const handleSuccessModalClose = () => {
        setSuccessModalOpen(false);
    };

    const handleErrorModalClose = () => {
        setErrorModalOpen(false);
    };

    return (
        <Container sx={{ marginTop: '10px' }} ref={lastContentRef}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                {/* <Typography variant="body1" sx={{ fontWeight: 'bold', marginRight: '10px', width: '410px', fontSize: '20px' }}>
                    Update Salary
                </Typography> */}
            </div>
            <Paper sx={{ padding: '5px' }}>
                <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
                    <Grid item xs={2}>
                        <Typography variant="body1" sx={{ fontWeight: 'bold', marginRight: '10px', fontSize: '20px' }}>
                            Add Salary
                        </Typography>
                    </Grid>

                    <Grid item xs={4}>
                        {/* <Typography variant='subtitle1' sx={{ fontWeight: 'bold', background: 'linear-gradient(135deg, #FFFFFF,#60a5f2,#FFFFFF)', textAlign: 'center' }}>{employeeName}</Typography> */}
                        <Autocomplete
                            value={employeeName}
                            // readOnly={true}
                            // onChange={(event, newValue) => {
                            //     setemployeeName(newValue);
                            // }}
                            onChange={(event, newValue) => setemployeeName(newValue)}
                            options={EmpList}
                            getOptionLabel={(option) => option?.full_name || ""}
                            getOptionSelected={(option, value) => option._id === value}
                            style={{ height: '38px', fontSize: '14px' }}
                            renderInput={(params) => <TextField {...params} variant="outlined" size='small'

                                inputProps={{ ...params.inputProps, 'aria-label': 'Select Department Type', style: { paddingTop: '0px' } }}
                                sx={{
                                    "& .MuiInputBase-root": { height: "30px" },
                                }}
                            />}
                        />
                    </Grid>

                    <Grid item xs={2}>
                        <Autocomplete
                            value={month}
                            onChange={(event, newValue) => setMonth(newValue)}
                            options={availableMonths}
                            style={{ height: '38px', fontSize: '14px' }}
                            renderInput={(params) => <TextField {...params} variant="outlined" size='small'

                                inputProps={{ ...params.inputProps, 'aria-label': 'Select Department Type', style: { paddingTop: '0px' } }}
                                sx={{
                                    "& .MuiInputBase-root": { height: "30px" },
                                }}
                            />}
                        />
                    </Grid>
                </Grid>
            </Paper>

            {/* <Paper sx={{ padding: '10px', marginTop: '5px' }}>
                <Grid container alignItems="center" justifyContent="flex-start" spacing={2}>
                    <Grid item xs={2}>
                        <Typography sx={{ fontWeight: 'bold' }}>Gross Salary</Typography>
                        <TextField
                            value={GrossSal}
                            onChange={(event) => setGrossSal(event.target.value)}
                            size="small"
                            InputProps={{ sx: { textAlign: 'right', height: '30px' } }}
                            type='number'
                        />
                    </Grid>

                    <Grid item xs={2}>
                        <Typography sx={{ fontWeight: 'bold' }}>Total Attendance</Typography>
                        <TextField
                            value={total_attendance}
                            onChange={(event) => settotal_attendance(event.target.value)}
                            size="small"
                            InputProps={{ sx: { textAlign: 'right', height: '30px' }, readOnly: true }}
                            type='number'
                        />

                    </Grid>

                    <Grid item xs={2}>
                        <Typography sx={{ fontWeight: 'bold' }}>OT Hours</Typography>
                        <TextField
                            value={ot_hrs}
                            onChange={(event) => setot_hrs(event.target.value)}
                            size="small"
                            InputProps={{ sx: { textAlign: 'right', height: '30px' } }}

                            type='number'
                        />
                    </Grid>

                    <Grid item xs={2}>
                        <Typography sx={{ fontWeight: 'bold' }}>OT Amount	</Typography>
                        <TextField
                            value={amt}
                            onChange={(event) => setamt(event.target.value)}
                            size="small"
                            InputProps={{ sx: { textAlign: 'right', height: '30px' }, readOnly: true }}
                            type='number'
                        />
                    </Grid>

                    <Grid item xs={2}>
                        <Typography sx={{ fontWeight: 'bold' }}>Advance</Typography>
                        <TextField
                            value={less_advance}
                            onChange={(event) => setless_advance(event.target.value)}
                            size="small"
                            InputProps={{ sx: { textAlign: 'right', height: '30px' } }}
                            type='number'
                        />
                    </Grid>

                    <Grid item xs={2}>
                        <Typography sx={{ fontWeight: 'bold' }}>Loan</Typography>
                        <TextField
                            value={less_loan}
                            onChange={(event) => setless_loan(event.target.value)}
                            size="small"
                            InputProps={{ sx: { textAlign: 'right', height: '30px' }, }}
                            type='number'
                        />
                    </Grid>

                </Grid>
            </Paper> */}

            <Paper sx={{ padding: '10px', marginTop: '5px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={6}>
                        <Grid item xs={12} md={12} lg={12}>
                            <TableContainer sx={{ fontSize: '0.8rem' }}>
                                <Table size="small">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell sx={{ fontWeight: 'bold', width: '50%', padding: '4px' }}>Leave</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold', padding: '4px' }}>Number</TableCell>
                                            {/* <TableCell sx={{ fontWeight: 'bold', padding: '4px' }}>Hours</TableCell> */}
                                            <TableCell sx={{ fontWeight: 'bold', padding: '4px' }}>Delete</TableCell>
                                        </TableRow>
                                        {leave_or_group?.map((value, index) => (
                                            <TableRow key={index} sx={{ padding: '4px' }}>
                                                <TableCell sx={{ padding: '4px' }}>
                                                    {/* {value.leave_name?.discription || 'Not Marked'} */}
                                                    <Autocomplete
                                                        value={value?.leave_name}
                                                        // readOnly={true}
                                                        // onChange={(event, newValue) => {
                                                        //     setemployeeName(newValue);
                                                        // }}
                                                        onChange={(event, newValue) => handleLeaveChange(newValue, index, 'leave_name')}
                                                        options={LeaveType}
                                                        getOptionLabel={(option) => option?.discription || ""}
                                                        getOptionSelected={(option, value) => option._id === value}
                                                        style={{ height: '30px', fontSize: '14px' }}
                                                        renderInput={(params) => <TextField {...params} variant="standard"

                                                            inputProps={{ ...params.inputProps, 'aria-label': 'Select Department Type', style: { paddingTop: '0px' } }}
                                                            sx={{
                                                                "& .MuiInputBase-root": { height: "30px" },
                                                            }}
                                                        />}
                                                    />
                                                </TableCell>
                                                <TableCell sx={{ padding: '0px' }}>
                                                    <TextField
                                                        value={value.nos}
                                                        onChange={(event) => handleLeaveChange(event.target.value, index, 'nos')}
                                                        size="small"
                                                        variant='outlined'
                                                        InputProps={{ sx: { textAlign: 'right', height: '30px' }, }}
                                                    />
                                                </TableCell>
                                                {/* <TableCell sx={{ padding: '0px' }}>
                                                    <TextField
                                                        value={value.hrs}
                                                        onChange={(event) => handleLeaveChange(event.target.value, index, 'hrs')}
                                                        size="small"
                                                        variant="outlined"
                                                        InputProps={{ sx: { textAlign: 'right', height: '30px' }, }}
                                                    />
                                                </TableCell> */}
                                                <TableCell sx={{ padding: '0px' }}>
                                                    <Button onClick={() => handleDelete(index)}><DeleteIcon /></Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={12}>
                            <Button onClick={handleAddMore} disabled={leave_or_group.length === 0}>Add More</Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>

                        <Paper sx={{ padding: '10px' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        value={GrossSal}
                                        onChange={(event) => setGrossSal(event.target.value)}
                                        size="small"
                                        InputProps={{ sx: { textAlign: 'right', height: '30px' } }}
                                        InputLabelProps={{ shrink: true }}
                                        type='number'
                                        label='Gross Salary'
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        value={total_attendance}
                                        onChange={(event) => settotal_attendance(event.target.value)}
                                        size="small"
                                        InputProps={{ sx: { textAlign: 'right', height: '30px' }, readOnly: true }}
                                        InputLabelProps={{ shrink: true }}
                                        type='number'
                                        label='Total Attendance'
                                    // sx={{ marginTop: '10px' }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        value={ot_hrs}
                                        onChange={(event) => setot_hrs(event.target.value)}
                                        size="small"
                                        InputProps={{ sx: { textAlign: 'right', height: '30px' } }}
                                        InputLabelProps={{ shrink: true }}
                                        label='OT Hours'
                                        type='number'
                                        sx={{ marginTop: '10px' }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        value={amt}
                                        onChange={(event) => setamt(event.target.value)}
                                        size="small"
                                        InputProps={{ sx: { textAlign: 'right', height: '30px' }, readOnly: true }}
                                        InputLabelProps={{ shrink: true }}
                                        type='number'
                                        sx={{ marginTop: '10px' }}
                                        label='OT Amount'
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        value={less_advance}
                                        onChange={(event) => setless_advance(event.target.value)}
                                        size="small"
                                        InputProps={{ sx: { textAlign: 'right', height: '30px' } }}
                                        InputLabelProps={{ shrink: true }}
                                        type='number'
                                        sx={{ marginTop: '10px' }}
                                        label='Advance'
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        value={less_loan}
                                        onChange={(event) => setless_loan(event.target.value)}
                                        size="small"
                                        InputProps={{ sx: { textAlign: 'right', height: '30px' } }}
                                        InputLabelProps={{ shrink: true }}
                                        type='number'
                                        sx={{ marginTop: '10px' }}
                                        label='Loan'
                                    />
                                </Grid>
                            </Grid>
                        </Paper>

                    </Grid>
                </Grid>
            </Paper>

            <Paper sx={{ padding: '10px', marginTop: '5px' }}>
                <Grid container spacing={2}>
                    {/* Earnings Section */}
                    <Grid item xs={6}>
                        <TableContainer sx={{ fontSize: '0.8rem' }}>
                            <Table size="small">
                                <TableBody>
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: 'bold', width: '30%', padding: '4px' }}>Earnings</TableCell>
                                        {/* <TableCell sx={{ fontWeight: 'bold', padding: '4px' }}>Calculation</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold', padding: '4px' }}>Round</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold', padding: '4px' }}>Order</TableCell> */}
                                        <TableCell sx={{ fontWeight: 'bold', padding: '4px' }}>Actual</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold', padding: '4px' }}>Calculated</TableCell>
                                    </TableRow>
                                    {Object.entries(positiveRows).map(([index, value]) => (
                                        <TableRow key={index} sx={{ padding: '4px' }}>
                                            <TableCell sx={{ padding: '4px' }}>
                                                {value?.salaryhead_name?.salaryhead_name || 'Not Marked'}
                                            </TableCell>
                                            <TableCell sx={{ padding: '4px' }}>
                                                <TextField
                                                    value={value?.salryhd_vlu}
                                                    onChange={(event) => { handlePositiveChange(event.target.value, index, 'salryhd_vlu'); handlePositiveAmountCal(event, index, value?.salryhd_calcb); }}
                                                    size="small"
                                                    type='number'
                                                    InputProps={{ sx: { textAlign: 'right', height: '30px' }, }}
                                                />
                                            </TableCell>
                                            <TableCell sx={{ padding: '4px' }}>
                                                <TextField
                                                    value={value?.amount}
                                                    size="small"
                                                    type='number'
                                                    InputProps={{ sx: { textAlign: 'right', height: '30px' }, readOnly: true }}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}

                                    <TableRow>
                                        <TableCell sx={{ padding: '4px', fontWeight: 'bold', }}>
                                            Total Earnings
                                        </TableCell>

                                        <TableCell sx={{ padding: '4px' }}>

                                        </TableCell>
                                        <TableCell sx={{ padding: '10px', fontWeight: 'bold', }}>
                                            {positiveTotal}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </Grid>
                    {/* Deductions Section */}
                    <Grid item xs={6}>
                        <TableContainer sx={{ fontSize: '0.8rem' }}>
                            <Table size="small">
                                <TableBody>
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: 'bold', width: '30%', padding: '4px' }}>Deductions</TableCell>
                                        {/* <TableCell sx={{ fontWeight: 'bold', padding: '4px' }}>Calculation</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold', padding: '4px' }}>Round</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold', padding: '4px' }}>Order</TableCell> */}
                                        <TableCell sx={{ fontWeight: 'bold', padding: '4px' }}>Actual</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold', padding: '4px' }}>Calculated</TableCell>
                                    </TableRow>
                                    {Object.entries(negativeRows).map(([index, value]) => (
                                        <TableRow key={index} sx={{ padding: '4px' }}>
                                            <TableCell sx={{ padding: '4px' }}>
                                                {value?.salaryhead_name?.salaryhead_name || 'Not Marked'}
                                            </TableCell>
                                            <TableCell sx={{ padding: '4px' }}>
                                                <TextField
                                                    value={value?.salryhd_vlu}
                                                    onChange={(event) => { handleNegativeChange(event.target.value, index, 'salryhd_vlu'); handleNegativeAmountCal(event, index, value?.salryhd_calcb, value?.salaryhead_name?.salaryhead_name) }}
                                                    size="small"
                                                    type='number'
                                                    InputProps={{ sx: { textAlign: 'right', height: '30px' }, }}
                                                />
                                            </TableCell>
                                            <TableCell sx={{ padding: '4px' }}>
                                                <TextField
                                                    value={value?.amount}
                                                    // onChange={(event) => handleNegativeChange(event, index, 'amount')}
                                                    size="small"
                                                    type='number'
                                                    InputProps={{ sx: { textAlign: 'right', height: '30px' }, readOnly: true }}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow>
                                        <TableCell sx={{ padding: '4px', fontWeight: 'bold', }}>
                                            Total Deductions
                                        </TableCell>
                                        <TableCell sx={{ padding: '4px' }}>

                                        </TableCell>
                                        <TableCell sx={{ padding: '10px', fontWeight: 'bold', }}>
                                            {negativeTotal}
                                        </TableCell>
                                    </TableRow>

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Paper>

            <Paper sx={{ padding: '10px', marginTop: '5px' }}>
                <Grid container>
                    <Grid item xs={12} md={6} lg={6}>
                        <TableContainer>
                            <Table size="small">
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={10} sx={{ fontWeight: 'bold' }}>Calculate Gross</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}></TableCell>
                                        <TableCell colSpan={2} sx={{ fontWeight: 'bold', textAlign: 'center' }}>{calculationGross || '0'}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={10} sx={{ fontWeight: 'bold' }}>OT Amount</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}></TableCell>
                                        <TableCell colSpan={2} sx={{ fontWeight: 'bold', textAlign: 'center' }}>{amt || '0'}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={10} sx={{ fontWeight: 'bold' }}>    Due Amount: {dueAmount}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <TableContainer>
                            <Table size="small">
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={10} sx={{ fontWeight: 'bold' }}>Advance</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}></TableCell>
                                        <TableCell colSpan={2} sx={{ fontWeight: 'bold', textAlign: 'center' }}>{less_advance || '0'}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={10} sx={{ fontWeight: 'bold' }}>Loan</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}></TableCell>
                                        <TableCell colSpan={2} sx={{ fontWeight: 'bold', textAlign: 'center' }}>{less_loan || '0'}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Paper>


            <Paper style={{ padding: '16px', position: 'fixed', bottom: '16px', zIndex: 1000, width: '50%', left: '0', right: '0', margin: '0 auto' }}>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '8px' }}>
                    <Button style={{ marginRight: '8px' }} variant="contained" color="error" onClick={() => { navigate('/transaction/salaryList') }}>
                        Cancel
                    </Button>
                    <Button disabled={leave_or_group.length === 0} style={{ marginLeft: '8px' }} variant="contained" color="primary" onClick={() => { handleSave() }} >
                        Save
                    </Button>
                </div>
            </Paper>
            <Modal
                open={successModalOpen}
                onClose={handleSuccessModalClose}
                aria-labelledby="success-modal-title"
                aria-describedby="success-modal-description"
            >
                <div style={{ ...modalStyle, borderColor: '#007bff' }}>
                    <h2 id="success-modal-title" style={{ color: '#007bff' }}>Success!</h2>
                    <p id="success-modal-description">{actionMessage}</p>
                    <Button variant="contained" onClick={handleSuccessModalClose} style={{ backgroundColor: '#007bff', color: 'white' }}>Close</Button>
                </div>
            </Modal>
            <Modal
                open={errorModalOpen}
                onClose={handleErrorModalClose}
                aria-labelledby="error-modal-title"
                aria-describedby="error-modal-description"
            >
                <div style={{ ...modalStyle, borderColor: '#ff0000' }}>
                    <h2 id="error-modal-title" style={{ color: '#ff0000' }}>Error!</h2>
                    <p id="error-modal-description">{actionMessage}.</p>
                    <Button variant="contained" onClick={handleErrorModalClose} style={{ backgroundColor: '#ff0000', color: 'white' }}>Close</Button>
                </div>
            </Modal>
        </Container>
    );
}

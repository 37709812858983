import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import axios from 'axios';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import DeleteIcon from '@mui/icons-material/Delete';



import { useLocation } from 'react-router-dom';
import ProfileNav from './profileNav';
import ProfileHeader from './profileheader';

// Extend Day.js with plugins
dayjs.extend(utc);
dayjs.extend(timezone);

const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
const AttendanceModeOptions = ["Manual Attendance", "Location Based", "Selfie & Location Based"];
const Genderoptions = ["Male", "Female", "Other"];
const maritalStatusOptions = ["Single", "Married", "Divorced", "Widowed"];
const BloodGroupOptions = ["A positive (A+)", "A negative (A-)", "B positive (B+)", "B negative (B-)", "AB positive (AB+)", "AB negative (AB-)", "O positive (O+)", "O negative (O-)"];
const YNOptions = ["Y", "N"];

function Profile() {
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [actionMessage, setActionMessage] = useState('');
    const [fileStates, setFileStates] = useState([]);

    const [DepValues, setDepValues] = useState([]);
    const [DesigValues, setDesigValues] = useState([]);
    const [CatValues, setCatValues] = useState([]);
    const [ShiftValues, setShiftValues] = useState([]);
    const [BankValues, setBankValues] = useState([]);
    const [MachineNameValues, setMachineNameValues] = useState([]);
    const [DivValues, setDivValues] = useState([]);

    const [StaffData, setStaffData] = useState({});
    const [openProfileModal, setOpenProfileModal] = useState(false);
    const [openGeneralModal, setOpenGeneralModal] = useState(false);
    const [openPersonalModal, setOpenPersonalModal] = useState(false);
    const [openEmploymentModal, setOpenEmploymentModal] = useState(false);
    const [openBankModal, setOpenBankModal] = useState(false);
    const [openUPIModal, setOpenUPIModal] = useState(false);
    const [openOtherModal, setOpenOtherModal] = useState(false);

    const [upiID, setUpiID] = useState('');

    const [name, setName] = useState('');
    const [id, setId] = useState('');
    const [department, setDepartment] = useState('');
    const [machineCode, setmachineCode] = useState('');

    const [designation, setDesignation] = useState('');
    const [staffType, setStaffType] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [reportingManager, setReportingManager] = useState('');
    const [machineName, setmachineName] = useState('');
    const [usrpwd, setusrpwd] = useState('');
    const [usrdiv, setusrdiv] = useState('');
    const [gross_sal, setgross_sal] = useState(0);

    const [salaryCycle, setSalaryCycle] = useState(null);
    const [weeklyOff, setWeeklyOff] = useState('');
    const [holidayTemplate, setHolidayTemplate] = useState('');
    const [leaveTemplate, setLeaveTemplate] = useState('');
    const [shift, setShift] = useState('');
    const [salaryAccess, setSalaryAccess] = useState('');
    const [attendanceMode, setAttendanceMode] = useState('');
    const [OTAccess, setOTAccess] = useState('');
    const [SalDays, setSalDays] = useState(null);
    const [salary_mode, setsalary_mode] = useState(null);

    const [email, setEmail] = useState('');
    const [gender, setGender] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState(null);
    const [martialStatus, setMartialStatus] = useState('');
    const [bloodGroup, setBloodGroup] = useState('');
    const [emergencyContact, setEmergencyContact] = useState('');
    const [fatherName, setFatherName] = useState('');
    const [motherName, setMotherName] = useState('');
    const [spouseName, setSpouseName] = useState('');
    const [physicallyChallenged, setPhysicallyChallenged] = useState('');
    const [addressLine1, setAddressLine1] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [addressLine3, setAddressLine3] = useState('');
    const [placeOfBirth, setplaceOfBirth] = useState('');
    const [Citizenship, setCitizenship] = useState('');
    const [Height, setHeight] = useState('');
    const [Weight, setWeight] = useState('');
    const [MobilePolicy, setMobilePolicy] = useState('');
    const [Tookloanamount, setTookloanamount] = useState('');
    const [Tookemi, setTookemi] = useState('');
    const [Tookbankname, setTookbankname] = useState('');

    const [languageList, setlanguageList] = useState([]);
    const [dropdownValues, setDropdownValues] = useState([{ language: "", read: "", write: "", speak: "", understand: '' }]);
    const [EducationdropdownValues, setEducationDropdownValues] = useState([{ Examination: "", InstituteName: "", Year: "", Percentage: "", Subject: '' }]);
    const [WorkdropdownValues, setWorkDropdownValues] = useState([{ WorkingPeriod: "", Organization: "", Designation: "", Duties: "", LeavingReason: '' }]);
    const [FamilydropdownValues, setFamilyDropdownValues] = useState([{ Name: "", Relation: "", Contact: "" }]);

    const [NumberKids, setNumberKids] = useState('');
    const [NumberSons, setNumberSons] = useState('');
    const [NumberDaughter, setNumberDaughter] = useState('');

    const [RentedOwen, setRentedOwen] = useState('');
    const [OldEmpRelation, setOldEmpRelation] = useState('');
    const [EyeGlasses, setEyeGlasses] = useState('');
    const [SeenFireSysVid, setSeenFireSysVid] = useState('');
    const [Kharchi, setKharchi] = useState('');
    const [BankLoanEMI, setBankLoanEMI] = useState('');
    const [NonVeg, setNonVeg] = useState('');
    const [AttachFamilyPF, setAttachFamilyPF] = useState('');
    const [PersonCTC, setPersonCTC] = useState('');
    const [Occupation, setOccupation] = useState('');
    const [WorkingHours, setWorkingHours] = useState('');
    const [PoliceRecord, setPoliceRecord] = useState('');
    const [OTPayout, setOTPayout] = useState('');
    const [bankAccountByCOM, setbankAccountByCOM] = useState('');
    const [Interview, setInterview] = useState('');
    const [SalaryFinal, setSalaryFinal] = useState('');
    const [Aayushman, setAayushman] = useState('');
    const [MedicalCliam, setMedicalCliam] = useState('');

    const [dateOfJoining, setDateOfJoining] = useState('');
    const [esicNumber, setEsicNumber] = useState('');
    const [dateOfResign, setDateOfResign] = useState('');
    const [uan, setUan] = useState('');
    const [panNumber, setPanNumber] = useState('');
    const [aadhaarNumber, setAadhaarNumber] = useState('');
    const [aadhaarEnrollmentNumber, setAadhaarEnrollmentNumber] = useState('');
    const [pfNumber, setPfNumber] = useState('');
    const [pfJoiningDate, setPfJoiningDate] = useState('');
    const [pfEligible, setPfEligible] = useState('');
    const [esiEligible, setEsiEligible] = useState('');
    const [esiNumber, setEsiNumber] = useState('');
    const [ptEligible, setPtEligible] = useState('');
    const [lwfEligible, setLwfEligible] = useState('');
    const [epsEligible, setEpsEligible] = useState('');
    const [epsJoiningDate, setEpsJoiningDate] = useState('');
    const [epsExitDate, setEpsExitDate] = useState('');
    const [hpsEligible, setHpsEligible] = useState('');

    const [bankName, setBankName] = useState('');
    const [bankBranch, setbankBranch] = useState('');
    const [ifscCode, setIfscCode] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [accountHolderName, setAccountHolderName] = useState('');


    const LanguageTypeGET = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');

            const data = {
                user: userSchema?.usrnm,
                compid: JSON.parse(companyIdString),
                divid: JSON.parse(divIdString),
                masterid: userSchema?.masterid
            }
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/employeegrade_master/LanguageTypeGET`, data,
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );
            setlanguageList(response.data.lastEntryNo.map(item => item.Language));
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        LanguageTypeGET();
    }, [])

    const handleLanguageChange = (index, field, value) => {
        const newDropdownValues = [...dropdownValues];
        newDropdownValues[index][field] = value;
        setDropdownValues(newDropdownValues);
    };

    // Function to add one more Language
    const handleLanguageAddMore = () => {
        setDropdownValues([...dropdownValues, { language: "", read: "", write: "", speak: "", understand: '' }]);
    };

    // Function to delete a Language item
    const handleLanguageDelete = (index) => {
        const newDropdownValues = [...dropdownValues];
        newDropdownValues.splice(index, 1);
        setDropdownValues(newDropdownValues);
    };

    const handleEducationChange = (index, field, value) => {
        const newDropdownValues = [...EducationdropdownValues];
        newDropdownValues[index][field] = value;
        setEducationDropdownValues(newDropdownValues);
    };

    // Function to add one more Education
    const handleEducationAddMore = () => {
        setEducationDropdownValues([...EducationdropdownValues, { Examination: "", InstituteName: "", Year: "", Percentage: "", Subject: '' }]);
    };

    // Function to delete a Education item
    const handleEducationDelete = (index) => {
        const newDropdownValues = [...EducationdropdownValues];
        newDropdownValues.splice(index, 1);
        setEducationDropdownValues(newDropdownValues);
    };

    const handleWorkChange = (index, field, value) => {
        const newDropdownValues = [...WorkdropdownValues];
        newDropdownValues[index][field] = value;
        setWorkDropdownValues(newDropdownValues);
    };

    // Function to add one more Work
    const handleWorkAddMore = () => {
        setWorkDropdownValues([...WorkdropdownValues, { WorkingPeriod: "", Organization: "", Designation: "", Duties: "", LeavingReason: '' }]);
    };

    // Function to delete a Work item
    const handleWorkDelete = (index) => {
        const newDropdownValues = [...WorkdropdownValues];
        newDropdownValues.splice(index, 1);
        setWorkDropdownValues(newDropdownValues);
    };

    const handleFamilyChange = (index, field, value) => {
        const newDropdownValues = [...FamilydropdownValues];
        newDropdownValues[index][field] = value;
        setFamilyDropdownValues(newDropdownValues);
    };

    // Function to add one more Family
    const handleFamilyAddMore = () => {
        setFamilyDropdownValues([...FamilydropdownValues, { Name: "", Relation: "", Contact: "" }]);
    };

    // Function to delete a Family item
    const handleFamilyDelete = (index) => {
        const newDropdownValues = [...FamilydropdownValues];
        newDropdownValues.splice(index, 1);
        setFamilyDropdownValues(newDropdownValues);
    };

    const handleOpenProfileModal = () => {
        setOpenProfileModal(true);
    };

    const handleOpenGeneralModal = () => {
        setOpenGeneralModal(true);
    };

    const handleOpenPersonalModal = () => {
        setOpenPersonalModal(true);
    };

    const handleOpenEmploymentModal = () => {
        setOpenEmploymentModal(true);
    };

    const handleOpenBankModal = () => {
        setOpenBankModal(true);
    };

    const handleOpenUPIModal = () => {
        setOpenUPIModal(true);
    };

    const handleOpenOtherModal = () => {
        setOpenOtherModal(true);
    };

    const convertToIndianTime = (utcTimestamp) => {
        const utcDate = dayjs.utc(utcTimestamp);
        if (utcDate.isValid()) {
            return utcDate.tz('Asia/Kolkata').format('DD-MM-YYYY');
        } else {
            return 'N/A';
        }
    };


    const handleCloseModal = () => {
        fetchData();
        setOpenProfileModal(false);
        setOpenGeneralModal(false);
        setOpenPersonalModal(false);
        setOpenEmploymentModal(false);
        setOpenBankModal(false);
        setOpenUPIModal(false);
        setOpenOtherModal(false);
    };

    const handleSaveModal = async (modalIdentifier) => {
        let formData = {};
        try {
            if (modalIdentifier === "Language") {
                formData = {
                    language: dropdownValues,
                };
            }

            if (modalIdentifier === "Academic") {
                formData = {
                    Academic: EducationdropdownValues,
                };
            }

            if (modalIdentifier === "Family") {
                formData = {
                    Family: FamilydropdownValues,
                };
            }

            if (modalIdentifier === "WorkEx") {
                formData = {
                    WorkEx: WorkdropdownValues,
                };
            }

            if (modalIdentifier === "Other") {
                formData = {
                    RentedOwen: RentedOwen,
                    OldEmpRelation: OldEmpRelation,
                    EyeGlasses: EyeGlasses,
                    SeenFireSysVid: SeenFireSysVid,
                    Kharchi: Kharchi,
                    BankLoanEMI: BankLoanEMI,
                    NonVeg,
                    AttachFamilyPF: AttachFamilyPF,
                    PersonCTC: PersonCTC,
                    Occupation,
                    WorkingHours,
                    PoliceRecord,
                    OTPayout,
                    bankAccountByCOM,
                    MobilePolicy,
                    Interview,
                    SalaryFinal,
                    Tookloanamount,
                    Tookemi,
                    Tookbankname,
                    Aayushman,
                    MedicalCliam
                };
            }

            if (modalIdentifier === "ProfileInfo") {
                formData = {
                    full_name: name,
                    machine_code: machineCode,
                    department: department?._id,
                    mobile_no: contactNumber,
                    designation: designation?._id,
                    category: staffType?._id,
                    machine: machineName?._id,
                    usrpwd,
                    div_code: usrdiv?._id,
                    reporting_manager: reportingManager
                };
            }

            if (modalIdentifier === "GenInfo") {
                formData = {
                    weekly_leave: weeklyOff,
                    default_shift: shift,
                    salaryAccess: salaryAccess,
                    attendanceMode: attendanceMode,
                    ol_appl: OTAccess,
                    days: SalDays,
                    salary_mode
                };
            }

            if (modalIdentifier === "PersonalInfo") {
                if (Weight && (Weight < 40.1 || Weight > 180)) {
                    setActionMessage('Weight should be above 40 Kg and max 180 Kg ')
                    setErrorModalOpen(true)
                    return;
                }
                if (dateOfBirth) {
                    const selectedDate = dayjs(dateOfBirth).tz('Asia/Kolkata');
                    const eighteenYearsAgo = dayjs().subtract(18, 'year');
                    if (selectedDate.isAfter(eighteenYearsAgo)) {
                        setActionMessage('Date of birth should be above 18 years');
                        setErrorModalOpen(true);
                        return;
                    }
                }
                if (email) {
                    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                    if (!emailRegex.test(email)) {
                        setActionMessage('Invalid email address');
                        setErrorModalOpen(true);
                        return;
                    }
                }

                if (Number(NumberKids || 0) !== (Number(NumberSons || 0) + Number(NumberDaughter || 0))) {
                    setActionMessage('Number of Kids should be equal to the number of Sons and Daughters');
                    setErrorModalOpen(true);
                    return;
                }

                formData = {
                    email: email,
                    gender_radio: gender,
                    marital_status: martialStatus,
                    NumberKids: NumberKids,
                    NumberSons: NumberSons,
                    NumberDaughter: NumberDaughter,
                    bloodGroup: bloodGroup,
                    emergencyContact: emergencyContact,
                    mother_name: motherName,
                    father_name: fatherName,
                    spouseName: spouseName,
                    physicallyChallenged: physicallyChallenged,
                    address: addressLine1,
                    addressLine2: addressLine2,
                    addressLine3: addressLine3,
                    birth_date: dateOfBirth,
                    birth_datemilisecond: Date.parse(dateOfBirth),
                    placeOfBirth,
                    Citizenship,
                    Height,
                    Weight
                };
            }
            if (modalIdentifier === "EmployementInfo") {
                formData = {
                    joining: dateOfJoining,
                    EsicNumber: esicNumber,
                    joining_datemilisecond: Date.parse(dateOfJoining),
                    resign: dateOfResign,
                    resign_datemilisecond: Date.parse(dateOfResign),
                    uan_no: uan,
                    pan: panNumber,
                    adhar_no: aadhaarNumber,
                    adhar_enroll_no: aadhaarEnrollmentNumber,
                    pfNumber: pfNumber,
                    pf_application: pfEligible,
                    esi_applicable: esiEligible,
                    EsiNumber: esiNumber,
                    pt_application: ptEligible,
                    lwf_application: lwfEligible,
                    eps_application: epsEligible,
                    hps_application: hpsEligible,
                    PfJoiningDate: pfJoiningDate,
                    PfJoining_datemilisecond: Date.parse(pfJoiningDate),
                    EpsJoiningDate: epsJoiningDate,
                    EpsJoining_datemilisecond: Date.parse(epsJoiningDate),
                    EpsExitDate: epsExitDate,
                    EpsExit_datemilisecond: Date.parse(epsExitDate)
                };
            }

            if (modalIdentifier === "BankInfo") {
                formData = {
                    bank: bankName?._id,
                    bankBranch,
                    ifsc_code: ifscCode,
                    bank_ac_no: accountNumber,
                    bank_acc_holder: accountHolderName || name
                };
            }

            if (modalIdentifier === "UPIInfo") {
                formData = {
                    upiId: upiID
                };
            }
            const data = {
                _id: id,
                formData
            }
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/employee/EmployeeUpdate`, data, {
                headers: {
                    authorization: `Bearer ${token}`
                }
            }).then(response => {
                console.log(response.data)

                if (response.data.status) {
                    fetchData()
                    // setActionMessage(response.data.message)
                    handleCloseModal();
                    // setSuccessModalOpen(true);
                    // handleCancel();

                } else {
                    fetchData()
                    // setActionMessage(response.data.message)
                    handleCloseModal()
                    // // handleCancel();
                    // setErrorModalOpen(true)
                }
            })
                .catch(error => {
                    fetchData()
                    console.log(error)
                    // setActionMessage(response.data.message)
                    handleCloseModal()
                    // setErrorModalOpen(true)
                });
        } catch (error) {
            console.log(error)
        }
    };

    const fetchData = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const dataToSend = {
                usrnm: userSchema?.usrnm,
                compid: JSON.parse(companyIdString),
                divid: JSON.parse(divIdString),
                masterid: userSchema?.masterid,
                // id: state.id ? state.id : null,
                id: localStorage.getItem('StaffDetailEmpID')
            };
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage

            const response = await axios.post(`${process.env.REACT_APP_API_URL}/attendance/GetattendanceDetailsold`, dataToSend, {
                headers: {
                    authorization: `Bearer ${token}`
                }
            });
            setStaffData(response.data.data[0])
            const data = response.data.data[0];
            setusrdiv(response.data.divDetails);
            setName(data.full_name || '');
            setId(data._id || '');
            setDepartment(data?.department || '');
            setDesignation(data.designation || '');
            setStaffType(data.category || '');
            setgross_sal(data.gross_sal || 0);
            setContactNumber(data.mobile_no || '');
            setusrpwd(data.usrpwd);
            setReportingManager(data.reporting_manager || '');
            setmachineCode(data.machine_code)
            setmachineName(data.machine)
            setSalaryCycle(data.salary_cycle || '');
            setWeeklyOff(data.weekly_leave || '');
            setHolidayTemplate(data.holidayTemplate || '');
            setLeaveTemplate(data.leaveTemplate || '');
            setShift(data?.default_shift || '');
            setSalaryAccess(data.salaryAccess || '');
            setAttendanceMode(data.attendanceMode || '');
            setOTAccess(data.ol_appl || '');
            setSalDays(data?.days || '');
            setsalary_mode(data?.salary_mode || "N/A")
            setAayushman(data?.Aayushman || "")
            setMedicalCliam(data?.MedicalCliam || "")

            setDropdownValues(data?.language?.length > 0 ? data?.language : [{ language: "", read: "", write: "", speak: "", understand: '' }])

            setEducationDropdownValues(data?.Academic?.length > 0 ? data?.Academic : [{ Examination: "", InstituteName: "", Year: "", Percentage: "", Subject: '' }])

            setWorkDropdownValues(data?.WorkEx?.length > 0 ? data?.WorkEx : [{ WorkingPeriod: "", Organization: "", Designation: "", Duties: "", LeavingReason: '' }])

            setFamilyDropdownValues(data?.Family?.length > 0 ? data?.Family : [{ Name: "", Relation: "", Contact: "" }])

            setEmail(data.email || '');
            setGender(data.gender_radio || '');
            setDateOfBirth(data.birth_date || null);
            setMartialStatus(data.marital_status || '');
            setBloodGroup(data.bloodGroup || '');
            setEmergencyContact(data.emergencyContact || '');
            setFatherName(data.father_name || '');
            setMotherName(data.mother_name || '');
            setSpouseName(data.spouseName || '');
            setPhysicallyChallenged(data.physicallyChallenged || '');
            setAddressLine1(data.address || '');
            setAddressLine2(data.addressLine2 || '');
            setAddressLine3(data.addressLine3 || '');
            setplaceOfBirth(data?.placeOfBirth || '');
            setCitizenship(data?.Citizenship || '');
            setHeight(data?.Height || '');
            setWeight(data?.Weight || '');

            setNumberKids(data.NumberKids || '');
            setNumberSons(data.NumberSons || '');
            setNumberDaughter(data.NumberDaughter || '');
            setRentedOwen(data.RentedOwen || '');
            setOldEmpRelation(data.OldEmpRelation || null);
            setEyeGlasses(data.EyeGlasses || '');
            setSeenFireSysVid(data.SeenFireSysVid || '');
            setKharchi(data.Kharchi || '');
            setBankLoanEMI(data.BankLoanEMI || '');
            setNonVeg(data.NonVeg || '');
            setAttachFamilyPF(data.AttachFamilyPF || '');
            setPersonCTC(data.PersonCTC || '');
            setOccupation(data.Occupation || '');
            setWorkingHours(data.WorkingHours || '');
            setPoliceRecord(data.PoliceRecord || '');
            setOTPayout(data?.OTPayout || '');
            setbankAccountByCOM(data?.bankAccountByCOM || '');
            setMobilePolicy(data?.MobilePolicy || '');
            setInterview(data?.Interview || '');
            setSalaryFinal(data?.SalaryFinal || '');
            setTookloanamount(data?.Tookloanamount || '');
            setTookemi(data?.Tookemi || '');
            setTookbankname(data?.Tookbankname || '');


            setDateOfJoining(data.joining || '');
            setEsicNumber(data.EsicNumber || '');
            setDateOfResign(data.resign || '');
            setUan(data.uan_no || '');
            setPanNumber(data.pan || '');
            setAadhaarNumber(data.adhar_no || '');
            setAadhaarEnrollmentNumber(data.adhar_enroll_no || '');
            setPfNumber(data.pfNumber || '');
            setPfJoiningDate(data.PfJoiningDate || '');
            setPfEligible(data.pf_application || '');
            setEsiEligible(data.esi_applicable || '');
            setEsiNumber(data.EsiNumber || '');
            setPtEligible(data.pt_application || '');
            setLwfEligible(data.lwf_application || '');
            setEpsEligible(data.eps_application || '');
            setEpsJoiningDate(data.EpsJoiningDate || '');
            setEpsExitDate(data.EpsExitDate || '');
            setHpsEligible(data.hps_application || '');

            setBankName(data.bank || '');
            setbankBranch(data?.bankBranch || '');
            setIfscCode(data.ifsc_code || '');
            setAccountNumber(data?.bank_ac_no || '');
            setAccountHolderName(data.bank_acc_holder || '');

            setUpiID(data.upiId || '')
            // localStorage.removeItem('StaffDetailEmpName');
            // localStorage.removeItem('StaffDetailEmpMid');
            // localStorage.removeItem('StaffDetailEmpID');
            // localStorage.removeItem('StaffDetailEmpStatus');

            // localStorage.setItem('StaffDetailEmpName', response.data.data[0].full_name);
            // localStorage.setItem('StaffDetailEmpMid', response.data.data[0].machine_code);
            // localStorage.setItem('StaffDetailEmpID', response.data.data[0]._id);
            // localStorage.setItem('StaffDetailEmpStatus', 'Android');
            console.log(response.data.data[0])
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const getFileType = (fileName) => {
        const extension = fileName.split('.').pop().toLowerCase();
        if (extension === 'pdf') return 'pdf';
        if (['xls', 'xlsx', 'csv'].includes(extension)) return 'excel';
        return 'image';
    };

    const EmployeeDocumentsGet = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const dataToSend = {
                usrnm: userSchema?.usrnm,
                compid: JSON.parse(companyIdString),
                divid: JSON.parse(divIdString),
                masterid: userSchema?.masterid,
                _id: localStorage.getItem('StaffDetailEmpID')
            };
            const token = localStorage.getItem('user-token');
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/employee/EmployeeDocumentsGet`,
                dataToSend,
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );
            let data = {};
            let remark = {};

            for (const key in response?.data?.documents) {
                if (Object.hasOwnProperty.call(response?.data?.documents, key)) {
                    const { files, remark: fileRemark } = response?.data?.documents[key];
                    data[key] = {
                        files: files.map(file => {
                            const fileExtension = getFileType(file.fileName)

                            const isBlob = file.file.startsWith('blob:');
                            return {
                                file: fileExtension !== "image" ? file.file : `${process.env.REACT_APP_API_URL}/uploads/${file.fileName}`,
                                fileName: file.fileName,
                                fileE: file.fileE
                            };
                        })
                    };
                    if (fileRemark) {
                        remark[key] = fileRemark;
                    }
                }
            }
            const allFiles = [];

            Object.values(data)?.forEach(item => {
                item.files.forEach(fileObj => {
                    allFiles.push(fileObj?.file);
                });
            });
            console.log("^^^^^^^^", allFiles);

            setFileStates(allFiles || []);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    useEffect(() => {
        console.log("**************", fileStates)

    }, [fileStates])
    useEffect(() => {
        EmployeeDocumentsGet();
    }, [])

    const DepTypeGET = async () => {
        const userSchemaString = localStorage.getItem('userSchema');
        const userSchema = JSON.parse(userSchemaString);
        const companyIdString = localStorage.getItem('companyId');
        const divIdString = localStorage.getItem('divId');

        const data = {
            user: userSchema?.usrnm,
            compid: JSON.parse(companyIdString),
            divid: JSON.parse(divIdString),
            masterid: userSchema?.masterid
        };

        try {
            const token = localStorage.getItem('user-token');
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/employee/DepartmentTypeGET`,
                data,
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );

            setDepValues(response.data.lastEntryNo);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const designationTypeGET = async () => {
        const userSchemaString = localStorage.getItem('userSchema');
        const userSchema = JSON.parse(userSchemaString);
        const companyIdString = localStorage.getItem('companyId');
        const divIdString = localStorage.getItem('divId');

        const data = {
            user: userSchema?.usrnm,
            compid: JSON.parse(companyIdString),
            divid: JSON.parse(divIdString),
            masterid: userSchema?.masterid
        };

        try {
            const token = localStorage.getItem('user-token');
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/employee/designationTypeGET`,
                data,
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );

            setDesigValues(response.data.lastEntryNo);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const CatTypeGET = async () => {
        const userSchemaString = localStorage.getItem('userSchema');
        const userSchema = JSON.parse(userSchemaString);
        const companyIdString = localStorage.getItem('companyId');
        const divIdString = localStorage.getItem('divId');

        const data = {
            user: userSchema?.usrnm,
            compid: JSON.parse(companyIdString),
            divid: JSON.parse(divIdString),
            masterid: userSchema?.masterid
        };

        try {
            const token = localStorage.getItem('user-token');
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/employee/CatTypeGET`,
                data,
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );

            setCatValues(response.data.lastEntryNo);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const shiftGET = async () => {
        const userSchemaString = localStorage.getItem('userSchema');
        const userSchema = JSON.parse(userSchemaString);
        const companyIdString = localStorage.getItem('companyId');
        const divIdString = localStorage.getItem('divId');

        const data = {
            user: userSchema?.usrnm,
            compid: JSON.parse(companyIdString),
            divid: JSON.parse(divIdString),
            masterid: userSchema?.masterid
        };

        try {
            const token = localStorage.getItem('user-token');
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/employee/shiftGET`,
                data,
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );

            setShiftValues(response.data.lastEntryNo);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const BankGET = async () => {
        const userSchemaString = localStorage.getItem('userSchema');
        const userSchema = JSON.parse(userSchemaString);
        const companyIdString = localStorage.getItem('companyId');
        const divIdString = localStorage.getItem('divId');

        const data = {
            user: userSchema?.usrnm,
            compid: JSON.parse(companyIdString),
            divid: JSON.parse(divIdString),
            masterid: userSchema?.masterid
        };

        try {
            const token = localStorage.getItem('user-token');
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/employee/BankGET`,
                data,
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );

            setBankValues(response.data.lastEntryNo);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const machine_mastGET = async () => {
        const userSchemaString = localStorage.getItem('userSchema');
        const userSchema = JSON.parse(userSchemaString);
        const companyIdString = localStorage.getItem('companyId');
        const divIdString = localStorage.getItem('divId');

        const data = {
            user: userSchema?.usrnm,
            compid: JSON.parse(companyIdString),
            divid: JSON.parse(divIdString),
            masterid: userSchema?.masterid
        };

        try {
            const token = localStorage.getItem('user-token');
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/employee/machine_mastGET`,
                data,
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );

            setMachineNameValues(response.data.lastEntryNo);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const Division_masterGET = async () => {
        const userSchemaString = localStorage.getItem('userSchema');
        const userSchema = JSON.parse(userSchemaString);
        const companyIdString = localStorage.getItem('companyId');
        const divIdString = localStorage.getItem('divId');

        const data = {
            masterid: userSchema?.masterid
        };

        try {
            const token = localStorage.getItem('user-token');
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/user_rights/Division_masterGET`,
                {
                    params: {
                        masterid: userSchema?.masterid,
                        compid: JSON.parse(companyIdString),
                        divid: JSON.parse(divIdString),
                    },
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );

            setDivValues(response.data.lastEntryNo);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        const fetchDataAsync = async () => {
            await fetchData();
            await DepTypeGET();
            await designationTypeGET();
            await CatTypeGET();
            await shiftGET();
            await BankGET();
            await machine_mastGET();
            await Division_masterGET();
        };
        fetchDataAsync();
    }, []);

    const languageRows = dropdownValues.map(lang => `
    <tr>
        <td>${lang.language}</td>
        <td>${lang.read}</td>
        <td>${lang.write}</td>
        <td>${lang.speak}</td>
        <td>${lang.understand}</td>
    </tr>
`).join('');

    const EducationRows = EducationdropdownValues.map(lang => `
    <tr>
        <td>${lang.Examination}</td>
        <td>${lang.InstituteName}</td>
        <td>${lang.Year}</td>
        <td>${lang.Percentage}</td>
        <td>${lang.Subject}</td>
    </tr>
`).join('');

    const WorkRows = WorkdropdownValues.map(lang => `
    <tr>
        <td>${lang.WorkingPeriod}</td>
        <td>${lang.Organization}</td>
        <td>${lang.Designation}</td>
        <td>${lang.Duties}</td>
        <td>${lang.LeavingReason}</td>
    </tr>
`).join('');

    const FamilyRows = FamilydropdownValues.map(lang => `
    <tr>
        <td>${lang.Name}</td>
        <td>${lang.Relation}</td>
        <td>${lang.Contact}</td>

    </tr>
`).join('');

    const PrintPictuers = fileStates?.map(pic =>
        `<img src="${pic}" alt="" />`
    ).join('');

    const LoanRows = BankLoanEMI === 'Y' ?
        `
    <div class="field-group">
    <label for="bankLoanEmi">Any Bank Loan - Per Month EMI (कोई भी बैंक लोन हो तो प्रति माह ईएमआई राशि):</label>
    <p>${BankLoanEMI === 'Y' ? 'Yes' : BankLoanEMI === 'N' ? 'No' : 'N/A'}</p>
    <label for="bankLoanEmi">Bank Amount (लोन राशि):</label>
    <p>${Tookloanamount || 'N/A'}</p>
    </div>
    
    <div class="field-group">
    <label for="bankLoanEmi">EMI Amount (प्रति माह ईएमआई राशि):</label>
    <p>${Tookemi || 'N/A'}</p>
    <label for="bankLoanEmi">Bank Name (बैंक नाम):</label>
    <p>${Tookbankname || 'N/A'}</p>
    </div>
    `
        :
        `
   <div class="field-group">
   <label for="bankLoanEmi">Any Bank Loan - Per Month EMI (कोई भी बैंक लोन हो तो प्रति माह ईएमआई राशि):</label>
   <p>${BankLoanEMI || 'N/A'}</p>
   <label for="bankLoanEmi"></label>
   <p></p>
   </div>
    `;

    const HeightWeight =
        Height && Weight ? `
      <p>
        Height: ${Height} ft / Weight: ${Weight} kg
      </p>
    ` : Height && !Weight ? `
      <p>
        Height: ${Height} ft / Weight: N/A
      </p>
    ` : !Height && Weight ? `
      <p>
        Height: N/A / Weight: ${Weight} kg
      </p>
    ` : `
      <p>
        Height: N/A / Weight: N/A
      </p>
    `;

    const formatjsDate = (date) => {
        let day = String(date.getDate()).padStart(2, '0');
        let month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        let year = date.getFullYear();

        return `${day}-${month}-${year}`;
    };

    const handlePrintRow = (params) => {
        const userSchemaString = localStorage.getItem('userSchema');
        const userSchema = JSON.parse(userSchemaString);

        // Example: Print row data in a new window
        const printWindow = window.open('', '_blank');
        printWindow.document.write(`
        <!DOCTYPE html>
        <html lang="en">
        
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Joining Form</title>
            <style>
        body {
            font-family: Arial, sans-serif;
            background-color: #f4f4f4;
            margin: 0;
            padding: 0;
        }

        .container {
            max-width: 1000px;
            margin: 20px auto;
            padding: 40px;
            background-color: #fff;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
        }
        .headercontainer {
            display: flex;
            flex-direction: column; /* Stack header and form container vertically */
            align-items: center; /* Center align items */
            padding: 20px;
        }
        
        .header {
            text-align: center;
            margin-bottom: 10px;
            padding-bottom: 10px;
            position: relative;
        }
        
        .header img {
            position: absolute;
            top: 0px;
            left: 10px;
            height: 100px;
            width: 100px;
        }
        
        .header h1 {
            margin: 0;
            font-size: 24px;
        }
        
        .header p {
            margin: 5px 0 0;
            font-size: 14px;
        }
        
        /* Add HR text styling */
        .hr-text {
            position: absolute;
            top: 0; /* Place it at the top */
            right: 10px; /* Align it with a small margin from the right */
            font-size: 16px;
            font-weight: bold;
            color: #333; /* Customize the color */
        }
        

        .form-title {
            text-align: center;
            margin-top: 15px;
        }

        .form-title h2 {
            margin: 5px 0;
            font-size: 18px;
        }
        
        .form-section {
            margin-top: 20px;
        }

        .form-section h2 {
            background-color: #8dc63f;
            color: white;
            padding: 10px;
            margin: 0 0 5px;
            font-size: 18px;
            border-radius: 4px;
        }

        .familyPhotoForPf-group {
            display: flex;
            flex-direction: column; /* Arrange label and photo box vertically */
            align-items: flex-start; /* Align items to the left */
            margin-bottom: 15px;
          }
          
          .familyPhotoForPf-group label {
            margin-top: 15px; /* Adds some space between label and photo box */
            margin-bottom: 4px; /* Adds some space between label and photo box */
          }
          
          .photo-box {
            width: 1000px; /* Width of the photo box */
            height: 80px; /* Height of the photo box */
            border: 2px dashed #ccc; /* Dashed border to indicate it's a placeholder */
            border-radius: 4px; /* Optional: Rounds the corners */
            background-color: #f9f9f9; /* Background color for the box */
            display: flex; /* Centers content inside the box */
            justify-content: center;
            align-items: center;
            gap: 10px;
            padding: 10px;
          }
          
          .photo-box img {
            width: 80px; /* Adjust image size */
            height: 80px;
            border: 2px dashed #ccc;
            border-radius: 4px;
            background-color: #f9f9f9;
            object-fit: cover;
          }
          .photo-box:hover {
            background-color: #eaeaea; /* Changes background color on hover */
          }
          
        .field-group {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-bottom: 0px;
            border-bottom: 1px solid #ccc; 
            border-top: 1px solid #ccc; 
            border-left: 1px solid #ccc;
            border-right: 1px solid #ccc;
            padding-left: 10px;
        }
        
        .field-group label {
            flex: 1;
            min-width: 200px;
            max-width: 300px;
            font-size: 14px;
            margin-right: 10px;
        }

        .field-group input,
        .field-group select,
        .field-group p {
            flex: 1;
            min-width: 100px;
            max-width: 200px;
            padding: 1px;
            margin: px;
            border: 1px solid #ccc;
            font-size: 14px;
            box-sizing: border-box;
            font-weight: bold;
        }

        .field-group p {
            border: none;
            padding: 1px 0;
        }

        .field-group2 {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-bottom: 0px;
            border-bottom: 1px solid #ccc; 
            border-top: 1px solid #ccc; 
            border-left: 1px solid #ccc;
            border-right: 1px solid #ccc;
            padding-left: 10px;
        }
        
        .field-group2 label {
            flex: 1;
            min-width: 200px;
            max-width: 200px;
            font-size: 14px;
            margin-right: 10px;
        }

        .field-group2 input,
        .field-group2 select,
        .field-group2 p {
            flex: 1;
            min-width: 100px;
            max-width: 700px;
            padding: 1px;
            margin: px;
            border: 1px solid #ccc;
            font-size: 14px;
            box-sizing: border-box;
            font-weight: bold;
        }

        .field-group2 p {
            border: none;
            padding: 1px 0;
        }

        table {
            width: 100%;
            border-collapse: collapse;
            font-size: 12px;
            margin-bottom: 20px;
        }

        th, td {
            border: 1px solid #ccc;
            padding: 10px;
            text-align: left;
        }

        th {
            background-color: #dee3d8;
            color: black;
        }

        td select {
            width: 100%;
            padding: 5px;
            font-size: 12px;
        }

        @media (max-width: 768px) {
            
            .field-group {
                flex-direction: column;
                align-items: flex-start;
            }

            .field-group label,
            .field-group input,
            .field-group select,
            .field-group p,
            .field-group textarea {
                width: 100%;
                max-width: none;
            }
        }
        @media print {
            .page-break {
                page-break-before: always;
            }
        }
    </style>
        </head>
        
        <body>
            <div class="container">
                <div class="header">
                <h1>${usrdiv?.div_code || ''}</h1>
                <p class="hr-text">Print By ${userSchema?.usrnm || ''}</p>
                </div>
        
                <div class="form-title">
                    <h2>JOINING FORM / ONBOARD FORM</h2>
                    <h2>ज्वाइनिंग फॉर्म / ऑनबोर्ड फॉर्म</h2>
                </div>
        
                

                <div class="form-section">
                    <h2>1. Personal Information</h2>
                    <div class="field-group">
                        <label for="fullName">Employee Code :</label>
                        <p> ${machineCode || "N/A"} </p>
                        <div style="border-left: 1px solid #ccc; height: 47px; margin: 0 10px;"></div>
                    <label for="fullName">Full Name (पूरा नाम) :</label>
                        <p> ${name || "N/A"} </p>
                    </div>
                    <div class="field-group">
                        <label for="dob">Date of Birth (जन्म तारीख):</label>
                        <p> ${convertToIndianTime(dateOfBirth) || "N/A"} </p>
                        <div style="border-left: 1px solid #ccc; height: 47px; margin: 0 10px;"></div>
                        <label for="placeOfBirth">Place of Birth (जन्म स्थान):</label>
                        <p>${placeOfBirth || "N/A"}</p>
                    </div>
                    <div class="field-group2">
                        <label for="permanentAddress">Permanent Address (स्थाई पता):</label>
                        <p>${addressLine1 || 'N/A'}</p>
                       
                    </div>
                    <div class="field-group2">
                        <label for="presentAddress">Present Address (वर्तमान पता):</label>
                        <p>${addressLine2 || 'N/A'}</p>
                    </div>
                    <div class="field-group">
                        <label for="bankAccountNo">Bank Account No (बैंक खाता क्रमांक):</label>
                        <p> ${accountNumber || "N/A"} </p>
                        <div style="border-left: 1px solid #ccc; height: 47px; margin: 0 10px;"></div>
                        <label for="ifscCode">IFSC Code (आईएफएससी कोड):</label>
                        <p> ${ifscCode || "N/A"} </p>
                    </div>
                    <div class="field-group">
                        <label for="bankName">Bank Name (बैंक नाम):</label>
                       <p> ${bankName?.discription || "N/A"}</p>
                       <div style="border-left: 1px solid #ccc; height: 47px; margin: 0 10px;"></div>
                        <label for="bankBranch">Bank Branch (बैंक शाखा):</label>
                        <p>${bankBranch || 'N/A'}</p>
                    </div>
                    <div class="field-group">
                        <label for="mobileNo">Mobile No. (मोबाइल नंबर):</label>
                        <p>  ${contactNumber || "N/A"} </p>
                        <div style="border-left: 1px solid #ccc; height: 47px; margin: 0 10px;"></div>
                        <label for="bloodGroup">Blood Group (ब्लड ग्रुप):</label>
                        <p> ${bloodGroup || "N/A"} </p>
                    </div>
                    <div class="field-group">
                        <label for="citizenship">Citizenship (नागरिकता):</label>
                        <p>${Citizenship || 'N/A'}</p>
                        <div style="border-left: 1px solid #ccc; height: 47px; margin: 0 10px;"></div>
                        <label for="heightWeight">Height/Weight (ऊंचाई/वजन):</label>
                        ${HeightWeight}
                    </div>
                    <div class="field-group">
                        <label for="maritalStatus">Marital Status (वैवाहिक स्थिति):</label>
                        <p> ${martialStatus || "N/A"} </p>
                        <div style="border-left: 1px solid #ccc; height: 47px; margin: 0 10px;"></div>
                        <label for="spouseName">Spouse Name (जीवनसाथी का नाम):</label>
                        <p> ${spouseName || "N/A"} </p>
                    </div>
                    <div class="field-group">
                        <label for="gender">Gender (लिंग): (M/F)</label>
                        <p> ${gender || "N/A"} </p>
                        <div style="border-left: 1px solid #ccc; height: 47px; margin: 0 10px;"></div>
                        <label for="emailId">EMAIL ID (ईमेल आईडी):</label>
                        <p> ${email || "N/A"} </p>
                    </div>
                    <div class="field-group">
                        <label for="aadharCardNo">AADHAR CARD NO.(आधार कार्ड नंबर):</label>
                        <p> ${aadhaarNumber || "N/A"} </p>
                        <div style="border-left: 1px solid #ccc; height: 47px; margin: 0 10px;"></div>
                        <label for="panNo">PAN No. (पैन नं):</label>
                        <p> ${panNumber || "N/A"} </p>
                    </div>
                    <div class="field-group">
                        <label for="uanNo">UAN No. (यूएएन नं.):</label>
                        <p>${uan || "N/A"}</p>
                        <div style="border-left: 1px solid #ccc; height: 47px; margin: 0 10px;"></div>
                        <label for="esicNo">ESIC No. (ईएसआईसी नं):</label>
                        <p>${esicNumber || "N/A"}</p>
                    </div>
                    <div class="field-group">
                        <label for="fatherName">Father Name (पिताजी का नाम):</label>
                        <p>${fatherName || "N/A"}</p>
                        <div style="border-left: 1px solid #ccc; height: 47px; margin: 0 10px;"></div>
                        <label for="motherName">Mother Name (माता का नाम):</label>
                        <p>${motherName || "N/A"}</p>
                    </div>
                    <div class="field-group">
                        <label for="noOfKids">No.of Kids (बच्चों की संख्या):</label>
                        <p>${NumberKids || "N/A"}</p>
                        <div style="border-left: 1px solid #ccc; height: 47px; margin: 0 10px;"></div>
                        <label for="emergencyMobNo">Emergency Mob No. (आपातकालीन संपर्क नं):</label>
                        <p>${emergencyContact || "N/A"}</p>
                    </div>
                    <div class="field-group">
                    <label for="esicNo">Medical Claim (मेडिक्लेम क्लेम):</label>
                    <p>${MedicalCliam === 'Y' ? 'Yes' : MedicalCliam === 'N' ? 'No' : 'N/A'}</p>
                        <div style="border-left: 1px solid #ccc; height: 47px; margin: 0 10px;"></div>
                        <label for="esicNo">Mobile Policy (मोबाइल नीति):</label>
                        <p>${MobilePolicy === 'Y' ? 'Yes' : MobilePolicy === 'N' ? 'No' : 'N/A'}</p>
                        </div>
                <div class="field-group">
                    <label for="esicNo">Ayushman Card (आयुष्मान कार्ड ):</label>
                        <p>${Aayushman === 'Y' ? 'Yes' : Aayushman === 'N' ? 'No' : 'N/A'}</p>
                        <div style="border-left: 1px solid #ccc; height: 47px; margin: 0 10px;"></div>
                        <label for="esicNo"></label>
                        <p></p>
                        
                </div>
                <div class="form-section">
                        <h2>LANGUAGE (भाषा)</h2>
                        <table>
                            <thead>
                                <tr>
                                    <th>Language (भाषा)</th>
                                    <th>Read (पढ़ना)</th>
                                    <th>Write (लिखना)</th>
                                    <th>Speak (बोलना)</th>
                                    <th>Understand (समझना)</th>
                                </tr>
                            </thead>
                            <tbody>
                            ${languageRows}
                        </tbody>
                        </table>
                    </div>
                    <div class="field-group">
                        <label for="professionalTax">Professional Tax (वृत्ति कर):</label>
                        <p>${ptEligible === 'Y' ? 'Yes' : ptEligible === 'N' ? 'No' : 'N/A'}</p>
                        <div style="border-left: 1px solid #ccc; height: 47px; margin: 0 10px;"></div>
                        <label for="providentFundNo">Provident Fund No. (भविष्य निधि):</label>
                        <p>${pfNumber || 'N/A'}</p>
                    </div>
                    <div class="field-group">
                        <label for="homeStatus">Rented / Owned Home (किराये का / स्वामित्व वाला घर):</label>
                        <p>${RentedOwen || 'N/A'}</p>
                        <div style="border-left: 1px solid #ccc; height: 47px; margin: 0 10px;"></div>
                        <label for="relationWithOldEmployee">Any Relation With Old Employee (पुराने कर्मचारी से कोई संबंध):</label>
                        <p>${OldEmpRelation === 'Y' ? 'Yes' : OldEmpRelation === 'N' ? 'No' : 'N/A'}</p>
                    </div>
                    <div class="field-group">
                        <label for="eyeGlasses">Eye Glasses (आँख के चश्मे का नंबर है या नहीं):</label>
                        <p>${EyeGlasses === 'Y' ? 'Yes' : EyeGlasses === 'N' ? 'No' : 'N/A'}</p>
                        <div style="border-left: 1px solid #ccc; height: 47px; margin: 0 10px;"></div>
                        <label for="bankAccountType">Bank Account Opened By Company Or Old Account (खाता कंपनी द्वारा खोला गया या स्वयं का पुराना खाता):</label>
                        <p>${bankAccountByCOM || 'N/A'}</p>
                    </div>
                    <div class="field-group">
                        <label for="fireSafetyVideo">Fire System Safety Video (अग्निशमन प्रणाली सुरक्षा वीडियो) seen:</label>
                        <p>${SeenFireSysVid === 'Y' ? 'Yes' : SeenFireSysVid === 'N' ? 'No' : 'N/A'}</p>
                        <div style="border-left: 1px solid #ccc; height: 47px; margin: 0 10px;"></div>
                        <label for="kharchi">They Want Kharchi Aur Not (उन्हें खर्ची चाहिए या नहीं):</label>
                        <p>${Kharchi === 'Y' ? 'Yes' : Kharchi === 'N' ? 'No' : 'N/A'}</p>
                    </div>
                    <div class="field-group">
                    <label for="disability">Any Disability (कोई अक्षमता):</label>
                    <p>${physicallyChallenged === 'Y' ? 'Yes' : physicallyChallenged === 'N' ? 'No' : 'N/A'}</p>
                    <div style="border-left: 1px solid #ccc; height: 47px; margin: 0 10px;"></div>
                        <label for="noNonVegAllowed">No Non-Veg Allowed In Food (भोजन में मांसाहार की अनुमति नहीं है):</label>
                        <p>${NonVeg === 'Y' ? 'Yes' : NonVeg === 'N' ? 'No' : 'N/A'}</p>
                    </div>
                    
                    ${LoanRows}
                </div>
                <div class="familyPhotoForPf-group">
                <label for="familyPhotoForPf">
                Attach Family Photo For PF (पीएफ के लिए पारिवारिक फोटो संलग्न करें):-
                </label>
                <div class="photo-box" id="familyPhotoForPf">
                ${PrintPictuers}
                </div>
                
                </div>

                <div class="form-section">
                    <h2>2. Academic Details (शैक्षणिक विवरण)</h2>
                    <table>
                        <thead>
                            <tr>
                                <th>Examination (परीक्षा)</th>
                                <th>Name of Institute & Board (संस्थान एवं बोर्ड का नाम)</th>
                                <th>Year of Appearing In Exam (परीक्षा में बैठने का साल)</th>
                                <th>Percentage (प्रतिशत)</th>
                                <th>Main Subject (मुख्य विषय)</th>
                            </tr>
                        </thead>
                        <tbody id="academicDetails">
                        ${EducationRows}
                        </tbody>
                    </table>
                </div>
        
                <div class="form-section">
                    <h2>3. Work Experience (कार्य अनुभव)</h2>
                    <table>
                        <thead>
                            <tr>
                                <th>Period of Working (कार्य करने की अवधि) (From (DD/MM/YY) - To (DD/MM/YY)</th>
                                <th>Organization Name & Address (संगठन का नाम और पता)</th>
                                <th>Designation (पद का नाम)</th>
                                <th>Nature Of Duties (कर्तव्यों का प्रकार)</th>
                                <th>Reason For Leaving (छोड़ने का कारण)</th>
                            </tr>
                        </thead>
                        <tbody id="workExperience">
                        ${WorkRows}
                        </tbody>
                    </table>
                </div>
        
                <div class="form-section">
                    <h2>4. Details of Family Members (परिवार के सदस्यों का विवरण)</h2>
                    <table>
                        <thead>
                            <tr>
                                <th>Name of Family Members (परिवार के सदस्यों के नाम)</th>
                                <th>Relation (रिश्ता)</th>
                                <th>Contact No. (संपर्क नंबर)</th>
                            </tr>
                        </thead>
                        <tbody id="familyMembers">
                        ${FamilyRows}
                        </tbody>
                    </table>
                </div>
        
                <div class="form-section">
                    <h2>5. DECLARATION (घोषणा)</h2>
                </div>
        
                <div class="form-section">
                    <h2>Only Office Use (केवल कार्यालय उपयोग)</h2>
                    <div class="field-group">
                        <label for="dateOfJoining">Date Of Joining (शामिल होने की तिथि):</label>
                        <p>${convertToIndianTime(dateOfJoining) || 'N/A'}</p>
                        <div style="border-left: 1px solid #ccc; height: 47px; margin: 0 10px;"></div>
                        <label for="departmentOffice">Department (विभाग):</label>
                        <p>${department?.discription || 'N/A'}</p>
                    </div>
                    <div class="field-group">
                        <label for="designationOffice">Designation (पद का नाम):</label>
                        <p>${designation?.discription || 'N/A'}</p>
                        <div style="border-left: 1px solid #ccc; height: 47px; margin: 0 10px;"></div>
                        <label for="salary">Salary Rs. Per Month (वेतन रु. प्रति महीने):</label>
                        <p>${gross_sal || 'N/A'}</p>
                    </div>
                    <div class="field-group">
                        <label for="ctc">Person In CTC: Rs. /- PA (Salary + Bonus)(वेतन में बोनस शामिल):</label>
                        <p>${PersonCTC || 'N/A'}</p>
                        <div style="border-left: 1px solid #ccc; height: 47px; margin: 0 10px;"></div>
                        <label for="occupation">Occupation (पेशा):</label>
                        <p>${Occupation || 'N/A'}</p>
                    </div>
                    <div class="field-group">
                        <label for="workingHours">Working Hours (कार्य के घंटे):</label>
                        <p>${WorkingHours || 'N/A'}</p>
                        <div style="border-left: 1px solid #ccc; height: 47px; margin: 0 10px;"></div>
                        <label for="policeRecord">Police Record (पोलीस रिकॉर्ड):</label>
                        <p>${PoliceRecord === 'Y' ? 'Yes' : PoliceRecord === 'N' ? 'No' : 'N/A'}</p>
                    </div>
                    <div class="field-group">
                        <label for="paymentMethod">Payment Method (भुगतान विधि):</label>
                        <p>${salary_mode || 'N/A'}</p>
                        <div style="border-left: 1px solid #ccc; height: 47px; margin: 0 10px;"></div>
                        <label for="overtimePayout">OverTime Payout (ओवरटाइम भुगतान):</label>
                        <p>${OTPayout === 'Y' ? 'Yes' : PoliceRecord === 'N' ? 'No' : 'N/A'}</p>
                    </div>
                    <div class="field-group">
                        <label for="managerSignature">Manager, HR & Admin (प्रबंधक, मानव संसाधन एवं व्यवस्थापक):</label>
                        <p></p>
                        <div style="border-left: 1px solid #ccc; height: 47px; margin: 0 10px;"></div>
                        <label for="vickySir">Vicky Sir (विक्की सर):</label>
                        <p></p>
       
                    </div>
                    <div class="field-group">
                    <label for="jitendraSir">Jitendra Sir (जितेन्द्र सर):</label>
                    <p></p>
                    </div>
                    <p>I Hereby Certify that all particulars as furnished by me in the Employment form are true, correct and completed.</p>
                    <div class="field-group">
                        <label for="applicantSignature">Applicant Signature (आवेदक का हस्ताक्षर):</label>
                        <p></p>
                        <div style="border-left: 1px solid #ccc; height: 47px; margin: 0 10px;"></div>
                        <label for="date">Date (तारीख):</label>
                        <p>${formatjsDate(new Date())}</p>
                    </div>
                    
                    <div class="field-group">
                        <label for="bankName">Interview Taken By:</label>
                       <p> ${Interview || "N/A"}</p>
                       <div style="border-left: 1px solid #ccc; height: 47px; margin: 0 10px;"></div>
                        <label for="bankBranch">Salary Final By:</label>
                        <p>${SalaryFinal || 'N/A'}</p>
                    </div>

                    <div class="field-group">
                        <label for="signature">Signature (हस्ताक्षर):</label>
                        <p></p>
                        <label for="dateTime"></label>
                        <p></p>
                    </div>
                </div>
            </div>
        </body>
        </html>
        `);

        // Add a new page for Terms and Conditions
        printWindow.document.write(`
    <html>
    <head>
        <title>Terms and Conditions</title>
        <style>
        bodyterm.{
            font-family: Arial, sans-serif;
            line-height: 1;
            margin: 15px;
            background-color: #f4f4f4;
            color: #333;
        }
            .containerterm {
                max-width: 1000px;
                margin: auto;
                background: #fff;
                padding: 20px;
                border-radius: 8px;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            }
            .h1term{
                color: #2c3e50;
                text-align: center;
                margin-bottom: 12px;
            }
            ul {
                list-style-type: disc;
                padding-left: 20px;
                margin-top: 15px;
            }
            li {
                margin-bottom: 7px;
                font-size: 12px;
            }  
            .section {
                margin-bottom: 10px;
            }
            .section h2 {
                font-size: 20px;
                margin-bottom: 5px;
                text-align: center;
            }
            .section p {
                font-size: 12px;
                line-height: 1.5;
            }
            .signature {
                margin-top: 15px;
            }   
            @media print {
                .pagebreak { page-break-before: always; } /* page-break-after works, as well */
            }  
        </style>
    </head>
    <body class="bodyterm">
    <div class="containerterm">
    <div class="pagebreak"> </div>
    <h1 class="h1term">Terms and Conditions / नियम और शर्तें</h1>
    <ul>
        <li>No headphones to be used for songs or movies.</li>
        <li>No tobacco on company premises.</li>
        <li>No mobile phone usage allowed.</li>
        <li>For labor: No watches, no long hair, no rakhi, no bands on hands.</li>
        <li>New joiners should get an eye test within 7 days of joining.</li>
        <li>There should be no criminal record in police records.</li>
        <li>Nails should be properly cut.</li>
        <li>No long hair for boys.</li>
        <li>Late coming to the office is not allowed.</li>
        <li>Parking is at your own risk.</li>
        <li>Ayushman Card – Yes / No.</li>
        <li>Photos of the company or company-related documents are not allowed.</li>
        <li>If any SIM/contact number is given by the company, it is considered company assets. You need to hand it over properly before the full and final settlement.</li>
    </ul>

    <ul>
            <li>गाने या फिल्म के लिए हेडफ़ोन का उपयोग न करें।</li>
            <li>कंपनी परिसर में तंबाकू निषेध है।</li>
            <li>फैक्ट्री परिसर में मोबाइल फोन का उपयोग नहीं किया जाना चाहिए।</li>
            <li>श्रमिकों के लिए - कोई घड़ी नहीं / लंबे बाल नहीं / राखी नहीं / हाथ में कोई बैंड नहीं।</li>
            <li>नए कर्मचारी को जॉइनिंग के 7 दिनों के भीतर आंखों की जांच करानी चाहिए।</li>
            <li>पुलिस में कोई आपराधिक रिकॉर्ड नहीं होना चाहिए।</li>
            <li>नाखून ठीक से कटे होने चाहिए।</li>
            <li>लड़कों के लिए लंबे बाल नहीं होने चाहिए।</li>
            <li>कार्यालय में देर से आने की अनुमति नहीं है।</li>
            <li>पार्किंग अपने जोखिम पर करें।</li>
            <li>आयुष्मान कार्ड – हाँ / नहीं।</li>
            <li>कंपनी या कंपनी से संबंधित दस्तावेज़ों की फोटो खींचना मना है।</li>
            <li>अगर कोई सिम/संपर्क नंबर कंपनी द्वारा दिया गया है, तो वह कंपनी की संपत्ति है। इसे फुल एंड फाइनल से पहले उचित तरीके से सौंपना होगा।</li>
        </ul>
        <div class="section">
        <h2>Declaration</h2>
        <p>I, <strong>${name}</strong>, hereby declare that I have read and understood the terms and conditions of Shiv Offset India Pvt. Ltd. I agree to abide by all the rules, regulations, and policies outlined by the company. I acknowledge that it is my responsibility to comply with these terms and conditions during my employment. Failure to adhere to the same may result in disciplinary action as per the company’s policies.</p>
        <div class="signature">
            <p>Date: _____________________</p>
            <p>Signature: _____________________</p>
            <p>Name: _____________________</p>
        </div>
    </div>

    <div class="section">
        <h2>सहमतिपत्र</h2>
        <p>मैं, <strong>${name}</strong>, यहाँ यह घोषणा करता/करती हूँ कि मैंने शिव ऑफसेट इंडिया प्राइवेट लिमिटेड की शर्तों और नियमों को पढ़ लिया है और उन्हें समझ लिया है। मैं कंपनी द्वारा निर्धारित सभी नियमों, विनियमों और नीतियों का पालन करने के लिए सहमत हूँ। मैं यह स्वीकार करता/करती हूँ कि इन शर्तों और नियमों का पालन करना मेरी जिम्मेदारी है। इनका पालन न करने पर कंपनी की नीतियों के अनुसार अनुशासनात्मक कार्रवाई की जा सकती है।</p>
        <div class="signature">
            <p>तारीख: _____________________</p>
            <p>हस्ताक्षर: _____________________</p>
            <p>नाम: _____________________</p>
        </div>
    </div> 
</div>
    </body>
    </html>
    `);
        printWindow.document.close();
        // printWindow.print();
    };

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        border: '2px solid',
        boxShadow: 24,
        padding: 16,
        minWidth: 300,
        maxWidth: 500,
        textAlign: 'center'
    };
    const handleSuccessModalClose = () => {
        setSuccessModalOpen(false);
    };

    const handleErrorModalClose = () => {
        setErrorModalOpen(false);
    };


    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <ProfileHeader name={StaffData.full_name} id={StaffData.machine_code} status="Android" EmpID={StaffData._id} />
            </Grid>
            {/* Side Navbar */}
            <Grid item xs={2}>
                <ProfileNav />
            </Grid>

            {/* Content Section */}
            <Grid item xs={10}>
                <Paper elevation={3} style={{ padding: '20px', marginBottom: '10px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
                            Profile
                        </Typography>
                        <Button variant='contained' color='info' onClick={() => { handlePrintRow() }}>Print Joining Letter</Button>
                    </div>

                    {/* Profile Info */}
                    <div style={{ padding: '10px' }}>
                        <Paper elevation={3} style={{ padding: '20px', marginBottom: '10px', position: 'relative' }}>
                            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                                Profile Information
                            </Typography>
                            <Button variant="outlined" color="primary" style={{ position: 'absolute', top: '10px', right: '10px' }} onClick={handleOpenProfileModal}>
                                Edit
                            </Button>

                            {/* Profile Modal */}
                            <Modal open={openProfileModal} onClose={handleCloseModal}>
                                <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '90%', maxWidth: '700px', bgcolor: 'white', boxShadow: 24, p: 4 }}>
                                    {/* Modal Content */}
                                    <Paper sx={{ padding: '50px' }}>
                                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Edit Profile Information</Typography>
                                        {/* First row */}
                                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                            <div style={{ marginRight: '20px', marginBottom: '20px', width: 'calc(50% - 20px)' }}>
                                                <TextField
                                                    label="Name"
                                                    variant="outlined"
                                                    defaultValue={name || ''}
                                                    fullWidth
                                                    onChange={(e) => setName(e.target.value)}
                                                    margin="normal"
                                                    style={{ marginBottom: '20px', width: '100%' }}
                                                />
                                            </div>
                                            <div style={{ marginRight: '20px', marginBottom: '20px', width: 'calc(50% - 20px)' }}>
                                                <TextField
                                                    label="ID"
                                                    variant="outlined"
                                                    defaultValue={machineCode || ''}
                                                    fullWidth
                                                    onChange={(e) => setmachineCode(e.target.value)}
                                                    margin="normal"
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                // Add onChange handler to update state
                                                />
                                            </div>
                                            <div style={{ marginRight: '20px', marginBottom: '20px', width: 'calc(50% - 20px)' }}>
                                                {/* <TextField
                                                    label="Department"
                                                    variant="outlined"
                                                    defaultValue={department || ''}
                                                    fullWidth
                                                    onChange={(e) => setDepartment(e.target.value)}
                                                    margin="normal"
                                                // Add onChange handler to update state
                                                /> */}
                                                <FormControl fullWidth>
                                                    <Autocomplete
                                                        value={department}
                                                        onChange={(event, newValue) => {
                                                            setDepartment(newValue);
                                                        }}
                                                        options={DepValues}
                                                        getOptionLabel={(option) => option?.discription || option?.description || ""}
                                                        getOptionSelected={(option, value) => option._id === value}
                                                        // style={{ height: '38px', fontSize: '14px' }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                                label="Department"
                                                                inputProps={{ ...params.inputProps, 'aria-label': 'Select Department Type', style: { paddingTop: '0px' } }}
                                                                sx={{ "& .MuiInputBase-root": { height: '56px', marginTop: "15px" } }}
                                                            />
                                                        )}
                                                    />

                                                </FormControl>
                                            </div>
                                            <div style={{ marginRight: '20px', marginBottom: '20px', width: 'calc(50% - 20px)' }}>
                                                {/* <TextField
                                                    label="Designation"
                                                    variant="outlined"
                                                    defaultValue={designation || ''}
                                                    fullWidth
                                                    onChange={(e) => setDesignation(e.target.value)}
                                                    margin="normal"
                                                // Add onChange handler to update state
                                                /> */}

                                                <FormControl fullWidth>
                                                    <Autocomplete
                                                        value={designation}
                                                        onChange={(event, newValue) => {
                                                            setDesignation(newValue);
                                                        }}
                                                        options={DesigValues}
                                                        getOptionLabel={(option) => option?.discription || ""}
                                                        getOptionSelected={(option, value) => option._id === value}
                                                        // style={{ height: '38px', fontSize: '14px' }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                                label="Designation"
                                                                inputProps={{ ...params.inputProps, 'aria-label': 'Select Designation Type', style: { paddingTop: '0px' } }}
                                                                sx={{ "& .MuiInputBase-root": { height: '56px', marginTop: "15px" } }}
                                                            />
                                                        )}
                                                    />

                                                </FormControl>
                                            </div>
                                            <div style={{ marginRight: '20px', marginBottom: '20px', width: 'calc(50% - 20px)' }}>
                                                {/* <TextField
                                                    label="Staff Type"
                                                    variant="outlined"
                                                    defaultValue={staffType || ''}
                                                    fullWidth
                                                    onChange={(e) => setStaffType(e.target.value)}
                                                    margin="normal"
                                                // Add onChange handler to update state
                                                /> */}
                                                <FormControl fullWidth>
                                                    <Autocomplete
                                                        value={staffType}
                                                        onChange={(event, newValue) => {
                                                            setStaffType(newValue);
                                                        }}
                                                        options={CatValues}
                                                        getOptionLabel={(option) => option?.discription || ""}
                                                        getOptionSelected={(option, value) => option._id === value}
                                                        // style={{ height: '38px', fontSize: '14px' }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                                label="Staff Type"
                                                                inputProps={{ ...params.inputProps, 'aria-label': 'Select Staff Type', style: { paddingTop: '0px' } }}
                                                                sx={{ "& .MuiInputBase-root": { height: '56px', marginTop: "15px" } }}
                                                            />
                                                        )}
                                                    />

                                                </FormControl>
                                            </div>
                                            <div style={{ marginRight: '20px', marginBottom: '20px', width: 'calc(50% - 20px)' }}>
                                                <TextField
                                                    label="Contact Number"
                                                    variant="outlined"
                                                    defaultValue={contactNumber || ''}
                                                    fullWidth
                                                    type="number"
                                                    onChange={(e) => setContactNumber(e.target.value)}
                                                    margin="normal"
                                                // Add onChange handler to update state
                                                />

                                            </div>
                                            <div style={{ marginRight: '20px', marginBottom: '20px', width: 'calc(50% - 20px)' }}>
                                                <TextField
                                                    label="Reporting Manager"
                                                    variant="outlined"
                                                    defaultValue={reportingManager || ''}
                                                    fullWidth
                                                    onChange={(e) => setReportingManager(e.target.value)}
                                                    margin="normal"
                                                // Add onChange handler to update state
                                                />
                                            </div>

                                            <div style={{ marginRight: '20px', marginBottom: '20px', width: 'calc(50% - 20px)' }}>
                                                <FormControl fullWidth>
                                                    <Autocomplete
                                                        value={machineName}
                                                        onChange={(event, newValue) => {
                                                            setmachineName(newValue);
                                                        }}
                                                        options={MachineNameValues}
                                                        getOptionLabel={(option) => option?.machine_name || ""}
                                                        getOptionSelected={(option, value) => option._id === value}
                                                        // style={{ height: '38px', fontSize: '14px' }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                                label="Machine Name"
                                                                inputProps={{ ...params.inputProps, 'aria-label': 'Select Staff Type', style: { paddingTop: '0px' } }}
                                                                sx={{ "& .MuiInputBase-root": { height: '56px', marginTop: "15px" } }}
                                                            />
                                                        )}
                                                    />

                                                </FormControl>
                                            </div>
                                            <div style={{ marginRight: '20px', marginBottom: '20px', width: 'calc(50% - 20px)' }}>
                                                <TextField
                                                    label="Password"
                                                    variant="outlined"
                                                    defaultValue={usrpwd || ''}
                                                    fullWidth
                                                    onChange={(e) => setusrpwd(e.target.value)}
                                                    margin="normal"
                                                // Add onChange handler to update state
                                                />
                                            </div>

                                            <div style={{ marginRight: '20px', marginBottom: '20px', width: 'calc(50% - 20px)' }}>
                                                <FormControl fullWidth>
                                                    <Autocomplete
                                                        value={usrdiv}
                                                        onChange={(event, newValue) => {
                                                            setusrdiv(newValue);
                                                        }}
                                                        options={DivValues}
                                                        getOptionLabel={(option) => option?.div_code || ""}
                                                        getOptionSelected={(option, value) => option._id === value}
                                                        // style={{ height: '38px', fontSize: '14px' }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                                label="Division"
                                                                inputProps={{ ...params.inputProps, 'aria-label': 'Select Staff Type', style: { paddingTop: '0px' } }}
                                                                sx={{ "& .MuiInputBase-root": { height: '56px', marginTop: "15px" } }}
                                                            />
                                                        )}
                                                    />

                                                </FormControl>
                                            </div>
                                        </div>
                                        <Button onClick={() => { handleSaveModal("ProfileInfo") }}>Save</Button>
                                        <Button onClick={handleCloseModal}>Cancel</Button>
                                    </Paper>
                                </div>
                            </Modal>
                            {/* First row */}
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Name:
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{name || '-'}</Typography>
                                </div>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Employee ID:
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{machineCode || '-'}</Typography>
                                </div>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Department:
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{StaffData?.department?.discription || '-'}</Typography>
                                </div>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Designation:
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{StaffData?.designation?.discription || '-'}</Typography>
                                </div>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Staff Type:
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{StaffData?.category?.discription || '-'}</Typography>
                                </div>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Contact Number:
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{contactNumber || '-'}</Typography>
                                </div>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Reporting Manager:
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{reportingManager || '-'}</Typography>
                                </div>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Machine Name:
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{StaffData?.machine?.machine_name || '-'}</Typography>
                                </div>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Password:
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{StaffData?.usrpwd || '-'}</Typography>
                                </div>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Division:
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{usrdiv?.div_code || '-'}</Typography>
                                </div>
                            </div>
                        </Paper>
                    </div>

                    {/* General info */}
                    <div style={{ padding: '10px' }}>
                        <Paper elevation={3} style={{ padding: '20px', marginBottom: '10px', position: 'relative' }}>
                            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                                General Information
                            </Typography>
                            <Button variant="outlined" color="primary" style={{ position: 'absolute', top: '10px', right: '10px' }} onClick={handleOpenGeneralModal}>
                                Edit
                            </Button>
                            <Modal open={openGeneralModal} onClose={handleCloseModal}>
                                <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '90%', maxWidth: '700px', bgcolor: 'white', boxShadow: 24, p: 4 }}>
                                    {/* Modal Content */}
                                    <Paper sx={{ padding: '20px' }}>
                                        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                                            Edit General Information
                                        </Typography>
                                        {/* First row */}
                                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                            <div style={{ marginRight: '20px', marginBottom: '20px', width: 'calc(50% - 20px)' }}>
                                                <TextField
                                                    label="Salary Cycle"
                                                    variant="outlined"
                                                    value={salaryCycle || ''}
                                                    fullWidth
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    margin="normal"
                                                />
                                            </div>
                                            <div style={{ marginRight: '20px', marginBottom: '20px', width: 'calc(50% - 20px)' }}>
                                                {/* <TextField
                                                    label="Weekly Off"
                                                    variant="outlined"
                                                    defaultValue={weeklyOff || ''}
                                                    fullWidth
                                                    onChange={(e) => setWeeklyOff(e.target.value)}
                                                    margin="normal"
                                                /> */}
                                                <Autocomplete
                                                    value={weeklyOff}
                                                    onChange={(event, newValue) => {
                                                        setWeeklyOff(newValue);
                                                    }}
                                                    options={weekdays}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Weekly Off"
                                                            variant="outlined"
                                                            fullWidth
                                                            margin="normal"
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div style={{ marginRight: '20px', marginBottom: '20px', width: 'calc(50% - 20px)' }}>
                                                <TextField
                                                    label="Holiday Template"
                                                    variant="outlined"
                                                    defaultValue={holidayTemplate || ''}
                                                    fullWidth
                                                    onChange={(e) => setHolidayTemplate(e.target.value)}
                                                    margin="normal"
                                                />
                                            </div>
                                            <div style={{ marginRight: '20px', marginBottom: '20px', width: 'calc(50% - 20px)' }}>
                                                <TextField
                                                    label="Leave Template"
                                                    variant="outlined"
                                                    defaultValue={leaveTemplate || ''}
                                                    fullWidth
                                                    onChange={(e) => setLeaveTemplate(e.target.value)}
                                                    margin="normal"
                                                />
                                            </div>
                                        </div>
                                        {/* Second row */}
                                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                            <div style={{ marginRight: '20px', marginBottom: '20px', width: 'calc(50% - 20px)' }}>
                                                {/* <TextField
                                                    label="Shift"
                                                    variant="outlined"
                                                    defaultValue={shift || ''}
                                                    fullWidth
                                                    onChange={(e) => setShift(e.target.value)}
                                                    margin="normal"
                                                /> */}
                                                <FormControl fullWidth>
                                                    <Autocomplete
                                                        value={shift}
                                                        onChange={(event, newValue) => {
                                                            setShift(newValue);
                                                        }}
                                                        options={ShiftValues}
                                                        getOptionLabel={(option) => option?.shift_name || ""}
                                                        getOptionSelected={(option, value) => option._id === value}
                                                        // style={{ height: '38px', fontSize: '14px' }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                                label="Shift Name"
                                                                inputProps={{ ...params.inputProps, 'aria-label': 'Select Department Type', style: { paddingTop: '0px' } }}
                                                                sx={{ "& .MuiInputBase-root": { height: '56px', marginTop: "15px" } }}
                                                            />
                                                        )}
                                                    />

                                                </FormControl>
                                            </div>
                                            <div style={{ marginRight: '20px', marginBottom: '20px', width: 'calc(50% - 20px)' }}>
                                                {/* <TextField
                                                    label="Salary Access"
                                                    variant="outlined"
                                                    defaultValue={salaryAccess || ''}
                                                    fullWidth
                                                    onChange={(e) => setSalaryAccess(e.target.value)}
                                                    margin="normal"
                                                /> */}
                                                <Autocomplete
                                                    value={salaryAccess}
                                                    onChange={(event, newValue) => {
                                                        setSalaryAccess(newValue);
                                                    }}
                                                    options={YNOptions}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Salary Access"
                                                            variant="outlined"
                                                            fullWidth
                                                            margin="normal"
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div style={{ marginRight: '20px', marginBottom: '20px', width: 'calc(50% - 20px)' }}>
                                                {/* <TextField
                                                    label="Attendance Mode"
                                                    variant="outlined"
                                                    defaultValue={attendanceMode || ''}
                                                    fullWidth
                                                    onChange={(e) => setAttendanceMode(e.target.value)}
                                                    margin="normal"
                                                /> */}
                                                <Autocomplete
                                                    value={attendanceMode}
                                                    onChange={(event, newValue) => {
                                                        setAttendanceMode(newValue);
                                                    }}
                                                    options={AttendanceModeOptions}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Attendance Mode"
                                                            variant="outlined"
                                                            fullWidth
                                                            margin="normal"
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div style={{ marginRight: '20px', marginBottom: '20px', width: 'calc(50% - 20px)' }}>
                                                {/* <TextField
                                                    label="Salary Access"
                                                    variant="outlined"
                                                    defaultValue={salaryAccess || ''}
                                                    fullWidth
                                                    onChange={(e) => setSalaryAccess(e.target.value)}
                                                    margin="normal"
                                                /> */}
                                                <Autocomplete
                                                    value={OTAccess}
                                                    onChange={(event, newValue) => {
                                                        setOTAccess(newValue);
                                                    }}
                                                    options={['Y', 'N', 1.5]}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="OT Applicable"
                                                            variant="outlined"
                                                            fullWidth
                                                            margin="normal"
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div style={{ marginRight: '20px', marginBottom: '20px', width: 'calc(50% - 20px)' }}>
                                                <TextField
                                                    label="Days"
                                                    variant="outlined"
                                                    value={SalDays || ''}
                                                    onChange={(e) => { setSalDays(e.target.value) }}
                                                    type='number'
                                                    fullWidth
                                                    margin="normal"
                                                />
                                            </div>
                                            <div style={{ marginRight: '20px', marginBottom: '20px', width: 'calc(50% - 20px)' }}>
                                                <Autocomplete
                                                    value={salary_mode}
                                                    onChange={(event, newValue) => {
                                                        setsalary_mode(newValue);
                                                    }}
                                                    options={['CASH', 'BANK']}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Salary Mode"
                                                            variant="outlined"
                                                            fullWidth
                                                            margin="normal"
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <Button onClick={() => { handleSaveModal("GenInfo") }}>Save</Button>
                                        <Button onClick={handleCloseModal}>Cancel</Button>
                                    </Paper>
                                </div>
                            </Modal>

                            {/* First row */}
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Salary Cycle:
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{StaffData?.salary_cycle || '-'}</Typography>
                                </div>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Weekly Off:
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{weeklyOff || '-'}</Typography>
                                </div>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Holiday Template:
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{holidayTemplate || '-'}</Typography>
                                </div>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Leave Template:
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{leaveTemplate || '-'}</Typography>
                                </div>
                            </div>
                            {/* Second row */}
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Shift
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{StaffData?.default_shift?.shift_name || '-'}</Typography>
                                </div>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Salary Access
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{salaryAccess || '-'}</Typography>
                                </div>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Attendance Mode
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{attendanceMode || '-'}</Typography>
                                </div>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        OT Applicable
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{OTAccess || '-'}</Typography>
                                </div>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Days
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{SalDays || '-'}</Typography>
                                </div>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Salary Mode
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{salary_mode || '-'}</Typography>
                                </div>

                            </div>
                        </Paper>
                    </div>

                    {/* Personal Info */}
                    <div style={{ padding: '10px' }}>
                        <Paper elevation={3} style={{ padding: '20px', marginBottom: '10px', position: 'relative' }}>
                            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                                Personal Information
                            </Typography>
                            <Button variant="outlined" color="primary" style={{ position: 'absolute', top: '10px', right: '10px' }} onClick={handleOpenPersonalModal}>
                                Edit
                            </Button>
                            {/* First row */}
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <Modal open={openPersonalModal} onClose={handleCloseModal}>
                                    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '90%', maxWidth: '700px', bgcolor: 'white', boxShadow: 24, p: 4 }}>
                                        {/* Modal Content */}
                                        <Paper sx={{ padding: '20px' }}>
                                            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                                                Edit Personal Information
                                            </Typography>
                                            {/* First row */}
                                            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                                                <TextField
                                                    label="Email"
                                                    variant="outlined"
                                                    defaultValue={email || ''}
                                                    autoComplete='off'
                                                    type="email"
                                                    fullWidth
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    margin="normal"
                                                    style={{ marginBottom: '10px', width: 'calc(25% - 10px)' }}
                                                />
                                                <Autocomplete
                                                    value={gender}
                                                    onChange={(event, newValue) => {
                                                        setGender(newValue);
                                                    }}
                                                    options={Genderoptions}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Gender"
                                                            variant="outlined"
                                                            fullWidth
                                                            margin="normal"
                                                        // style={{ marginBottom: '10px', width: 'calc(25% - 10px)' }}
                                                        />
                                                    )}
                                                    style={{ marginBottom: '10px', width: 'calc(25% - 10px)' }}
                                                />
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DatePicker
                                                        value={dateOfBirth ? dayjs(dateOfBirth) : null}
                                                        label='DOB'
                                                        format="DD/MM/YYYY" // Set the desired date format here
                                                        onChange={(newValue) => {
                                                            if (newValue && typeof newValue === 'object' && typeof newValue.toISOString === 'function') {
                                                                const indianTimeISO = newValue.tz('Asia/Kolkata').format();
                                                                setDateOfBirth(indianTimeISO);
                                                            } else {
                                                                setDateOfBirth(null);
                                                            }
                                                        }}
                                                        fullWidth
                                                        inputFormat="yyyy-dd-MM"
                                                        maxDate={dayjs().subtract(18, 'year')}
                                                        sx={{ marginTop: '16px', width: 'calc(25% - 10px)' }}
                                                    />
                                                </LocalizationProvider>
                                                <Autocomplete
                                                    value={martialStatus}
                                                    onChange={(event, newValue) => {
                                                        setMartialStatus(newValue);
                                                    }}
                                                    options={maritalStatusOptions}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Martial Status"
                                                            variant="outlined"
                                                            fullWidth
                                                            margin="normal"
                                                        />
                                                    )}
                                                    sx={{ marginBottom: '10px', width: 'calc(25% - 10px)' }}
                                                />

                                                {martialStatus !== 'Single' && (
                                                    <TextField
                                                        label="No. Of Kids"
                                                        variant="outlined"
                                                        defaultValue={NumberKids || ''}
                                                        type="number"
                                                        fullWidth
                                                        onChange={(e) => setNumberKids(e.target.value)}
                                                        margin="normal"
                                                        style={{ marginBottom: '5px', width: 'calc(25% - 10px)' }}
                                                    />
                                                )}
                                                {martialStatus !== 'Single' && (
                                                    <TextField
                                                        label="No. Of Sons"
                                                        variant="outlined"
                                                        defaultValue={NumberSons || ''}
                                                        type="number"
                                                        fullWidth
                                                        onChange={(e) => setNumberSons(e.target.value)}
                                                        margin="normal"
                                                        style={{ marginBottom: '5px', width: 'calc(25% - 10px)' }}
                                                    />
                                                )}
                                                {martialStatus !== 'Single' && (
                                                    <TextField
                                                        label="No. Of Daughters"
                                                        variant="outlined"
                                                        defaultValue={NumberDaughter || ''}
                                                        type="number"
                                                        fullWidth
                                                        onChange={(e) => setNumberDaughter(e.target.value)}
                                                        margin="normal"
                                                        style={{ marginBottom: '5px', width: 'calc(25% - 10px)' }}
                                                    />
                                                )}
                                                <Autocomplete
                                                    value={bloodGroup}
                                                    onChange={(event, newValue) => {
                                                        setBloodGroup(newValue);
                                                    }}
                                                    options={BloodGroupOptions}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Blood Group"
                                                            variant="outlined"
                                                            fullWidth
                                                            margin="normal"
                                                        />
                                                    )}
                                                    sx={{ marginBottom: '10px', width: 'calc(25% - 10px)' }}
                                                />
                                                <TextField
                                                    label="Emergency Contact"
                                                    variant="outlined"
                                                    defaultValue={emergencyContact || ''}
                                                    type='number'
                                                    fullWidth
                                                    onChange={(e) => setEmergencyContact(e.target.value)}
                                                    margin="normal"
                                                    style={{ marginBottom: '10px', width: 'calc(25% - 10px)' }}
                                                />
                                                <TextField
                                                    label="Father's Name"
                                                    variant="outlined"
                                                    defaultValue={fatherName || ''}
                                                    fullWidth
                                                    onChange={(e) => setFatherName(e.target.value)}
                                                    margin="normal"
                                                    style={{ marginBottom: '10px', width: 'calc(25% - 10px)' }}
                                                />
                                                <TextField
                                                    label="Mother's Name"
                                                    variant="outlined"
                                                    defaultValue={motherName || ''}
                                                    fullWidth
                                                    onChange={(e) => setMotherName(e.target.value)}
                                                    margin="normal"
                                                    style={{ marginBottom: '10px', width: 'calc(25% - 10px)' }}
                                                />

                                                <TextField
                                                    label="Spouse's Name"
                                                    variant="outlined"
                                                    defaultValue={spouseName || ''}
                                                    fullWidth
                                                    onChange={(e) => setSpouseName(e.target.value)}
                                                    margin="normal"
                                                    style={{ marginBottom: '10px', width: 'calc(25% - 10px)' }}
                                                />
                                                <Autocomplete
                                                    value={physicallyChallenged}
                                                    onChange={(event, newValue) => {
                                                        setPhysicallyChallenged(newValue);
                                                    }}
                                                    options={YNOptions}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Physically Challenged"
                                                            variant="outlined"
                                                            fullWidth
                                                            margin="normal"
                                                        />
                                                    )}
                                                    sx={{ marginBottom: '10px', width: 'calc(25% - 10px)' }}
                                                />
                                                {/* <TextField
                                                    label="Current Address"
                                                    variant="outlined"
                                                    defaultValue=""
                                                    fullWidth
                                                    
                                                    margin="normal"
                                                    style={{ marginBottom: '20px', width: 'calc(100% - 10px)' }}
                                                /> */}
                                                <TextField
                                                    label="Address Line 1"
                                                    variant="outlined"
                                                    defaultValue={addressLine1 || ''}
                                                    fullWidth
                                                    onChange={(e) => setAddressLine1(e.target.value)}
                                                    margin="normal"
                                                    style={{ marginBottom: '10px', width: 'calc(25% - 10px)' }}
                                                />
                                                <TextField
                                                    label="Address Line 2"
                                                    variant="outlined"
                                                    defaultValue={addressLine2 || ''}
                                                    fullWidth
                                                    onChange={(e) => setAddressLine2(e.target.value)}
                                                    margin="normal"
                                                    style={{ marginBottom: '10px', width: 'calc(25% - 10px)' }}
                                                />
                                                <TextField
                                                    label="Address Line 3"
                                                    variant="outlined"
                                                    defaultValue={addressLine3 || ''}
                                                    fullWidth
                                                    onChange={(e) => setAddressLine3(e.target.value)}
                                                    margin="normal"
                                                    style={{ marginBottom: '10px', width: 'calc(25% - 10px)' }}
                                                />
                                                <TextField
                                                    label="Place Of Birth"
                                                    variant="outlined"
                                                    defaultValue={placeOfBirth || ''}
                                                    fullWidth
                                                    onChange={(e) => setplaceOfBirth(e.target.value)}
                                                    margin="normal"
                                                    style={{ marginBottom: '10px', width: 'calc(25% - 10px)' }}
                                                />
                                                <Autocomplete
                                                    value={Citizenship}
                                                    onChange={(event, newValue) => {
                                                        setCitizenship(newValue);
                                                    }}
                                                    options={['India', 'Nepal']}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Citizenship"
                                                            variant="outlined"
                                                            fullWidth
                                                            margin="normal"
                                                        />
                                                    )}
                                                    sx={{ marginBottom: '10px', width: 'calc(25% - 10px)' }}
                                                />
                                                <TextField
                                                    label="Height (ft)"
                                                    variant="outlined"
                                                    type='number'
                                                    value={Height || ''}
                                                    fullWidth
                                                    onChange={(e) => {
                                                        // Validate that the input is a number below 8 and can have one decimal place
                                                        const value = e.target.value;
                                                        const regex = /^([1-7](\.\d{0,1})?)?$/; // Allows numbers 0-7 with up to one decimal place

                                                        if (regex.test(value) || value === '') {
                                                            setHeight(value);
                                                        }
                                                    }}
                                                    margin="normal"
                                                    style={{ marginBottom: '10px', width: 'calc(25% - 10px)' }}
                                                    inputProps={{
                                                        step: '0.1', // Allow increments of 0.1
                                                        min: 0,
                                                        max: 7.9, // Max height just below 8
                                                        pattern: '[1-7]?(\.[0-9]?)?', // Regular expression pattern for the input
                                                    }}
                                                />

                                                <TextField
                                                    type='number'
                                                    label="Weight (kg)"
                                                    variant="outlined"
                                                    value={Weight || ''}
                                                    fullWidth
                                                    onChange={(e) => {
                                                        // Get the input value and convert it to a number
                                                        const value = e.target.value;
                                                        const numberValue = parseFloat(value);

                                                        // Check if the value is a number and within the valid range
                                                        // if (!isNaN(numberValue) && numberValue >= 40 && numberValue <= 180) {
                                                        setWeight(value);
                                                        // }
                                                    }}
                                                    margin="normal"
                                                    style={{ marginBottom: '10px', width: 'calc(25% - 10px)' }}
                                                    inputProps={{
                                                        min: 40,
                                                        max: 180,
                                                        step: '0.1' // Allow decimal values
                                                    }}
                                                />

                                            </div>
                                            <Button onClick={() => { handleSaveModal("PersonalInfo") }}>Save</Button>
                                            <Button onClick={handleCloseModal}>Cancel</Button>
                                        </Paper>
                                    </div>
                                </Modal>

                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Email
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{email || '-'}</Typography>
                                </div>

                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Gender
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{gender || '-'}</Typography>
                                </div>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Date Of Birth
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{convertToIndianTime(dateOfBirth) || '-'}</Typography>
                                </div>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Martial Status
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{martialStatus || '-'}</Typography>
                                </div>
                                {martialStatus !== 'Single' && (
                                    <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                        <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                            No Of Kids
                                        </Typography>
                                        <Typography sx={{ fontWeight: 'bold' }} variant="body1">{NumberKids || '-'}</Typography>
                                    </div>
                                )}
                                {martialStatus !== 'Single' && (
                                    <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                        <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                            No Of Sons
                                        </Typography>
                                        <Typography sx={{ fontWeight: 'bold' }} variant="body1">{NumberSons || '-'}</Typography>
                                    </div>
                                )}
                                {martialStatus !== 'Single' && (
                                    <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                        <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                            No Of Daughters
                                        </Typography>
                                        <Typography sx={{ fontWeight: 'bold' }} variant="body1">{NumberDaughter || '-'}</Typography>
                                    </div>
                                )}
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Blood Group
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{bloodGroup || '-'}</Typography>
                                </div>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Emergency Contact
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{emergencyContact || '-'}</Typography>
                                </div>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Father's Name
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{fatherName || '-'}</Typography>
                                </div>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Mother's Name
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{motherName || '-'}</Typography>
                                </div>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Spouse's Name
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{spouseName || '-'}</Typography>
                                </div>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Physically Challenged
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{physicallyChallenged || '-'}</Typography>
                                </div>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Adress Line 1
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{addressLine1 || '-'}</Typography>
                                </div>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Adress Line 2
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{addressLine2 || '-'}</Typography>
                                </div>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Adress Line 3
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{addressLine3 || '-'}</Typography>
                                </div>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Place Of Birth
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{placeOfBirth || '-'}</Typography>
                                </div>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Citizenship
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{Citizenship || '-'}</Typography>
                                </div>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Height
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{Height || '-'}</Typography>
                                </div>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Weight
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{Weight || '-'}</Typography>
                                </div>

                            </div>
                        </Paper>
                    </div>



                    {/* Employement info  */}

                    <div style={{ padding: '10px' }}>
                        <Paper elevation={3} style={{ padding: '20px', marginBottom: '10px', position: 'relative' }}>
                            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                                Employement Information
                            </Typography>
                            <Button variant="outlined" color="primary" style={{ position: 'absolute', top: '10px', right: '10px' }} onClick={handleOpenEmploymentModal}>
                                Edit
                            </Button>
                            <Modal open={openEmploymentModal} onClose={handleCloseModal}>
                                <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '90%', maxWidth: '700px', bgcolor: 'white', boxShadow: 24, p: 4 }}>
                                    {/* Modal Content */}
                                    <Paper sx={{ padding: '20px' }}>
                                        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                                            Edit Employment Information
                                        </Typography>
                                        {/* First row */}
                                        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                                            {/* <TextField
                                                label="Date Of Joining"
                                                variant="outlined"
                                                defaultValue={dateOfJoining || ''}
                                                fullWidth
                                                onChange={(e) => setDateOfJoining(e.target.value)}
                                                margin="normal"
                                                style={{ marginBottom: '10px', width: 'calc(25% - 10px)' }}
                                            /> */}

                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    value={dateOfJoining ? dayjs(dateOfJoining) : null}
                                                    label='Date Of Joining'
                                                    onChange={(newValue) => {
                                                        if (newValue && typeof newValue === 'object' && typeof newValue.toISOString === 'function') {
                                                            const indianTimeISO = newValue.tz('Asia/Kolkata').format(); // Convert to Indian time zone in ISO format
                                                            setDateOfJoining(indianTimeISO);
                                                        } else {
                                                            setDateOfJoining(null);
                                                        }
                                                    }}
                                                    fullWidth
                                                    inputFormat="yyyy-dd-MM"
                                                    sx={{ marginTop: '16px', width: 'calc(25% - 10px)' }}
                                                />
                                            </LocalizationProvider>
                                            <TextField
                                                label="UAN"
                                                variant="outlined"
                                                defaultValue={uan || ''}
                                                fullWidth
                                                onChange={(e) => setUan(e.target.value)}
                                                margin="normal"
                                                style={{ marginBottom: '10px', width: 'calc(25% - 10px)' }}
                                            />
                                            <TextField
                                                label="PAN Number"
                                                variant="outlined"
                                                defaultValue={panNumber || ''}
                                                fullWidth
                                                onChange={(e) => setPanNumber(e.target.value)}
                                                margin="normal"
                                                style={{ marginBottom: '10px', width: 'calc(25% - 10px)' }}
                                            />
                                            {/* Second row */}
                                            <TextField
                                                label="Aadhaar Number"
                                                variant="outlined"
                                                defaultValue={aadhaarNumber || ''}
                                                fullWidth
                                                onChange={(e) => setAadhaarNumber(e.target.value)}
                                                margin="normal"
                                                style={{ marginBottom: '10px', width: 'calc(25% - 10px)' }}
                                            />
                                            {!aadhaarNumber && (
                                                <TextField
                                                    label="Aadhaar Enrollment Number"
                                                    variant="outlined"
                                                    defaultValue={aadhaarEnrollmentNumber || ''}
                                                    fullWidth
                                                    onChange={(e) => setAadhaarEnrollmentNumber(e.target.value)}
                                                    margin="normal"
                                                    style={{ marginBottom: '10px', width: 'calc(25% - 10px)' }}
                                                />
                                            )}
                                            <Autocomplete
                                                value={pfEligible}
                                                onChange={(event, newValue) => {
                                                    setPfEligible(newValue);
                                                }}
                                                options={YNOptions}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="PF Eligible"
                                                        variant="outlined"
                                                        fullWidth
                                                        margin="normal"
                                                    />
                                                )}
                                                sx={{ marginBottom: '10px', width: 'calc(25% - 10px)' }}
                                            />
                                            {/* Third row */}
                                            {/* <TextField
                                                label="PF Joining Date"
                                                variant="outlined"
                                                defaultValue={pfJoiningDate || ''}
                                                fullWidth
                                                onChange={(e) => setPfJoiningDate(e.target.value)}
                                                margin="normal"
                                                style={{ marginBottom: '10px', width: 'calc(25% - 10px)' }}
                                            /> */}
                                            {pfEligible === 'Y' && (
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DatePicker
                                                        value={pfJoiningDate ? dayjs(pfJoiningDate) : null}
                                                        label='PF Joining'
                                                        onChange={(newValue) => {
                                                            if (newValue && typeof newValue === 'object' && typeof newValue.toISOString === 'function') {
                                                                const indianTimeISO = newValue.tz('Asia/Kolkata').format(); // Convert to Indian time zone in ISO format
                                                                setPfJoiningDate(indianTimeISO);
                                                            } else {
                                                                setPfJoiningDate(null);
                                                            }
                                                        }}
                                                        fullWidth
                                                        inputFormat="yyyy-dd-MM"
                                                        sx={{ marginTop: '16px', width: 'calc(25% - 10px)' }}
                                                    />
                                                </LocalizationProvider>
                                            )}
                                            {/* <TextField
                                                label="PF Eligible"
                                                variant="outlined"
                                                defaultValue={pfEligible || ''}
                                                fullWidth
                                                onChange={(e) => setPfEligible(e.target.value)}
                                                margin="normal"
                                                style={{ marginBottom: '10px', width: 'calc(25% - 10px)' }}
                                            /> */}
                                            {pfEligible === 'Y' && (
                                                <TextField
                                                    label="PF Number"
                                                    variant="outlined"
                                                    defaultValue={pfNumber || ''}
                                                    fullWidth
                                                    onChange={(e) => setPfNumber(e.target.value)}
                                                    margin="normal"
                                                    style={{ marginBottom: '10px', width: 'calc(25% - 10px)' }}
                                                />
                                            )}
                                            {/* <TextField
                                                label="ESI Eligible"
                                                variant="outlined"
                                                defaultValue={esiEligible || ''}
                                                fullWidth
                                                onChange={(e) => setEsiEligible(e.target.value)}
                                                margin="normal"
                                                style={{ marginBottom: '10px', width: 'calc(25% - 10px)' }}
                                            /> */}
                                            {/* <Autocomplete
                                                value={esiEligible}
                                                onChange={(event, newValue) => {
                                                    setEsiEligible(newValue);
                                                }}
                                                options={YNOptions}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="ESI Eligible"
                                                        variant="outlined"
                                                        fullWidth
                                                        margin="normal"
                                                    />
                                                )}
                                                sx={{ marginBottom: '10px', width: 'calc(25% - 10px)' }}
                                            /> */}
                                            {/* Fourth row */}
                                            {/* <TextField
                                                label="ESI Number"
                                                variant="outlined"
                                                defaultValue={esiNumber || ''}
                                                fullWidth
                                                onChange={(e) => setEsiNumber(e.target.value)}
                                                margin="normal"
                                                style={{ marginBottom: '10px', width: 'calc(25% - 10px)' }}
                                            /> */}
                                            <TextField
                                                label="ESIC Number"
                                                variant="outlined"
                                                defaultValue={esicNumber || ''}
                                                fullWidth
                                                onChange={(e) => setEsicNumber(e.target.value)}
                                                margin="normal"
                                                style={{ marginBottom: '10px', width: 'calc(25% - 10px)' }}
                                            />

                                            {/* <TextField
                                                label="PT Eligible"
                                                variant="outlined"
                                                defaultValue={ptEligible || ''}
                                                fullWidth
                                                onChange={(e) => setPtEligible(e.target.value)}
                                                margin="normal"
                                                style={{ marginBottom: '10px', width: 'calc(25% - 10px)' }}
                                            /> */}
                                            <Autocomplete
                                                value={ptEligible}
                                                onChange={(event, newValue) => {
                                                    setPtEligible(newValue);
                                                }}
                                                options={YNOptions}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="PT Eligible"
                                                        variant="outlined"
                                                        fullWidth
                                                        margin="normal"
                                                    />
                                                )}
                                                sx={{ marginBottom: '10px', width: 'calc(25% - 10px)' }}
                                            />
                                            {/* <TextField
                                                label="LWF Eligible"
                                                variant="outlined"
                                                defaultValue={lwfEligible || ''}
                                                fullWidth
                                                onChange={(e) => setLwfEligible(e.target.value)}
                                                margin="normal"
                                                style={{ marginBottom: '10px', width: 'calc(25% - 10px)' }}
                                            /> */}
                                            {/* <Autocomplete
                                                value={lwfEligible}
                                                onChange={(event, newValue) => {
                                                    setLwfEligible(newValue);
                                                }}
                                                options={YNOptions}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="LWF Eligible"
                                                        variant="outlined"
                                                        fullWidth
                                                        margin="normal"
                                                    />
                                                )}
                                                sx={{ marginBottom: '10px', width: 'calc(25% - 10px)' }}
                                            /> */}
                                            {/* Fifth row */}
                                            {/* <TextField
                                                label="EPS Eligible"
                                                variant="outlined"
                                                defaultValue={epsEligible || ''}
                                                fullWidth
                                                onChange={(e) => setEpsEligible(e.target.value)}
                                                margin="normal"
                                                style={{ marginBottom: '10px', width: 'calc(25% - 10px)' }}
                                            /> */}
                                            {/* <Autocomplete
                                                value={epsEligible}
                                                onChange={(event, newValue) => {
                                                    setEpsEligible(newValue);
                                                }}
                                                options={YNOptions}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="EPS Eligible"
                                                        variant="outlined"
                                                        fullWidth
                                                        margin="normal"
                                                    />
                                                )}
                                                sx={{ marginBottom: '10px', width: 'calc(25% - 10px)' }}
                                            /> */}
                                            {/* <TextField
                                                label="EPS Joining Date"
                                                variant="outlined"
                                                defaultValue={epsJoiningDate || ''}
                                                fullWidth
                                                onChange={(e) => setEpsJoiningDate(e.target.value)}
                                                margin="normal"
                                                style={{ marginBottom: '10px', width: 'calc(25% - 10px)' }}
                                            /> */}
                                            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    value={epsJoiningDate ? dayjs(epsJoiningDate) : null}
                                                    label='EPS Joining Date'
                                                    onChange={(newValue) => {
                                                        if (newValue && typeof newValue === 'object' && typeof newValue.toISOString === 'function') {
                                                            const indianTimeISO = newValue.tz('Asia/Kolkata').format(); // Convert to Indian time zone in ISO format
                                                            setEpsJoiningDate(indianTimeISO);
                                                        } else {
                                                            setEpsJoiningDate(null);
                                                        }
                                                    }}
                                                    fullWidth
                                                    inputFormat="yyyy-dd-MM"
                                                    sx={{ marginTop: '16px', width: 'calc(25% - 10px)' }}
                                                />
                                            </LocalizationProvider> */}

                                            {/* <TextField
                                                label="EPS Exit Date"
                                                variant="outlined"
                                                defaultValue={epsExitDate || ''}
                                                fullWidth
                                                onChange={(e) => setEpsExitDate(e.target.value)}
                                                margin="normal"
                                                style={{ marginBottom: '10px', width: 'calc(25% - 10px)' }}
                                            /> */}
                                            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    value={epsExitDate ? dayjs(epsExitDate) : null}
                                                    label='EPS Exit Date'
                                                    onChange={(newValue) => {
                                                        if (newValue && typeof newValue === 'object' && typeof newValue.toISOString === 'function') {
                                                            const indianTimeISO = newValue.tz('Asia/Kolkata').format(); // Convert to Indian time zone in ISO format
                                                            setEpsExitDate(indianTimeISO);
                                                        } else {
                                                            setEpsExitDate(null);
                                                        }
                                                    }}
                                                    fullWidth
                                                    inputFormat="yyyy-dd-MM"
                                                    sx={{ marginTop: '16px', width: 'calc(25% - 10px)' }}
                                                />
                                            </LocalizationProvider> */}

                                            {/* <TextField
                                                label="HPS Eligible"
                                                variant="outlined"
                                                defaultValue={hpsEligible || ''}
                                                fullWidth
                                                onChange={(e) => setHpsEligible(e.target.value)}
                                                margin="normal"
                                                style={{ marginBottom: '10px', width: 'calc(25% - 10px)' }}
                                            /> */}
                                            {/* <Autocomplete
                                                value={hpsEligible}
                                                onChange={(event, newValue) => {
                                                    setHpsEligible(newValue);
                                                }}
                                                options={YNOptions}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="HPS Eligible"
                                                        variant="outlined"
                                                        fullWidth
                                                        margin="normal"
                                                    />
                                                )}
                                                sx={{ marginBottom: '10px', width: 'calc(25% - 10px)' }}
                                            /> */}

                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    value={dateOfResign ? dayjs(dateOfResign) : null}
                                                    label='Date Of Resign'
                                                    onChange={(newValue) => {
                                                        if (newValue && typeof newValue === 'object' && typeof newValue.toISOString === 'function') {
                                                            const indianTimeISO = newValue.tz('Asia/Kolkata').format(); // Convert to Indian time zone in ISO format
                                                            setDateOfResign(indianTimeISO);
                                                        } else {
                                                            setDateOfResign(null);
                                                        }
                                                    }}
                                                    minDate={dateOfJoining ? dayjs(dateOfJoining).add(1, 'day') : undefined} // Ensure "Date of Resign" is strictly after "Date of Joining"
                                                    fullWidth
                                                    disabled={!dateOfJoining}
                                                    inputFormat="yyyy-dd-MM"
                                                    sx={{ marginTop: '16px', width: 'calc(25% - 10px)' }}
                                                />
                                            </LocalizationProvider>


                                        </div>
                                        <Button onClick={() => { handleSaveModal("EmployementInfo") }}>Save</Button>
                                        <Button onClick={handleCloseModal}>Cancel</Button>
                                    </Paper>
                                </div>
                            </Modal>

                            {/* First row */}
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Date Of Joining
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{convertToIndianTime(dateOfJoining) || '-'}</Typography>
                                </div>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        UAN
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{uan || '-'}</Typography>
                                </div>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        PAN Number
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{panNumber || '-'}</Typography>
                                </div>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Aadhaar Number
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{aadhaarNumber || '-'}</Typography>
                                </div>
                                {!aadhaarNumber && (
                                    <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                        <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                            Aadhaar Enrollment Number
                                        </Typography>
                                        <Typography sx={{ fontWeight: 'bold' }} variant="body1">{aadhaarEnrollmentNumber || '-'}</Typography>
                                    </div>
                                )}
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        PF Eligible
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{pfEligible || '-'}</Typography>
                                </div>
                                {pfEligible === 'Y' && (
                                    <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                        <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                            PF Number
                                        </Typography>
                                        <Typography sx={{ fontWeight: 'bold' }} variant="body1">{pfNumber || '-'}</Typography>
                                    </div>
                                )}
                                {pfEligible === 'Y' && (
                                    <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                        <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                            PF Joining Date
                                        </Typography>
                                        <Typography sx={{ fontWeight: 'bold' }} variant="body1">{convertToIndianTime(pfJoiningDate) || '-'}</Typography>
                                    </div>
                                )}
                                {/* <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        ESI Eligible
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{esiEligible || '-'}</Typography>
                                </div> */}
                                {/* <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        ESI Number
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{esiNumber || '-'}</Typography>
                                </div> */}
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        PT Eligible
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{ptEligible || '-'}</Typography>
                                </div>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Date Of Resign
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{convertToIndianTime(dateOfResign) || '-'}</Typography>
                                </div>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        ESIC Number
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{esicNumber || '-'}</Typography>
                                </div>
                            </div>
                        </Paper>
                    </div>

                    {/* Other Info */}
                    <div style={{ padding: '10px' }}>
                        <Paper elevation={3} style={{ padding: '20px', marginBottom: '10px', position: 'relative' }}>
                            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                                Other Information
                            </Typography>
                            <Button variant="outlined" color="primary" style={{ position: 'absolute', top: '10px', right: '10px' }} onClick={handleOpenOtherModal}>
                                Edit
                            </Button>
                            {/* First row */}
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <Modal open={openOtherModal} onClose={handleCloseModal}>
                                    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '100%', maxWidth: '700px', bgcolor: 'white', boxShadow: 24, p: 4 }}>
                                        {/* Modal Content */}
                                        <Paper sx={{ padding: '20px' }}>
                                            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                                                Edit Other Information
                                            </Typography>
                                            {/* First row */}
                                            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>

                                                <Autocomplete
                                                    value={RentedOwen}
                                                    onChange={(event, newValue) => {
                                                        setRentedOwen(newValue);
                                                    }}
                                                    options={['Rented', 'Owned Home']}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Rented / Owned Home"
                                                            variant="outlined"
                                                            fullWidth
                                                            margin="normal"
                                                        // style={{ marginBottom: '10px', width: 'calc(25% - 10px)' }}
                                                        />
                                                    )}
                                                    style={{ marginBottom: '5px', width: 'calc(25% - 10px)' }}
                                                />

                                                <Autocomplete
                                                    value={OldEmpRelation}
                                                    onChange={(event, newValue) => {
                                                        setOldEmpRelation(newValue);
                                                    }}
                                                    options={['Parents', 'Spouses', 'Siblings', 'Aunt', 'Uncle', 'Cousins', 'Other Relatives', 'Maternal Relatives', 'N']}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Any Relation With Old Employee"
                                                            variant="outlined"
                                                            fullWidth
                                                            margin="normal"
                                                        // style={{ marginBottom: '10px', width: 'calc(25% - 10px)' }}
                                                        />
                                                    )}
                                                    style={{ marginBottom: '5px', width: 'calc(25% - 10px)' }}
                                                />

                                                {/* Second row */}


                                                <Autocomplete
                                                    value={EyeGlasses}
                                                    onChange={(event, newValue) => {
                                                        setEyeGlasses(newValue);
                                                    }}
                                                    options={['Y', 'N']}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Eye Glasses"
                                                            variant="outlined"
                                                            fullWidth
                                                            margin="normal"
                                                        />
                                                    )}
                                                    sx={{ marginBottom: '5px', width: 'calc(25% - 10px)' }}
                                                />

                                                <Autocomplete
                                                    value={SeenFireSysVid}
                                                    onChange={(event, newValue) => {
                                                        setSeenFireSysVid(newValue);
                                                    }}
                                                    options={['Y', 'N']}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Fire System Safety Video seen"
                                                            variant="outlined"
                                                            fullWidth
                                                            margin="normal"
                                                        />
                                                    )}
                                                    sx={{ marginBottom: '5px', width: 'calc(25% - 10px)' }}
                                                />
                                                <Autocomplete
                                                    value={Kharchi}
                                                    onChange={(event, newValue) => {
                                                        setKharchi(newValue);
                                                    }}
                                                    options={['Y', 'N']}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Kharchi"
                                                            variant="outlined"
                                                            fullWidth
                                                            margin="normal"
                                                        />
                                                    )}
                                                    sx={{ marginBottom: '5px', width: 'calc(25% - 10px)' }}
                                                />

                                                {/* Third row */}
                                                <TextField
                                                    label="Bank Account Opened By Company"
                                                    variant="outlined"
                                                    defaultValue={bankAccountByCOM || ''}
                                                    fullWidth
                                                    onChange={(e) => setbankAccountByCOM(e.target.value)}
                                                    margin="normal"
                                                    style={{ marginBottom: '5px', width: 'calc(25% - 10px)' }}
                                                />
                                                {/* <TextField
                                                    label="Any Bank Loan - Per Month EMI"
                                                    variant="outlined"
                                                    defaultValue={BankLoanEMI || ''}
                                                    fullWidth
                                                    onChange={(e) => setBankLoanEMI(e.target.value)}
                                                    margin="normal"
                                                    style={{ marginBottom: '10px', width: 'calc(25% - 10px)' }}
                                                /> */}


                                                <Autocomplete
                                                    value={NonVeg}
                                                    onChange={(event, newValue) => {
                                                        setNonVeg(newValue);
                                                    }}
                                                    options={['Y', 'N']}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="No Non-Veg Allowed In Food"
                                                            variant="outlined"
                                                            fullWidth
                                                            margin="normal"
                                                        />
                                                    )}
                                                    sx={{ marginBottom: '5px', width: 'calc(25% - 10px)' }}
                                                />
                                                <Autocomplete
                                                    value={MobilePolicy}
                                                    onChange={(event, newValue) => {
                                                        setMobilePolicy(newValue);
                                                    }}
                                                    options={['Y', 'N']}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Mobile Policy"
                                                            variant="outlined"
                                                            fullWidth
                                                            margin="normal"
                                                        />
                                                    )}
                                                    sx={{ marginBottom: '5px', width: 'calc(25% - 10px)' }}
                                                />
                                                {/* Fourth row */}
                                                <TextField
                                                    label="Person In CTC: Rs. /- PA (Salary + Bonus)"
                                                    variant="outlined"
                                                    defaultValue={PersonCTC || ''}
                                                    fullWidth
                                                    onChange={(e) => setPersonCTC(e.target.value)}
                                                    margin="normal"
                                                    style={{ marginBottom: '5px', width: 'calc(25% - 10px)' }}
                                                />
                                                <TextField
                                                    label="Occupation"
                                                    variant="outlined"
                                                    defaultValue={Occupation || ''}
                                                    fullWidth
                                                    onChange={(e) => setOccupation(e.target.value)}
                                                    margin="normal"
                                                    style={{ marginBottom: '5px', width: 'calc(25% - 10px)' }}
                                                />
                                                <TextField
                                                    label="Working Hours"
                                                    variant="outlined"
                                                    defaultValue={WorkingHours || ''}
                                                    fullWidth
                                                    onChange={(e) => setWorkingHours(e.target.value)}
                                                    margin="normal"
                                                    style={{ marginBottom: '5px', width: 'calc(25% - 10px)' }}
                                                />
                                                <Autocomplete
                                                    value={PoliceRecord}
                                                    onChange={(event, newValue) => {
                                                        setPoliceRecord(newValue);
                                                    }}
                                                    options={['Y', 'N']}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Police Record"
                                                            variant="outlined"
                                                            fullWidth
                                                            margin="normal"
                                                        />
                                                    )}
                                                    sx={{ marginBottom: '5px', width: 'calc(25% - 10px)' }}
                                                />
                                                <TextField
                                                    label="OT Payout"
                                                    variant="outlined"
                                                    defaultValue={OTPayout || ''}
                                                    fullWidth
                                                    onChange={(e) => setOTPayout(e.target.value)}
                                                    margin="normal"
                                                    style={{ marginBottom: '5px', width: 'calc(25% - 10px)' }}
                                                />
                                                <Autocomplete
                                                    value={BankLoanEMI}
                                                    onChange={(event, newValue) => {
                                                        setBankLoanEMI(newValue);
                                                    }}
                                                    options={['Y', 'N']}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Any Bank Loan"
                                                            variant="outlined"
                                                            fullWidth
                                                            margin="normal"
                                                        />
                                                    )}
                                                    sx={{ marginBottom: '5px', width: 'calc(25% - 10px)' }}
                                                />
                                                {BankLoanEMI === 'Y' && (
                                                    <TextField
                                                        label="Loan Amount"
                                                        variant="outlined"
                                                        defaultValue={Tookloanamount || ''}
                                                        fullWidth
                                                        onChange={(e) => setTookloanamount(e.target.value)}
                                                        margin="normal"
                                                        style={{ marginBottom: '5px', width: 'calc(25% - 10px)' }}
                                                    />
                                                )}
                                                {BankLoanEMI === 'Y' && (

                                                    <TextField
                                                        label="EMI"
                                                        variant="outlined"
                                                        defaultValue={Tookemi || ''}
                                                        fullWidth
                                                        onChange={(e) => setTookemi(e.target.value)}
                                                        margin="normal"
                                                        style={{ marginBottom: '5px', width: 'calc(25% - 10px)' }}
                                                    />
                                                )}

                                                {BankLoanEMI === 'Y' && (

                                                    <TextField
                                                        label="Bank Name"
                                                        variant="outlined"
                                                        defaultValue={Tookbankname || ''}
                                                        fullWidth
                                                        onChange={(e) => setTookbankname(e.target.value)}
                                                        margin="normal"
                                                        style={{ marginBottom: '5px', width: 'calc(25% - 10px)' }}
                                                    />
                                                )}

                                                <TextField
                                                    label="Interview Taken By"
                                                    variant="outlined"
                                                    defaultValue={Interview || ''}
                                                    fullWidth
                                                    onChange={(e) => setInterview(e.target.value)}
                                                    margin="normal"
                                                    style={{ marginBottom: '5px', width: 'calc(25% - 10px)' }}
                                                />
                                                {/* <TextField
                                                    label="Salary Final By"
                                                    variant="outlined"
                                                    defaultValue={SalaryFinal || ''}
                                                    fullWidth
                                                    onChange={(e) => setSalaryFinal(e.target.value)}
                                                    margin="normal"
                                                    style={{ marginBottom: '10px', width: 'calc(25% - 10px)' }}
                                                /> */}
                                                <Autocomplete
                                                    value={SalaryFinal}
                                                    onChange={(event, newValue) => {
                                                        setSalaryFinal(newValue);
                                                    }}
                                                    options={['JITEN', 'VICKY', 'BOSS']}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Salary Final By"
                                                            variant="outlined"
                                                            fullWidth
                                                            margin="normal"
                                                        />
                                                    )}
                                                    sx={{ marginBottom: '5px', width: 'calc(25% - 10px)' }}
                                                />

                                                <Autocomplete
                                                    value={Aayushman}
                                                    onChange={(event, newValue) => {
                                                        setAayushman(newValue);
                                                    }}
                                                    options={['Y', 'N']}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Ayushman Card"
                                                            variant="outlined"
                                                            fullWidth
                                                            margin="normal"
                                                        />
                                                    )}
                                                    sx={{ marginBottom: '5px', width: 'calc(25% - 10px)' }}
                                                />

                                                <Autocomplete
                                                    value={MedicalCliam}
                                                    onChange={(event, newValue) => {
                                                        setMedicalCliam(newValue);
                                                    }}
                                                    options={['Y', 'N']}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Medical Claim"
                                                            variant="outlined"
                                                            fullWidth
                                                            margin="normal"
                                                        />
                                                    )}
                                                    sx={{ marginBottom: '5px', width: 'calc(25% - 10px)' }}
                                                />
                                            </div>
                                            <Button onClick={() => { handleSaveModal("Other") }}>Save</Button>
                                            <Button onClick={handleCloseModal}>Cancel</Button>
                                        </Paper>
                                    </div>
                                </Modal>

                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Rented / Owned Home
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{RentedOwen || '-'}</Typography>
                                </div>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Any Relation With Old Employee
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{OldEmpRelation || '-'}</Typography>
                                </div>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Fire System Safety Video seen
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{SeenFireSysVid || '-'}</Typography>
                                </div>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Kharchi                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{Kharchi || '-'}</Typography>
                                </div>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Bank Account Opened By Company
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{bankAccountByCOM || '-'}</Typography>
                                </div>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        No Non-Veg Allowed In Food
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{NonVeg || '-'}</Typography>
                                </div>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Eye Glasses
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{EyeGlasses || '-'}</Typography>
                                </div>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Mobile Policy
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{MobilePolicy || '-'}</Typography>
                                </div>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Person In CTC: Rs. /- PA (Salary + Bonus)
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{PersonCTC || '-'}</Typography>
                                </div>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Occupation
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{Occupation || '-'}</Typography>
                                </div>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Working Hours
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{WorkingHours || '-'}</Typography>
                                </div>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Police Record
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{PoliceRecord || '-'}</Typography>
                                </div>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        OverTime Payout
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{OTPayout || '-'}</Typography>
                                </div>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Any Bank Loan
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{BankLoanEMI || '-'}</Typography>
                                </div>
                                {BankLoanEMI === 'Y' && (
                                    <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                        <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                            Loan Amount
                                        </Typography>
                                        <Typography sx={{ fontWeight: 'bold' }} variant="body1">{Tookloanamount || '-'}</Typography>
                                    </div>
                                )}
                                {BankLoanEMI === 'Y' && (
                                    <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                        <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                            EMI
                                        </Typography>
                                        <Typography sx={{ fontWeight: 'bold' }} variant="body1">{Tookemi || '-'}</Typography>
                                    </div>
                                )}
                                {BankLoanEMI === 'Y' && (
                                    <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                        <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                            Bank Name
                                        </Typography>
                                        <Typography sx={{ fontWeight: 'bold' }} variant="body1">{Tookbankname || '-'}</Typography>
                                    </div>
                                )}
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Interview Taken By
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{Interview || '-'}</Typography>
                                </div>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Salary Final By
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{SalaryFinal || '-'}</Typography>
                                </div>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Ayushman Card
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{Aayushman || '-'}</Typography>
                                </div>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Medical Claim
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{MedicalCliam || '-'}</Typography>
                                </div>
                            </div>
                        </Paper>
                    </div>

                    {/* Bank Details */}
                    <div style={{ padding: '10px' }}>
                        <Paper elevation={3} style={{ padding: '20px', marginBottom: '10px', position: 'relative' }}>
                            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                                Bank Details
                            </Typography>
                            <Button variant="outlined" color="primary" style={{ position: 'absolute', top: '10px', right: '10px' }} onClick={handleOpenBankModal}>
                                Edit
                            </Button>
                            <Modal open={openBankModal} onClose={handleCloseModal}>
                                <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '90%', maxWidth: '700px', bgcolor: 'white', boxShadow: 24, p: 4 }}>
                                    {/* Modal Content */}
                                    <Paper sx={{ padding: '20px' }}>
                                        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                                            Edit Bank Details
                                        </Typography>
                                        {/* First row */}
                                        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                                            {/* <TextField
                                                label="Name Of Bank"
                                                variant="outlined"
                                                defaultValue={bankName || ''}
                                                fullWidth
                                                onChange={(e) => setBankName(e.target.value)}
                                                margin="normal"
                                                style={{ marginBottom: '20px', width: 'calc(40% - 10px)' }}
                                            /> */}
                                            <FormControl fullWidth>
                                                <Autocomplete
                                                    value={bankName}
                                                    onChange={(event, newValue) => {
                                                        setBankName(newValue);
                                                    }}
                                                    options={BankValues}
                                                    getOptionLabel={(option) => option?.discription || ""}
                                                    getOptionSelected={(option, value) => option._id === value}
                                                    // style={{ height: '38px', fontSize: '14px' }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            label="Bank"
                                                            inputProps={{ ...params.inputProps, 'aria-label': 'Select Department Type', style: { paddingTop: '0px' } }}
                                                            sx={{ "& .MuiInputBase-root": { height: '56px', marginTop: "15px" } }}
                                                        />
                                                    )}
                                                />

                                            </FormControl>

                                            <TextField
                                                label="Bank Branch"
                                                variant="outlined"
                                                defaultValue={bankBranch || ''}
                                                fullWidth
                                                onChange={(e) => setbankBranch(e.target.value)}
                                                margin="normal"
                                                style={{ marginBottom: '20px', width: 'calc(40% - 10px)' }}
                                            />
                                            <TextField
                                                label="IFSC Code"
                                                variant="outlined"
                                                defaultValue={ifscCode || ''}
                                                fullWidth
                                                onChange={(e) => setIfscCode(e.target.value)}
                                                margin="normal"
                                                style={{ marginBottom: '20px', width: 'calc(40% - 10px)' }}
                                            />
                                            <TextField
                                                label="Account Number"
                                                variant="outlined"
                                                defaultValue={accountNumber || ''}
                                                fullWidth
                                                onChange={(e) => setAccountNumber(e.target.value)}
                                                margin="normal"
                                                style={{ marginBottom: '20px', width: 'calc(40% - 10px)' }}
                                            />
                                            <TextField
                                                label="Name Of Account Holder"
                                                variant="outlined"
                                                defaultValue={accountHolderName || name}
                                                fullWidth
                                                onChange={(e) => setAccountHolderName(e.target.value)}
                                                margin="normal"
                                                style={{ marginBottom: '20px', width: 'calc(40% - 10px)' }}
                                            />
                                        </div>
                                        <Button onClick={() => { handleSaveModal("BankInfo") }}>Save</Button>
                                        <Button onClick={handleCloseModal}>Cancel</Button>
                                    </Paper>
                                </div>
                            </Modal>

                            {/* First row */}
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Name Of Bank
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{StaffData?.bank?.discription || '-'}</Typography>
                                </div>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Bank Branch
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{bankBranch || '-'}</Typography>
                                </div>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        IFSC Code
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{ifscCode || '-'}</Typography>
                                </div>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Account Number
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{accountNumber || '-'}</Typography>
                                </div>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        Name Of Account Holder
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{accountHolderName || '-'}</Typography>
                                </div>
                            </div>
                        </Paper>
                    </div>

                    {/* UPI Details */}
                    <div style={{ padding: '10px' }}>
                        <Paper elevation={3} style={{ padding: '20px', marginBottom: '10px', position: 'relative' }}>
                            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                                UPI Details
                            </Typography>
                            <Button variant="outlined" color="primary" style={{ position: 'absolute', top: '10px', right: '10px' }} onClick={handleOpenUPIModal}>
                                Edit
                            </Button>
                            <Modal open={openUPIModal} onClose={handleCloseModal}>
                                <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '90%', maxWidth: '400px', bgcolor: 'white', boxShadow: 24, p: 4 }}>
                                    {/* Modal Content */}
                                    <Paper sx={{ padding: '20px' }}>
                                        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                                            Edit UPI Details
                                        </Typography>
                                        {/* First row */}
                                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                            <TextField
                                                label="UPI ID"
                                                variant="outlined"
                                                defaultValue={upiID || ''}
                                                fullWidth
                                                onChange={(e) => setUpiID(e.target.value)}
                                                margin="normal"
                                                style={{ marginBottom: '20px', width: '100%' }}
                                            />
                                        </div>
                                        <Button onClick={() => { handleSaveModal("UPIInfo") }}>Save</Button>
                                        <Button onClick={handleCloseModal}>Cancel</Button>
                                    </Paper>
                                </div>
                            </Modal>

                            {/* First row */}
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <div style={{ marginRight: '40px', marginBottom: '20px', width: '200px' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                                        UPI ID
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="body1">{upiID || '-'}</Typography>
                                </div>
                            </div>
                        </Paper>
                    </div>

                    {/* Language */}
                    <div style={{ padding: '10px' }}>
                        <Paper elevation={3} style={{ padding: '10px', marginBottom: '10px', position: 'relative' }}>
                            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                                Languages
                            </Typography>

                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <Grid item xs={12}>
                                    <Grid item xs={12}>
                                        {dropdownValues.map((item, index) => (
                                            <div key={index}>
                                                <Paper elevation={0} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '0px', marginTop: '10px' }}>
                                                    <Grid container spacing={1} alignItems="center">
                                                        <Grid item xs={3}>
                                                            <FormControl style={{ width: '100%' }}>
                                                                <Autocomplete
                                                                    value={item?.language}
                                                                    onChange={(event, newValue) => {
                                                                        handleLanguageChange(index, 'language', newValue);
                                                                    }}
                                                                    options={languageList}
                                                                    // getOptionLabel={(option) => option?.usrnm || ""}
                                                                    // getOptionSelected={(option, value) => option._id === value}
                                                                    style={{ height: '38px', fontSize: '14px' }}
                                                                    renderInput={(params) => <TextField {...params} label="Language"
                                                                        inputProps={{ ...params.inputProps, 'aria-label': 'Select Department Type', style: { paddingTop: '0px' } }}
                                                                        // sx={{ "& .MuiInputBase-root": { height: '30px', marginTop: "10px" } }}    
                                                                        variant="outlined"
                                                                        size="small" />}
                                                                    fullWidth
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <FormControl style={{ width: '100%' }}>
                                                                <Autocomplete
                                                                    value={item.read}
                                                                    onChange={(event, newValue) => handleLanguageChange(index, 'read', newValue)}
                                                                    options={[
                                                                        "Yes",
                                                                        "No"]}
                                                                    // getOptionLabel={(option) => option?.label || ""}
                                                                    // getOptionSelected={(option, value) => option.value === value}
                                                                    style={{ height: '38px', fontSize: '14px' }}
                                                                    renderInput={(params) => <TextField {...params} label="Read"
                                                                        inputProps={{ ...params.inputProps, 'aria-label': 'Select Department Type', style: { paddingTop: '0px' } }}
                                                                        variant="outlined"
                                                                        size="small" />}
                                                                    fullWidth
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <FormControl style={{ width: '100%' }}>
                                                                <Autocomplete
                                                                    value={item.write}
                                                                    onChange={(event, newValue) => handleLanguageChange(index, 'write', newValue)}
                                                                    options={[
                                                                        "Yes",
                                                                        "No"]}
                                                                    // getOptionLabel={(option) => option?.label || ""}
                                                                    // getOptionSelected={(option, value) => option.value === value}
                                                                    style={{ height: '38px', fontSize: '14px' }}
                                                                    renderInput={(params) => <TextField {...params} label="Write "
                                                                        inputProps={{ ...params.inputProps, 'aria-label': 'Select Department Type', style: { paddingTop: '0px' } }}
                                                                        variant="outlined"
                                                                        size="small" />}
                                                                    fullWidth
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <FormControl style={{ width: '100%' }}>
                                                                <Autocomplete
                                                                    value={item.speak}
                                                                    onChange={(event, newValue) => handleLanguageChange(index, 'speak', newValue)}
                                                                    options={[
                                                                        "Yes",
                                                                        "No"]}
                                                                    // getOptionLabel={(option) => option?.label || ""}
                                                                    // getOptionSelected={(option, value) => option.value === value}
                                                                    style={{ height: '38px', fontSize: '14px' }}
                                                                    renderInput={(params) => <TextField {...params} label="Speak "
                                                                        inputProps={{ ...params.inputProps, 'aria-label': 'Select Department Type', style: { paddingTop: '0px' } }}
                                                                        variant="outlined"
                                                                        size="small" />}
                                                                    fullWidth
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <FormControl style={{ width: '100%' }}>
                                                                <Autocomplete
                                                                    value={item.understand}
                                                                    onChange={(event, newValue) => handleLanguageChange(index, 'understand', newValue)}
                                                                    options={[
                                                                        "Yes",
                                                                        "No"]}
                                                                    // getOptionLabel={(option) => option?.label || ""}
                                                                    // getOptionSelected={(option, value) => option.value === value}
                                                                    style={{ height: '38px', fontSize: '14px' }}
                                                                    renderInput={(params) => <TextField {...params} label="Understand"
                                                                        inputProps={{ ...params.inputProps, 'aria-label': 'Select Department Type', style: { paddingTop: '0px' } }}
                                                                        variant="outlined"
                                                                        size="small" />}
                                                                    fullWidth
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            <Button onClick={() => handleLanguageDelete(index)}><DeleteIcon /></Button>

                                                        </Grid>
                                                    </Grid>
                                                </Paper>
                                            </div>
                                        ))}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
                                            <Button onClick={handleLanguageAddMore}>Add More</Button>
                                            <Button variant="contained" size='small' color="primary" onClick={() => handleSaveModal("Language")}>
                                                Save
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper>
                    </div>

                    {/* Academic Details */}
                    <div style={{ padding: '10px' }}>
                        <Paper elevation={3} style={{ padding: '10px', marginBottom: '10px', position: 'relative' }}>
                            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                                Academic Details
                            </Typography>

                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <Grid item xs={12}>
                                    <Grid item xs={12}>
                                        {EducationdropdownValues.map((item, index) => (
                                            <div key={index}>
                                                <Paper elevation={0} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '0px', marginTop: '10px' }}>
                                                    <Grid container spacing={1} alignItems="center">
                                                        <Grid item xs={2}>
                                                            <TextField
                                                                label="Examination"
                                                                variant="outlined"
                                                                value={item.Examination || ''}
                                                                fullWidth
                                                                size='small'
                                                                onChange={(event) => handleEducationChange(index, 'Examination', event.target.value)}
                                                            // margin="normal"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                label="Institute Name"
                                                                variant="outlined"
                                                                value={item.InstituteName || ''}
                                                                fullWidth
                                                                size='small'
                                                                onChange={(event) => handleEducationChange(index, 'InstituteName', event.target.value)}
                                                            // margin="normal"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <TextField
                                                                label="Exam Year"
                                                                variant="outlined"
                                                                value={item.Year || ''}
                                                                fullWidth
                                                                size='small'
                                                                onChange={(event) => handleEducationChange(index, 'Year', event.target.value)}
                                                            // margin="normal"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <TextField
                                                                label="Percentage"
                                                                variant="outlined"
                                                                value={item.Percentage || ''}
                                                                fullWidth
                                                                size='small'
                                                                onChange={(event) => handleEducationChange(index, 'Percentage', event.target.value)}
                                                            // margin="normal"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <TextField
                                                                label="Main Subject"
                                                                variant="outlined"
                                                                value={item.Subject || ''}
                                                                fullWidth
                                                                size='small'
                                                                onChange={(event) => handleEducationChange(index, 'Subject', event.target.value)}
                                                            // margin="normal"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            <Button onClick={() => handleEducationDelete(index)}><DeleteIcon /></Button>

                                                        </Grid>
                                                    </Grid>
                                                </Paper>
                                            </div>
                                        ))}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
                                            <Button onClick={handleEducationAddMore}>Add More</Button>
                                            <Button variant="contained" size='small' color="primary" onClick={() => handleSaveModal("Academic")}>
                                                Save
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper>
                    </div>

                    {/* Work Details */}
                    <div style={{ padding: '10px' }}>
                        <Paper elevation={3} style={{ padding: '10px', marginBottom: '10px', position: 'relative' }}>
                            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                                Work Experience
                            </Typography>

                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <Grid item xs={12}>
                                    <Grid item xs={12}>
                                        {WorkdropdownValues.map((item, index) => (
                                            <div key={index}>
                                                <Paper elevation={0} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '0px', marginTop: '10px' }}>
                                                    <Grid container spacing={1} alignItems="center">
                                                        <Grid item xs={2}>
                                                            <TextField
                                                                label="Working Period"
                                                                variant="outlined"
                                                                value={item.WorkingPeriod || ''}
                                                                fullWidth
                                                                size='small'
                                                                onChange={(event) => handleWorkChange(index, 'WorkingPeriod', event.target.value)}
                                                            // margin="normal"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                label="Organization"
                                                                variant="outlined"
                                                                value={item.Organization || ''}
                                                                fullWidth
                                                                size='small'
                                                                onChange={(event) => handleWorkChange(index, 'Organization', event.target.value)}
                                                            // margin="normal"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <TextField
                                                                label="Designation"
                                                                variant="outlined"
                                                                value={item.Designation || ''}
                                                                fullWidth
                                                                size='small'
                                                                onChange={(event) => handleWorkChange(index, 'Designation', event.target.value)}
                                                            // margin="normal"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <TextField
                                                                label="Duties"
                                                                variant="outlined"
                                                                value={item.Duties || ''}
                                                                fullWidth
                                                                size='small'
                                                                onChange={(event) => handleWorkChange(index, 'Duties', event.target.value)}
                                                            // margin="normal"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <TextField
                                                                label="Leaving Reason"
                                                                variant="outlined"
                                                                value={item.LeavingReason || ''}
                                                                fullWidth
                                                                size='small'
                                                                onChange={(event) => handleWorkChange(index, 'LeavingReason', event.target.value)}
                                                            // margin="normal"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            <Button onClick={() => handleWorkDelete(index)}><DeleteIcon /></Button>

                                                        </Grid>
                                                    </Grid>
                                                </Paper>
                                            </div>
                                        ))}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
                                            <Button onClick={handleWorkAddMore}>Add More</Button>
                                            <Button variant="contained" size='small' color="primary" onClick={() => handleSaveModal("WorkEx")}>
                                                Save
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper>
                    </div>


                    {/* Family Details */}
                    <div style={{ padding: '10px' }}>
                        <Paper elevation={3} style={{ padding: '10px', marginBottom: '10px', position: 'relative' }}>
                            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                                Details of Family Members
                            </Typography>

                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <Grid item xs={12}>
                                    <Grid item xs={12}>
                                        {FamilydropdownValues.map((item, index) => (
                                            <div key={index}>
                                                <Paper elevation={0} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '0px', marginTop: '10px' }}>
                                                    <Grid container spacing={1} alignItems="center">
                                                        <Grid item xs={4}>
                                                            {/* <TextField
                                                                label="Relation"
                                                                variant="outlined"
                                                                value={item.Relation || ''}
                                                                fullWidth
                                                                size='small'
                                                                onChange={(event) => handleFamilyChange(index, 'Relation', event.target.value)}
                                                            // margin="normal"
                                                            /> */}
                                                            <Autocomplete
                                                                value={item.Relation}
                                                                onChange={(event, newValue) => handleFamilyChange(index, 'Relation', newValue)}
                                                                options={[
                                                                    "Father",
                                                                    "Mother",
                                                                    "Sister",
                                                                    "Brother",
                                                                    "Wife",
                                                                    "Husband",
                                                                    "Kids"]}
                                                                // getOptionLabel={(option) => option?.label || ""}
                                                                // getOptionSelected={(option, value) => option.value === value}
                                                                style={{ height: '38px', fontSize: '14px' }}
                                                                renderInput={(params) => <TextField {...params} label="Relation"
                                                                    inputProps={{ ...params.inputProps, 'aria-label': 'Select Department Type', style: { paddingTop: '0px' } }}
                                                                    variant="outlined"
                                                                    size="small" />}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                label="Memeber Name"
                                                                variant="outlined"
                                                                value={item.Name || ''}
                                                                fullWidth
                                                                size='small'
                                                                onChange={(event) => handleFamilyChange(index, 'Name', event.target.value)}
                                                            // margin="normal"
                                                            />
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <TextField
                                                                label="Contact No."
                                                                variant="outlined"
                                                                value={item.Contact || ''}
                                                                fullWidth
                                                                size='small'
                                                                onChange={(event) => handleFamilyChange(index, 'Contact', event.target.value)}
                                                            // margin="normal"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            <Button onClick={() => handleFamilyDelete(index)}><DeleteIcon /></Button>

                                                        </Grid>
                                                    </Grid>
                                                </Paper>
                                            </div>
                                        ))}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
                                            <Button onClick={handleFamilyAddMore}>Add More</Button>
                                            <Button variant="contained" size='small' color="primary" onClick={() => handleSaveModal("Family")}>
                                                Save
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper>
                    </div>
                </Paper>
            </Grid>
            <Modal
                open={successModalOpen}
                onClose={handleSuccessModalClose}
                aria-labelledby="success-modal-title"
                aria-describedby="success-modal-description"
            >
                <div style={{ ...modalStyle, borderColor: '#007bff' }}>
                    <h2 id="success-modal-title" style={{ color: '#007bff' }}>Success!</h2>
                    <p id="success-modal-description">{actionMessage}</p>
                    <Button variant="contained" onClick={handleSuccessModalClose} style={{ backgroundColor: '#007bff', color: 'white' }}>Close</Button>
                </div>
            </Modal>
            <Modal
                open={errorModalOpen}
                onClose={handleErrorModalClose}
                aria-labelledby="error-modal-title"
                aria-describedby="error-modal-description"
            >
                <div style={{ ...modalStyle, borderColor: '#ff0000' }}>
                    <h2 id="error-modal-title" style={{ color: '#ff0000' }}>Error!</h2>
                    <p id="error-modal-description">{actionMessage}.</p>
                    <Button variant="contained" onClick={handleErrorModalClose} style={{ backgroundColor: '#ff0000', color: 'white' }}>Close</Button>
                </div>
            </Modal>
        </Grid>
    );
}

export default Profile;

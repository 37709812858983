import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';

import Container from '@mui/material/Container';
import MUIDataTable from "mui-datatables";
import Modal from '@mui/material/Modal';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid'; // Import DataGrid from MUI
import Autocomplete from '@mui/material/Autocomplete';

function ShiftMaster() {
    const [shiftName, setShiftName] = useState(''); // Updated state variable
    const [inTime, setInTime] = useState(''); // Updated state variable
    const [outTime, setOutTime] = useState(''); // Updated state variable
    const [shiftType, setShifTtype] = useState([]);
    const [shiftTypeValue, setShifTtypeValue] = useState(null);
    const [description, setDescription] = useState('');

    const [data, setdata] = useState([]);
    const [openModal, setOpenModal] = useState(false); // State to control the modal visibility
    const [deleteIndex, setDeleteIndex] = useState(null); // State to store the index of item to be deleted
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [ActionMessage, setActionMessage] = useState('');
    const [editID, setEditID] = useState();

    const handleSubmit = (event) => {
        event.preventDefault();
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const dataToSend = {
                shift_name: shiftTypeValue?._id,
                in_time: inTime,
                out_time: outTime,
                description,
                usrnm: userSchema?.usrnm,
                compid: JSON.parse(companyIdString),
                divid: JSON.parse(divIdString),
                masterid: userSchema?.masterid,
                _id: editID ? editID : null
            };

            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            axios.post(`${process.env.REACT_APP_API_URL}/employeegrade_master/HolidayAdd`, dataToSend, {
                headers: {
                    authorization: `Bearer ${token}`
                }
            })
                .then(response => {
                    console.log(response)
                    fetchData()
                    if (response.data.status) {
                        setActionMessage(response.data.message)
                        setSuccessModalOpen(true);
                        setDescription('');
                        setShifTtypeValue('');
                        setInTime('');
                        setOutTime('');
                        setEditID('');
                    } else {
                        setActionMessage(response.data.message)
                        setDescription('');
                        setShifTtypeValue('');
                        setInTime('');
                        setOutTime('');
                        setEditID('');
                        setErrorModalOpen(true)
                    }
                })
                .catch(error => {
                    console.log(error)
                    setActionMessage("Ooops an error occured.")
                    setErrorModalOpen(true)
                });

        
    };

    const handleErrorModalClose = () => {
        setErrorModalOpen(false);
    };

    const handleSuccessModalClose = () => {
        setSuccessModalOpen(false);
    };


    const handleCancel = () => {
        setDescription('');
        setShifTtypeValue('');
        setInTime('');
        setOutTime('');
        setEditID('');
    };

    const handleDelete = (INFO) => {
        setDeleteIndex(INFO._id);
        setOpenModal(true);
    };

    const handleModalDelete = async () => {
        try {
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/employeegrade_master/HolidayDELETE/${deleteIndex}`, {}, {
                headers: {
                    authorization: `Bearer ${token}`
                }
            });
            console.log('Delete API Response:', response.data);
            handleCloseModal();
            fetchData();
        } catch (error) {
            console.error('Error deleting item:', error);
        }
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const fetchData = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const masterid = userSchema?.masterid;
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/employeegrade_master/HolidayGET`,
                {
                    params: {
                        masterid,
                        compid: JSON.parse(companyIdString),
                        divid: JSON.parse(divIdString)
                    },
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );

            console.log('API Response:', response.data.lastEntryNo);
            setdata(response.data.lastEntryNo)

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const shiftTypeGET = async () => {
        const userSchemaString = localStorage.getItem('userSchema');
        const userSchema = JSON.parse(userSchemaString);
        const companyIdString = localStorage.getItem('companyId');
        const divIdString = localStorage.getItem('divId');


        const data = {
            user: userSchema?.usrnm,
            compid: JSON.parse(companyIdString),
            divid: JSON.parse(divIdString),
            masterid: userSchema?.masterid
        };
        try {
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/attendance/shiftTypeGET`, data,
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );

            setShifTtype(response.data.lastEntryNo);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        shiftTypeGET();
    }, [])
    useEffect(() => {
        fetchData(); // Call fetchData when component mounts or when searchText, rowsPerPage, or currentPage changes
        // }, [searchText, rowsPerPage, currentPage]);
    }, []);


    const dataWithSerialNumbers = data.map((item, index) => ({
        ...item,
        serialNumber: index + 1, // Serial number starts from 1
        id: index + 1, // Serial number starts from 1
    }));

    const columns = [
        { field: "serialNumber", headerName: "S. No.", flex: 1 }, // Serial number column
        { field: "shift_name", headerName: "Shift Name", flex: 1 },
        { field: "in_time", headerName: "In Time", flex: 1 },
        { field: "out_time", headerName: "Out Time", flex: 1 },
        { field: "holiday_name", headerName: "Holiday", flex: 1 },
        {
            field: "edit",
            flex: 1,
            headerName: "Edit",
            renderCell: (params) => (
                <Button variant="text" color="primary"
                    onClick={() => handleEdit(params.row)}><EditIcon fontSize="small" /></Button>
            )
        },
        {
            field: "delete",
            flex: 1,
            headerName: "Delete",
            renderCell: (params) => (
                <Button variant="text" color="error" onClick={() => handleDelete(params.row)}><DeleteIcon fontSize="small" /></Button>
            )
        }
    ];

    const handleEdit = (editdata) => {
        setShifTtypeValue(editdata.shift_name);
        setInTime(editdata.in_time);
        setOutTime(editdata.out_time);
        setEditID(editdata._id);
        setDescription(editdata?.holiday_name);
        window.scrollTo(0, 0); // Scroll to the top of the page
    };
    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        border: '2px solid',
        boxShadow: 24,
        padding: 16,
        minWidth: 300,
        maxWidth: 500,
        textAlign: 'center'
    };
    return (
        <>
            <Container maxWidth="xl" style={{ marginTop: '25px' }}>
                <div >
                    <Typography variant="body1" sx={{ fontWeight: 'bold', marginRight: '10px', fontSize: '20px' }}>
                        Add Holiday
                    </Typography>
                    <div style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={12}>
                                <Paper elevation={3} style={{ padding: '15px' }}>
                                    <div style={{ marginBottom: '10px', marginTop: '10px' }}>
                                        <Grid container alignItems="center" spacing={2}>
                                            <Grid item xs={2}>
                                                <Typography variant="body1">Holiday</Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField
                                                    value={description}
                                                    onChange={(e) => setDescription(e.target.value)}
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={1}></Grid>
                                            <Grid item xs={2}>
                                                <Typography variant="body1">Shift Name:</Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Autocomplete
                                                    value={shiftTypeValue}
                                                    onChange={(event, newValue) => {
                                                        setShifTtypeValue(newValue);
                                                    }}
                                                    options={shiftType}
                                                    getOptionLabel={(option) => option.shift_name}
                                                    getOptionSelected={(option, value) => option._id === value}
                                                    style={{ height: '38px', fontSize: '14px' }}
                                                    renderInput={(params) => <TextField {...params} variant="outlined"

                                                        inputProps={{ ...params.inputProps, 'aria-label': 'Select Staff Type', style: { paddingTop: '0px' } }}
                                                        sx={{
                                                            "& .MuiInputBase-root": { height: "38px" },
                                                        }}
                                                    />}
                                                />
                                            </Grid>
                                        </Grid>

                                    </div>
                                    <Divider />
                                    <div style={{ marginBottom: '10px', marginTop: '10px' }}>
                                        <Grid container alignItems="center" spacing={2}>
                                            <Grid item xs={2}>
                                                <Typography variant="body1">In Time</Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <input
                                                    type="time"
                                                    value={inTime}
                                                    onChange={(e) => setInTime(e.target.value)}
                                                    style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', flex: '0.5' }}
                                                />
                                            </Grid>
                                            <Grid item xs={1}></Grid>
                                            <Grid item xs={2}>
                                                <Typography variant="body1">Out Time</Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <input
                                                    type="time"
                                                    value={outTime}
                                                    onChange={(e) => setOutTime(e.target.value)}
                                                    style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', flex: '0.5' }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Paper>
                            </Grid>
                        </Grid>
                    </div>
                    <div style={{ marginTop: '10px' }}>
                        <Typography variant="h5" sx={{ color: 'black', fontWeight: 'bold' }}>
                            Holiday List
                        </Typography>
                        {/* <MUIDataTable title={""} data={dataWithSerialNumbers} columns={columns} options={options} /> */}
                        <DataGrid
                            rows={dataWithSerialNumbers}
                            columns={columns}
                            initialState={{
                                ...data.initialState,
                                pagination: { paginationModel: { pageSize: 10 } },

                            }}
                            pageSizeOptions={[10, 25, 100]}
                            pagination
                            autoHeight
                            checkboxSelection={false}
                            disableSelectionOnClick
                            density="compact"
                            slots={{ toolbar: GridToolbar }}
                            slotProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                },
                            }}
                        />
                    </div>
                    <Paper style={{ padding: '16px', position: 'fixed', bottom: '10px', zIndex: 1000, width: '60%', left: '0', right: '0', margin: '0 auto' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '8px' }}>
                        <Button style={{ marginRight: '8px' }} variant="contained" color="error" onClick={() => { handleCancel() }}>
                            Cancel
                        </Button>
                        <Button style={{ marginLeft: '8px' }} variant="contained" color="primary" onClick={() => { handleSubmit() }} >
                            Save
                        </Button>
                    </div>
                </Paper>

                </div>
            </Container>

            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4, width: 400 }}>
                    <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
                        Confirm Deletion
                    </Typography>
                    <Typography id="modal-description" sx={{ mt: 2 }}>
                        Are you sure you want to delete this item?
                    </Typography>
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={handleCloseModal} color="primary" sx={{ mr: 2 }}>
                            Cancel
                        </Button>
                        <Button onClick={handleModalDelete} variant="contained" color="error">
                            Delete
                        </Button>
                    </Box>
                </Box>
            </Modal>


            <Modal
                open={successModalOpen}
                onClose={handleSuccessModalClose}
                aria-labelledby="success-modal-title"
                aria-describedby="success-modal-description"
            >
                <div style={{ ...modalStyle, borderColor: '#007bff' }}>
                    <h2 id="success-modal-title" style={{ color: '#007bff' }}>Success!</h2>
                    <p id="success-modal-description">{ActionMessage}</p>
                    <Button variant="contained" onClick={handleSuccessModalClose} style={{ backgroundColor: '#007bff', color: 'white' }}>Close</Button>
                </div>
            </Modal>

            <Modal
                open={errorModalOpen}
                onClose={handleErrorModalClose}
                aria-labelledby="error-modal-title"
                aria-describedby="error-modal-description"
            >
                <div style={{ ...modalStyle, borderColor: '#ff0000' }}>
                    <h2 id="error-modal-title" style={{ color: '#ff0000' }}>Error!</h2>
                    <p id="error-modal-description">{ActionMessage}.</p>
                    <Button variant="contained" onClick={handleErrorModalClose} style={{ backgroundColor: '#ff0000', color: 'white' }}>Close</Button>
                </div>
            </Modal>



        </>
    );
}

export default ShiftMaster;

import React, { useState, useEffect, useCallback } from 'react';
import {
  Box, CircularProgress, Select, MenuItem, Typography, Paper, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow, TablePagination, Button
} from '@mui/material';
import axios from 'axios';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';

import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid'; // Import DataGrid from MUI

const AttendanceTable = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [month, setMonth] = useState('');
  const [availableMonths, setAvailableMonths] = useState([
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ]);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const currentDate = new Date();
    const currentMonth = currentDate.toLocaleString('default', { month: 'long' });
    setMonth(currentMonth);
  }, []);

  const handleMonthChange = (event) => {
    setMonth(event.target.value);
  };

  const fetchData = async () => {
    try {
      setIsLoading(true); // Set loading state to true
      const userSchemaString = localStorage.getItem('userSchema');
      const userSchema = JSON.parse(userSchemaString);
      const companyIdString = localStorage.getItem('companyId');
      const divIdString = localStorage.getItem('divId');
      const currentDate = new Date();

      const dataToSend = {
        usrnm: userSchema?.usrnm,
        compid: JSON.parse(companyIdString),
        divid: JSON.parse(divIdString),
        masterid: userSchema?.masterid,
        rowsPerPage,
        page: page + 1,
        Month: month ? month : currentDate.toLocaleString('default', { month: 'long' })
      };
      const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/attendance/GetAllEmpAttendanceDetail?search=${searchTerm}`,
        dataToSend,
        { headers: { authorization: `Bearer ${token}` } }
      );
      setData(response.data.summary);
      setIsLoading(false); // Set loading state to false
    } catch (error) {
      setIsLoading(false); // Set loading state to false
      console.error('Error fetching data:', error);
    }
  };

  // const debouncedFetchData = useCallback(
  //   debounce(fetchData, 2000),
  //   [month]
  // );

  // useEffect(() => {
  //   debouncedFetchData();
  //   return () => {
  //     debouncedFetchData.cancel();
  //   };
  // }, [debouncedFetchData]);

  useEffect(() => {
    fetchData();
  }, [month, rowsPerPage, page]);


  // useEffect(() => {
  //   setTimeout(() => {
  //     fetchData();
  //   }, 3000)
  // }, [ searchTerm]);

  // Step 1: Extract unique dates
  let datesSet = new Set();
  data.forEach(employee => {
    employee.details.forEach(detail => {
      const originalDate = new Date(detail.dalatten_date);
      const adjustedDate = new Date(originalDate.getTime() + (5 * 60 * 60 * 1000) + (30 * 60 * 1000));
      datesSet.add(adjustedDate.toISOString().slice(8, 10)); // Extract only the day part
    });
  });
  let datesArray = Array.from(datesSet);

  // Sort dates in ascending order
  datesArray.sort((a, b) => parseInt(a, 10) - parseInt(b, 10));

  // Step 2: Generate object with attendance details for each date
  let attendanceByDate = {};
  datesArray.forEach(date => {
    attendanceByDate[date] = [];
    data.forEach(employee => {
      employee.details.forEach(detail => {
        const originalDate = new Date(detail.dalatten_date);
        const adjustedDate = new Date(originalDate.getTime() + (5 * 60 * 60 * 1000) + (30 * 60 * 1000));
        const day = adjustedDate.toISOString().slice(8, 10); // Extract only the day part
        if (day === date) {
          attendanceByDate[date].push({
            employee: employee.employee[0],
            lvname: detail.lvname[0]
          });
        }
      });
    });
  });

  // Step 3: Calculate P, A, and PD counts for each employee
  let pCountByEmployee = {};
  let aCountByEmployee = {};
  let pdCountByEmployee = {};
  data.forEach(employee => {
    let pCount = 0;
    let aCount = 0;
    let pdCount = 0;
    datesArray.forEach(date => {
      const attendance = attendanceByDate[date].find(
        (attendance) => attendance.employee === employee.employee[0]
      );
      if (attendance) {
        if (attendance.lvname === 'P') {
          pCount++;
        } else if (attendance.lvname === 'A') {
          aCount++;
        } else if (attendance.lvname === 'PD') {
          pdCount++;
        }
      }
    });
    pCountByEmployee[employee.employee[0]] = pCount;
    aCountByEmployee[employee.employee[0]] = aCount;
    pdCountByEmployee[employee.employee[0]] = pdCount;
  });

  const columns = [
    { field: 'employee', headerName: 'Employee', width: 150, headerAlign: 'center', align: 'center' },
    ...datesArray.map(date => ({
      field: date,
      headerName: date,
      width: 40,
      renderCell: (params) => {
        const attendance = attendanceByDate[params.field].find(
          (attendance) => attendance.employee === params.row.employee
        );
        return (
          <div style={{
            background: `linear-gradient(to right, 
                ${attendance?.lvname === 'A' ? '#c98389' : attendance?.lvname === 'P' ? '#6bb05f' : ''}, 
                ${attendance?.lvname === 'A' ? '#f5d6d9' : attendance?.lvname === 'P' ? '#c5e4b7' : ''})`,
            padding: '0px',
            textAlign: 'center',
          }}>
            {attendance?.lvname || ''}
          </div>
        );
      },
      headerAlign: 'center',
      align: 'center'
    })),
    { field: 'pCount', headerName: 'P', width: 70, headerAlign: 'center', align: 'center' },
    { field: 'aCount', headerName: 'A', width: 70, headerAlign: 'center', align: 'center' },
    { field: 'pdCount', headerName: 'PD', width: 70, headerAlign: 'center', align: 'center' },
  ];

  // DataGrid rows
  const rows = data.map((employee, index) => ({
    id: index,
    employee: employee?.employee?.[0],
    ...datesArray.reduce((acc, date) => {
      const attendance = attendanceByDate[date].find(
        (attendance) => attendance.employee === employee.employee[0]
      );
      acc[date] = attendance ? attendance.lvname : '';
      return acc;
    }, {}),
    pCount: pCountByEmployee[employee.employee[0]],
    aCount: aCountByEmployee[employee.employee[0]],
    pdCount: pdCountByEmployee[employee.employee[0]],
  }));


  return (
    <>
      <Paper elevation={3} style={{ padding: '20px', marginBottom: '10px', borderRadius: '10px', background: 'linear-gradient(135deg, #FFFFFF,#60a5f2,#FFFFFF)', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>
            <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: 'black' }}>
              {"Attendance List"}
            </Typography>
          </div>
        </div>
        <div>
          <Select
            value={month}
            onChange={handleMonthChange}
            variant="outlined"
            MenuProps={{ PaperProps: { style: { maxHeight: '200px' } } }} // Adjust the height here
            sx={{ minWidth: 120, marginRight: '10px', marginBottom: '20px', height: 40, backgroundColor: 'white', borderRadius: '14px', border: '1px solid #ccc', }} // Add margin left for spacing
          >
            {availableMonths.map((month, index) => (
              <MenuItem key={index} value={month}>
                {month}
              </MenuItem>
            ))}
          </Select>
          {/* <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ marginLeft: '10px', marginRight: '10px', padding: '8px', borderRadius: '14px', border: '1px solid #ccc', width: '200px', height: '22px' }}
          /> */}
          <Button variant='contained' color='success' size='small' 
          // onClick={() => { navigate('/DetailedAttendance') }} 
          onClick={(e) => {
            if (e.metaKey || e.ctrlKey) {
                const url = `/DetailedAttendance`;
                window.open(url, '_blank');
            } else {
                navigate('/DetailedAttendance')
            }
        }}
          sx={{
            marginRight: '10px',
            borderRadius: '14px',
            borderColor: 'red',
          }}>Detailed Attendance</Button>
        </div>
      </Paper>
      {isLoading ? (
        <Box display="flex" padding='20px' marginTop='10px' alignItems="center" justifyContent="center" height="350px">
          <CircularProgress />
        </Box>
      ) : (
        // <TableContainer component={Paper} style={{ width: '100%', overflowX: 'auto' }}>
        //   <Table style={{ width: '100%', tableLayout: 'auto' }} size='small'>
        //     <TableHead>
        //       <TableRow>
        //         <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>
        //           Employee
        //         </TableCell>
        //         {datesArray.map((date) => (
        //           <TableCell
        //             sx={{
        //               fontWeight: 'bold',
        //               textAlign: 'center',
        //               maxWidth: '100px',
        //               overflow: 'hidden',
        //               textOverflow: 'ellipsis',
        //               whiteSpace: 'nowrap',
        //             }}
        //             key={date}
        //             title={date}
        //           >
        //             {date}
        //           </TableCell>
        //         ))}
        //         <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>
        //           P
        //         </TableCell>
        //         <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>
        //           A
        //         </TableCell>
        //         <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>
        //           PD
        //         </TableCell>
        //       </TableRow>
        //     </TableHead>
        //     <TableBody>
        //       {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((employee) => (
        //         <TableRow key={employee.employee[0]} style={{ height: '10px' }}>
        //           <TableCell
        //             component="th"
        //             scope="row"
        //             sx={{
        //               padding: '0px',
        //               fontSize: '0.8rem',
        //               textAlign: 'center',
        //               border: '1px groove black',
        //             }}
        //           >
        //             {employee.employee[0]}
        //           </TableCell>
        //           {datesArray.map((date) => {
        //             const attendance = attendanceByDate[date].find(
        //               (attendance) => attendance.employee === employee.employee[0]
        //             );
        //             let cellStyle = {
        //               padding: '0px',
        //               fontSize: '0.8rem',
        //               height: '10px',
        //               textAlign: 'center',
        //               border: '1px groove black',
        //               maxWidth: '100px', // Limit cell width
        //               overflow: 'hidden',
        //               textOverflow: 'ellipsis',
        //               whiteSpace: 'nowrap',
        //             };
        //             if (attendance) {
        //               cellStyle.background =
        //                 attendance.lvname === 'A'
        //                   ? `linear-gradient(to right, #c4848e, #e0b4b8)`
        //                   : attendance.lvname === 'P'
        //                     ? `linear-gradient(to right, #84a683, #a2c49c)`
        //                     : '';
        //             }
        //             return (
        //               <TableCell key={date} style={cellStyle}>
        //                 {attendance?.lvname || ''}
        //               </TableCell>
        //             );
        //           })}
        //           <TableCell
        //             sx={{
        //               textAlign: 'center',
        //               border: '1px groove black',
        //               padding: '0px',
        //               fontSize: '0.8rem',
        //             }}
        //           >
        //             {pCountByEmployee[employee.employee[0]]}
        //           </TableCell>
        //           <TableCell
        //             sx={{
        //               textAlign: 'center',
        //               border: '1px groove black',
        //               padding: '4px',
        //               fontSize: '0.8rem',
        //             }}
        //           >
        //             {aCountByEmployee[employee.employee[0]]}
        //           </TableCell>
        //           <TableCell
        //             sx={{
        //               textAlign: 'center',
        //               border: '1px groove black',
        //               padding: '0px',
        //               fontSize: '0.8rem',
        //             }}
        //           >
        //             {pdCountByEmployee[employee.employee[0]]}
        //           </TableCell>
        //         </TableRow>
        //       ))}
        //     </TableBody>
        //   </Table>
        //   <TablePagination
        //     rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
        //     component="div"
        //     count={data.length}
        //     rowsPerPage={rowsPerPage}
        //     page={page}
        //     onPageChange={handleChangePage}
        //     onRowsPerPageChange={handleChangeRowsPerPage}
        //   />
        // </TableContainer>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            ...rows.initialState,
            pagination: { paginationModel: { pageSize: 10 } },

          }}
          pageSizeOptions={[10, 25, 100]}
          pagination
          autoHeight
          checkboxSelection={false}
          disableSelectionOnClick
          density="compact"
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          sx={{
            '& .MuiDataGrid-cell': {
              borderBottom: 'none', // Remove bottom border of cells
              borderRight: '1px solid #e0e0e0', // Add right border to cells
              padding: 0, // Remove padding
              margin: 0, // Remove margin
            },
            '& .MuiDataGrid-columnHeaders': {
              borderBottom: 'none', // Remove bottom border of column headers
            },
            '& .MuiDataGrid-row': {
              margin: 0, // Remove margin between rows
            },
            '& .MuiDataGrid-cell:focus': {
              outline: 'none', // Remove focus outline
            },
          }}
        />
      )}
    </>
  );
};

export default AttendanceTable;


// import { BrowserRouter, Route, Routes } from 'react-router-dom';
// import Login from './auth/login/Login';
// import Auth from './auth/Auth';
// import ProtectedRoute from './util/ProtectedRoute';
// import NotFound from './util/NotFound';
// import Home from './portal/home/Home';
// import Division from './portal/division/division';
// import Emp_Grade_Master from './portal/Emp_Info_Master/emp_grade_master';
// import Emp_Type from './portal/Emp_Info_Master/emp_type';

// import Salary_Head from './portal/Salary_Master/salary_head';
// import Salary_Structure from './portal/Salary_Master/salary_structure';
// import Salary_StrucList from './portal/Salary_Master/salary_struc_list';
// import Shift_Role from './portal/Salary_Master/shift_role';
// import Salary_StrucEdit from './portal/Salary_Master/salry_struc_edit';

// import Shift_Master from './portal/Shift_Master/shift_master';

// import Profile from './portal/profile/profile';

// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
// // import 'bootstrap/dist/css/bootstrap.min.css';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <BrowserRouter basename={'/'}>
//       <Routes>
//         <Route path="*" element={<NotFound />} />
//         <Route path='/auth' element={<Auth />}>
//           <Route path='login' element={<Login />} />
//         </Route>
//         <Route path='/' element={<Auth />}>
//           <Route path='/' element={<Login />} />
//         </Route>

//         <Route path="/" element={<App />}>
//           <Route path='/dash' element={
//             <ProtectedRoute>
//               <Home />
//             </ProtectedRoute>
//           } />
//         </Route>

//  <Route path="/" element={<App />}>
//           <Route path='HrMaster/Emp_Grade_Master' element={
//             <ProtectedRoute>
//               <Emp_Grade_Master />
//             </ProtectedRoute>
//           } />
//           <Route path='HrMaster/Emp_Type' element={
//             <ProtectedRoute>
//               <Emp_Type />
//             </ProtectedRoute>
//           } />
//         </Route>

//         <Route path="/" element={<App />}>
//           <Route path='HrMaster/salaryMaster/SalaryHead' element={
//             <ProtectedRoute>
//               <Salary_Head />
//             </ProtectedRoute>
//           } />
//           <Route path='HrMaster/salaryMaster/SalaryStructure' element={
//             <ProtectedRoute>
//               <Salary_Structure />
//             </ProtectedRoute>
//           } />
//           <Route path='HrMaster/salaryMaster/Salary_StrucList' element={
//             <ProtectedRoute>
//               <Salary_StrucList />
//             </ProtectedRoute>
//           } />
//           <Route path='HrMaster/salaryMaster/shiftRole' element={
//             <ProtectedRoute>
//               <Shift_Role />
//             </ProtectedRoute>
//           } />
//           <Route path='HrMaster/salaryMaster/Salary_StrucEdit' element={
//             <ProtectedRoute>
//               <Salary_StrucEdit />
//             </ProtectedRoute>
//           } />
          
//         </Route>

//         <Route path="/" element={<App />}>
//           <Route path='SalaryMaster/AddShiftMaster' element={
//             <ProtectedRoute>
//               <Shift_Master />
//             </ProtectedRoute>
//           } />
//         </Route>


//         <Route path="/" element={<App />}>
//           <Route path='/devision' element={
//             <ProtectedRoute>
//               <Division />
//             </ProtectedRoute>
//           } />
//           <Route path='/profile' element={
//             <ProtectedRoute>
//               <Profile />
//             </ProtectedRoute>
//           } />
//         </Route>
        

//       </Routes>
//     </BrowserRouter>  </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Auth from './auth/Auth';
import Login from './auth/login/Login';
import NotFound from './util/NotFound';
import Registration from './auth/resgistration/registration';
import ProtectedRoute from './util/ProtectedRoute';
import Home from './portal/home/Home';
import Division from './portal/division/division';

import Homepage from './auth/homepage/homepage';
import PrivacyPolicy from './auth/homepage/privacy_policy';
import TermCondition from './auth/homepage/terms_condition';

import Emp_Grade_Master from './portal/Emp_Info_Master/emp_grade_master';
import Emp_Type from './portal/Emp_Info_Master/emp_type';
import Emp_Document from './portal/Emp_Info_Master/emp_document';
import Emp_Designation from './portal/Emp_Info_Master/designation';
import Emp_AttendanceType from './portal/Emp_Info_Master/attendance_type';
import LeaveReasonMaster from './portal/Emp_Info_Master/leave_reason_master';
import Language from './portal/Emp_Info_Master/language';
import HolidayMaster from './portal/Emp_Info_Master/holiday_master';
import BankMaster from './portal/Emp_Info_Master/bank_master';

import Salary_Head from './portal/Salary_Master/salary_head';
import Salary_Structure from './portal/Salary_Master/salary_structure';
import Salary_StrucList from './portal/Salary_Master/salary_struc_list';
import Shift_Role from './portal/Salary_Master/shift_role';
import Salary_StrucEdit from './portal/Salary_Master/salry_struc_edit';
import Shift_Master from './portal/Shift_Master/shift_master';


import Profile from './portal/profile/profile';
import ProfileAttendance from './portal/profile/attendance';
import DocumentCenter from './portal/profile/documentCenter';
import EmployeesList from './portal/profile/employee_list';


import TasksReport from './portal/profile/tasks_report';
import SalryStruc from './portal/profile/salary_struc';
import Loans from './portal/profile/loan';
import Leaves from './portal/profile/leave';


import ProfileSalaryOverview from './portal/profile/salary_overview'
import DetailedAttendance from './portal/home/detailedAttendance';
import StaffDetails from './portal/Emp_Master/StaffDetails';
import Advance from './portal/Transaction/advance';
import TransSalary from './portal/Transaction/salary';
import TransSalaryList from './portal/Transaction/salaryList';
import BankSalaryList from './portal/Transaction/banksalarylist';
import EsicReport from './portal/Transaction/esic_report';
import PFReport from './portal/Transaction/pf_report';

import AllEmpAttenList from './portal/Transaction/attendance';
import EditSalary from './portal/Transaction/edit_salary';
import AddSalary from './portal/Transaction/add_salary';
import LeaveReq from './portal/Transaction/leave_req';
import LeaveReqList from './portal/Transaction/leave_req_list';
import LeaveReqEdit from './portal/Transaction/leave_req_edit';
import LoanReq from './portal/Transaction/loan_req';
import LoanReqEdit from './portal/Transaction/loan_req_edit';
import LoanReqList from './portal/Transaction/loan_req_list';
import AddLeaves from './portal/Transaction/add_leaves_module';



import ProMachMaster from './portal/production/production_mach_master'
import ProMachList from './portal/production/production_mach_list'
import ProMachEdit from './portal/production/production_mach_edit'

import Department from './portal/Emp_Info_Master/department';
import Security from './portal/user_rights/security'
import UsersList from './portal/user_rights/users_list'
import UserRightsEdit from './portal/user_rights/user_rightsEdit'
import UserRightsListEdit from './portal/user_rights/user_rights_list'
import UserRightsAddUser from './portal/user_rights/add_user'
import UserRightsAuthorizationSetup from './portal/user_rights/authorization_setup/authorization_setup'
import DivisionMaster from './portal/user_rights/division_master'
import CompanyMaster from './portal/user_rights/company_master'

import MachineWiseReq from './portal/reports/machine_wise_req'
import AbsentReport from './portal/reports/absent_report'
import LoanReport from './portal/reports/loan_report'
import DetailedLoanReport from './portal/reports/detailed_load_report'
import LeavingReport from './portal/reports/resign_report'
import JoinReport from './portal/reports/joining_report'
import OTReport from './portal/reports/Ot_report'
import LatecomingReport from './portal/reports/Latecoming_report'
import MissPunchReport from './portal/reports/Misspunch_report'

import TaskMange from './portal/task/task_mange'
import TaskPlan from './portal/task/task_plan'

import VisitorMaster from './portal/visitor/attendant_master'
import VisitorList from './portal/visitor/visitors_list'
import VisitorEdit from './portal/visitor/visitor_edit'

import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <BrowserRouter basename={'/'}>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/homepage" element={<Homepage />} /> 
        <Route path="/privacy-policy" element={<PrivacyPolicy />} /> 
        <Route path="/terms-conditions" element={<TermCondition />} /> 
        <Route path='/auth' element={<Auth />}>
          <Route path='login' element={<Homepage />} />
        </Route>
        <Route path='/' element={<Auth />}>
          <Route path='/' element={<Homepage />} />
        </Route>
        <Route path="/" element={<App />}>
          <Route path='/dash' element={<ProtectedRoute><Home /></ProtectedRoute>} />
          <Route path='HrMaster/Emp_Grade_Master' element={<ProtectedRoute><Emp_Grade_Master /></ProtectedRoute>} />
          <Route path='HrMaster/Emp_Type' element={<ProtectedRoute><Emp_Type /></ProtectedRoute>} />
          <Route path='HrMaster/Emp_Document' element={<ProtectedRoute><Emp_Document /></ProtectedRoute>} />
          <Route path='HrMaster/Department' element={<ProtectedRoute><Department /></ProtectedRoute>} />
          <Route path='HrMaster/Designation' element={<ProtectedRoute><Emp_Designation /></ProtectedRoute>} />
          <Route path='HrMaster/Emp_AttendanceType' element={<ProtectedRoute><Emp_AttendanceType /></ProtectedRoute>} />
          <Route path='HrMaster/leave_reason_master' element={<ProtectedRoute><LeaveReasonMaster /></ProtectedRoute>} />
          <Route path='HrMaster/language_master' element={<ProtectedRoute><Language /></ProtectedRoute>} />
          <Route path='HrMaster/holiday_master' element={<ProtectedRoute><HolidayMaster /></ProtectedRoute>} />
          <Route path='HrMaster/bank_master' element={<ProtectedRoute><BankMaster /></ProtectedRoute>} />

          <Route path='HrMaster/salaryMaster/SalaryHead' element={<ProtectedRoute><Salary_Head /></ProtectedRoute>} />
          <Route path='HrMaster/salaryMaster/SalaryStructure' element={<ProtectedRoute><Salary_Structure /></ProtectedRoute>} />
          <Route path='HrMaster/salaryMaster/Salary_StrucList' element={<ProtectedRoute><Salary_StrucList /></ProtectedRoute>} />
          <Route path='HrMaster/salaryMaster/shiftRole' element={<ProtectedRoute><Shift_Role /></ProtectedRoute>} />
          <Route path='HrMaster/salaryMaster/Salary_StrucEdit' element={<ProtectedRoute><Salary_StrucEdit /></ProtectedRoute>} />
          <Route path='HrMaster/EmpMaster/StaffDetails' element={<ProtectedRoute><StaffDetails /></ProtectedRoute>} />
         
          <Route path='HrMaster/production/ProMachMaster' element={<ProtectedRoute><ProMachMaster /></ProtectedRoute>} />
          <Route path='HrMaster/production/ProMachList' element={<ProtectedRoute><ProMachList /></ProtectedRoute>} />
          <Route path='HrMaster/production/ProMachEdit' element={<ProtectedRoute><ProMachEdit /></ProtectedRoute>} />

          <Route path='ShiftMaster/AddShiftMaster' element={<ProtectedRoute><Shift_Master /></ProtectedRoute>} />
          <Route path='/devision' element={<ProtectedRoute><Division /></ProtectedRoute>} />
          <Route path='/staffDetail/profile' element={<ProtectedRoute><Profile /></ProtectedRoute>} />
          <Route path='/staffDetail/attendance' element={<ProtectedRoute><ProfileAttendance /></ProtectedRoute>} />
          <Route path='/DetailedAttendance' element={<ProtectedRoute><DetailedAttendance /></ProtectedRoute>} />
          <Route path='/staffDetail/salaryOverview' element={<ProtectedRoute><ProfileSalaryOverview /></ProtectedRoute>} />
          <Route path='/staffDetail/document-center' element={<ProtectedRoute><DocumentCenter /></ProtectedRoute>} />
          <Route path='/staffDetail/tasks-report' element={<ProtectedRoute><TasksReport /></ProtectedRoute>} />
          <Route path='/staffDetail/salary-structure' element={<ProtectedRoute><SalryStruc /></ProtectedRoute>} />
          <Route path='/staffDetail/loans' element={<ProtectedRoute><Loans /></ProtectedRoute>} />
          <Route path='/staffDetail/leave' element={<ProtectedRoute><Leaves /></ProtectedRoute>} />
          <Route path='/staffDetail/EmployeesList' element={<ProtectedRoute><EmployeesList /></ProtectedRoute>} />

          <Route path='/transaction/advance' element={<ProtectedRoute><Advance /></ProtectedRoute>} />
          <Route path='/transaction/salary' element={<ProtectedRoute><TransSalary /></ProtectedRoute>} />
          <Route path='/transaction/salaryList' element={<ProtectedRoute><TransSalaryList /></ProtectedRoute>} />
          <Route path='/transaction/EditSalary' element={<ProtectedRoute><EditSalary /></ProtectedRoute>} />
          <Route path='/transaction/AddSalary' element={<ProtectedRoute><AddSalary /></ProtectedRoute>} />
          <Route path='/transaction/attendanceList' element={<ProtectedRoute><AllEmpAttenList /></ProtectedRoute>} />
          <Route path='/transaction/leave_req' element={<ProtectedRoute><LeaveReq /></ProtectedRoute>} />
          <Route path='/transaction/LeaveReqList' element={<ProtectedRoute><LeaveReqList /></ProtectedRoute>} />
          <Route path='/transaction/LeaveReqEdit' element={<ProtectedRoute><LeaveReqEdit /></ProtectedRoute>} />
          <Route path='/transaction/loan_req' element={<ProtectedRoute><LoanReq /></ProtectedRoute>} />
          <Route path='/transaction/LoanReqList' element={<ProtectedRoute><LoanReqList /></ProtectedRoute>} />
          <Route path='/transaction/LoanReqEdit' element={<ProtectedRoute><LoanReqEdit /></ProtectedRoute>} />
          <Route path='/transaction/add_leaves' element={<ProtectedRoute><AddLeaves /></ProtectedRoute>} />
          <Route path='/transaction/banksalaryList' element={<ProtectedRoute><BankSalaryList /></ProtectedRoute>} />
          <Route path='/transaction/esic_report' element={<ProtectedRoute><EsicReport /></ProtectedRoute>} />
          <Route path='/transaction/pf_report' element={<ProtectedRoute><PFReport /></ProtectedRoute>} />

          <Route path='/reports/MachineWiseRequirement' element={<ProtectedRoute><MachineWiseReq /></ProtectedRoute>} />
          <Route path='/reports/AbsentReport' element={<ProtectedRoute><AbsentReport /></ProtectedRoute>} />
          <Route path='/reports/LoanReport' element={<ProtectedRoute><LoanReport /></ProtectedRoute>} />
          <Route path='/reports/DetailedLoanReport' element={<ProtectedRoute><DetailedLoanReport /></ProtectedRoute>} />
          <Route path='/reports/leaving_report' element={<ProtectedRoute><LeavingReport /></ProtectedRoute>} />
          <Route path='/reports/joining_report' element={<ProtectedRoute><JoinReport /></ProtectedRoute>} />
          <Route path='/reports/ot_report' element={<ProtectedRoute><OTReport /></ProtectedRoute>} />
          <Route path='/reports/latecoming' element={<ProtectedRoute><LatecomingReport /></ProtectedRoute>} />
          <Route path='/reports/miss_punch_report' element={<ProtectedRoute><MissPunchReport /></ProtectedRoute>} />

          <Route path='/user_rights/security' element={<ProtectedRoute><Security /></ProtectedRoute>} />
          <Route path='/user_rights/users_list' element={<ProtectedRoute><UsersList /></ProtectedRoute>} />
          <Route path='/user_rights/user_rights_edit' element={<ProtectedRoute><UserRightsEdit /></ProtectedRoute>} />
          <Route path='/user_rights/user_rights_edit_list' element={<ProtectedRoute><UserRightsListEdit /></ProtectedRoute>} />
          <Route path='/user_rights/UserRightsAddUser' element={<ProtectedRoute><UserRightsAddUser /></ProtectedRoute>} />
          <Route path='/user_rights/authorization_setup' element={<ProtectedRoute><UserRightsAuthorizationSetup /></ProtectedRoute>} />
          <Route path='/user_rights/division_master' element={<ProtectedRoute><DivisionMaster /></ProtectedRoute>} />
          <Route path='/user_rights/company_master' element={<ProtectedRoute><CompanyMaster /></ProtectedRoute>} />
          
          <Route path='/task/task_management' element={<ProtectedRoute><TaskMange /></ProtectedRoute>} />
          <Route path='/task/task_plan' element={<ProtectedRoute><TaskPlan /></ProtectedRoute>} />

          <Route path='/visitor/attendant_master' element={<ProtectedRoute><VisitorMaster /></ProtectedRoute>} />
          <Route path='/visitor/visitors_list' element={<ProtectedRoute><VisitorList /></ProtectedRoute>} />
          <Route path='/visitor/visitors_edit' element={<ProtectedRoute><VisitorEdit /></ProtectedRoute>} />
          
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Paper
} from '@mui/material';

const Division = () => {
    const navigate = useNavigate();
    const [companyDetails, setCompanyDetails] = useState([]); // State to store company details
    const [divisionDetails, setDivisionDetails] = useState([]); // State to store division details
    const [selectedCompanyId, setSelectedCompanyId] = useState(null); // State to store the selected company ID

    useEffect(() => {
        const userSchemaString = localStorage.getItem('userSchema');
        const userSchema = JSON.parse(userSchemaString);
    const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
    axios.post(`${process.env.REACT_APP_API_URL}/company/code_co`, { co_code: userSchema.co_code }, {
        headers: {
            authorization: `Bearer ${token}`
        }
    }).then(response => {
                setCompanyDetails(response.data.company);
            })
            .catch(error => {
                console.error('Error fetching company details:', error);
            });
    }, []);

    const handleCompanyDivSelect = (divId) => {
        localStorage.setItem('divId', JSON.stringify(divId?._id));
        localStorage.setItem('masterid', JSON.stringify(divId?.masterid));
        localStorage.setItem('div_code', JSON.stringify(divId?.div_code));
        navigate('/dash')
    }
    
    const handleCompanySelect = (companyId,com_name) => {
        console.log(companyId)
        localStorage.setItem('companyId', JSON.stringify(companyId));
        localStorage.setItem('com_name', JSON.stringify(com_name));

        setSelectedCompanyId(companyId);
        const userSchemaString = localStorage.getItem('userSchema');
        const userSchema = JSON.parse(userSchemaString);
        const token = localStorage.getItem('user-token'); // Get JWT token from localStorage

        axios.post(`${process.env.REACT_APP_API_URL}/company/div_co`, { div_code: userSchema.div_code },{
            headers: {
                authorization: `Bearer ${token}`
            }
        }).then(response => {
                setDivisionDetails(response.data.division);
            })
            .catch(error => {
                console.error('Error fetching company details:', error);
            });
    };

    return (
        <div>
            <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                <Table style={{ minWidth: 650 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>Company Name</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Start Date</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>End Date</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Action</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={4} style={{ fontWeight: 'bold', fontSize: '20px', backgroundColor: '#007bff', color: 'white' }}>Select Company</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {companyDetails.map((company, index) => (
                            <TableRow key={index} style={{ backgroundColor: index % 2 === 0 ? '#f0f0f0' : 'white' }}>
                                <TableCell>{company.com_name}</TableCell>
                                <TableCell>{company.sdate}</TableCell>
                                <TableCell>{company.edate}</TableCell>
                                <TableCell>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleCompanySelect(company._id, company.com_name)}
                                        style={{ textTransform: 'none', minWidth: '120px', boxShadow: 'none' }}
                                    >
                                        Select
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {selectedCompanyId && (
                <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                    <Table style={{ minWidth: 650 }}>
                        <TableHead>
                        <TableRow>
                            <TableCell colSpan={4} style={{ fontWeight: 'bold', fontSize: '20px', backgroundColor: '#007bff', color: 'white' }}>Select Division</TableCell>
                        </TableRow>
                            <TableRow>
                                <TableCell style={{ fontWeight: 'bold' }}>Division</TableCell>
                                <TableCell style={{ fontWeight: 'bold', textAlign:'center' }}>Code</TableCell>
                                <TableCell style={{ fontWeight: 'bold',textAlign: 'center' }}>Link</TableCell>
                            </TableRow>
                          
                        </TableHead>
                        <TableBody>
                            {divisionDetails.map((division,index) => (
                                <TableRow key={index}>
                                    <TableCell>{division.div_mast}</TableCell>
                                    <TableCell style={{textAlign:'center' }}>{division.div_code}</TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={()=>{handleCompanyDivSelect(division,)}}
                                            style={{ textTransform: 'none', minWidth: '120px', boxShadow: 'none' }}
                                        >
                                            Select
                                        </Button>
                                    </TableCell>
                                </TableRow>
                                
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </div>
    );
};

export default Division;

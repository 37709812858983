import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import PortalNavbar from "./portal/navbar/PortalNavbar";
import { ProSidebarProvider } from 'react-pro-sidebar';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const location = useLocation(); // Get the current location

  const checkUserToken = () => {
    const userToken = localStorage.getItem('user-token');
    if (!userToken || userToken === 'undefined') {
      setIsLoggedIn(false);
    } else {
      setIsLoggedIn(true);
    }
  };

  useEffect(() => {
    checkUserToken();
  }, [isLoggedIn]);
  const isDevRoute = location.pathname === '/devision';
const style ={ 'min-height': 'auto' };
  return (
    <>
<style>
  {`
    .css-hyum1k-MuiToolbar-root {
      min-height: auto;
    }
    
    .tss-gm6zfk-MUIDataTableHeadCell-fixedHeader {
      background: linear-gradient(#FFFFFF, #60a5f2);
    }

    .css-1ygcj2i-MuiTableCell-root {
      padding: 8px;
    }

    .tss-1fbujeu-MUIDataTableHeadCell-toolButton {
      font-weight: bold;
      font-size: 12px; 
    }
    .css-1j9bukl-MuiDataGrid-root .MuiDataGrid-container--top [role=row], .css-1j9bukl-MuiDataGrid-root .MuiDataGrid-container--bottom [role=row]{
      background: linear-gradient(#FFFFFF, #60a5f2);
    }
    .css-t89xny-MuiDataGrid-columnHeaderTitle{      
      font-weight: 600;
    }

    .app-container {
      display: flex;
      flex-direction: row;
      height: 100vh;
      overflow: hidden;
    }

    .main-content {
      flex: 1;
      overflow-y: auto;
    }

    .sidebar {
      height: 100vh;
      overflow-y: auto;
    }
  `}
</style>

    {/* <div>
      {isLoggedIn && !isDevRoute && <PortalNavbar />}
      <div>
        <Outlet />
      </div>
    </div> */}
    <ProSidebarProvider>
    <div className="app-container">
    {isLoggedIn && !isDevRoute && <div className="sidebar"><PortalNavbar /></div>}
        <div className="main-content">
          <Outlet />
        </div>
      </div>
      </ProSidebarProvider>
    </>
    );
}

export default App;
